import BaseLayout from "./base-layout";
import { ReactComponent as Logo } from "../assets/logo.svg";
import styles from "../styles/secure-layout.module.scss";
import { Col, Row } from "antd";
import { ReactElement, useEffect, useState } from "react";
import MenuItemButton from "../atomics/buttons/menu-item-button";
import { useNavigate, useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { roleAtom, userAtom } from "../../store/global-atoms";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { useSecureService } from "../../store/service-atoms";
import ModalLayout from "./modal-layout";
import PrimaryButton from "../atomics/buttons/primary-button";
import { t } from "../../translate";

type PageType = "dashboard" | "machines" | "requests" | "offers" | "requests-and-offers";
export type SecureLayoutProps = {
    activePage?: PageType;
    role: string;
    unreadCount: number;
    children?: any;
    title?: string;
    description?: string;
    helperButtons?: ReactElement[];
    onlyHeader?: boolean;
};

const SecureLayout = (props: SecureLayoutProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [user] = useAtom(userAtom);
    const [role, setRole] = useAtom(roleAtom);
    const { doLogout, doSwitch } = useSecureService();
    const [signUpVisible, setSignupVisible] = useState<boolean>(false);

    useEffect(() => {
        if (user === undefined) {
            navigate("/login?role=" + role);
        }
        //eslint-disable-next-line
    }, [user]);

    const navigateToPage = (target: PageType) => {
        const pathParts = pathname.split("/");
        const slashCount = pathParts.length - 2;
        //let backCount = 0;
        if (slashCount > 0) {
            navigate("/" + target);
        } else navigate("/" + target, { replace: true });
        //     backCount = slashCount*-1;
        //     navigate(backCount);
        // }
    };

    const logout = async () => {
        await doLogout;
    };

    const switchDashboard = async () => {
        const result = await doSwitch(role);
        if (result) {
            setRole((val) => {
                return val === "renter" ? "lessor" : "renter";
            });
            //TODO: False geldiğinde yapılacaklar önemli. Ya da switch'in hiç gözükmemesi gereken durumlar olabilir.
            navigateToPage("dashboard");
        } else {
            setSignupVisible(true);
        }
    };

    const modalText = role === "renter" ? t("dashboard.switch.signUpLessor") : t("dashboard.switch.signUpRenter");
    const modalPrimaryButtonTarget = role === "renter" ? "/signup/form?role=lessor" : "/signup/form?role=renter";

    return (
        <BaseLayout style={{ background: "#FFF6E8" }}>
            <Header className={styles.header}>
                <div className={styles.headBar}>
                    <div className={styles.leftSide}>
                        <Logo className={styles.logo} />
                        {role === "lessor" ? (
                            <>
                                <MenuItemButton
                                    isActive={props.activePage === "dashboard"}
                                    onClick={() => {
                                        navigateToPage("dashboard");
                                    }}
                                >
                                    Anasayfa
                                </MenuItemButton>
                                <MenuItemButton
                                    isActive={props.activePage === "machines"}
                                    onClick={() => {
                                        navigateToPage("machines");
                                    }}
                                >
                                    Makinelerim
                                </MenuItemButton>
                                <MenuItemButton
                                    isActive={props.activePage === "requests"}
                                    onClick={() => {
                                        navigateToPage("requests");
                                    }}
                                >
                                    Talepler
                                </MenuItemButton>
                                <MenuItemButton isActive={props.activePage === "offers"}>Tekliflerim</MenuItemButton>
                            </>
                        ) : (
                            <>
                                <MenuItemButton
                                    isActive={props.activePage === "dashboard"}
                                    onClick={() => {
                                        navigateToPage("dashboard");
                                    }}
                                >
                                    Anasayfa
                                </MenuItemButton>
                                <MenuItemButton
                                    isActive={props.activePage === "requests-and-offers"}
                                    onClick={() => {
                                        navigateToPage("requests-and-offers");
                                    }}
                                >
                                    Taleplerim &amp; Tekliflerim
                                </MenuItemButton>
                            </>
                        )}
                    </div>
                    <div className={styles.rigthSide}>
                        <button onClick={switchDashboard}>Switch</button>
                        <div>Bildirim</div>
                        <div>Profil</div>
                        <button onClick={logout}>Çıkış</button>
                    </div>
                </div>
                <hr className={styles.hrLine} />
            </Header>
            {props.onlyHeader ? (
                props.children
            ) : (
                <>
                    <Content className={styles.content}>
                        <div className={styles.secureContainer}>
                            <Row className={styles.headerArea}>
                                <Col>
                                    <h1 className={styles.title}>{props.title}</h1>
                                    <span className={styles.description} style={{ fontWeight: "lighter" }}>
                                        {props.description}
                                    </span>
                                </Col>
                                <Col xs={24} md={5}>
                                    <Row gutter={8}>
                                        {props.helperButtons &&
                                            props.helperButtons.map((btn: ReactElement) => {
                                                return (
                                                    <Col xs={24} md={24 / (props.helperButtons?.length || 1)}>
                                                        {btn}
                                                    </Col>
                                                );
                                            })}
                                    </Row>
                                </Col>
                            </Row>
                            {props.children}
                        </div>
                    </Content>
                    <Footer></Footer>
                </>
            )}
            <ModalLayout onCloseClick={() => setSignupVisible(false)} show={signUpVisible}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <span style={{ fontSize: "1.75rem", fontWeight: "800", margin: "3rem" }}>{modalText}</span>
                    <PrimaryButton
                        style={{ width: "auto", padding: "0 3rem" }}
                        onClick={() => {
                            setSignupVisible(false);
                            navigate(modalPrimaryButtonTarget);
                        }}
                    >
                        {t("signup.form.createAccount")}
                    </PrimaryButton>
                </div>
            </ModalLayout>
        </BaseLayout>
    );
};

export default SecureLayout;
