import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListContainer from "../../../../components/containers/list-container";
import { useQuery } from "../../../../helpers/use-query";
import RenterCompanyInfoPart from "../../../../page-parts/view-renter-company-info-part";
import { RenterCompanyDetailViewModel } from "../../../../service";
import { useSecureService } from "../../../../store/service-atoms";
import { t } from "../../../../translate";
import { ReactComponent as ArrowLeft } from "./../../../../components/assets/left-arrow.svg";

const ViewRenterCompany = () => {
    const query = useQuery();
    const companyId = query.get("compid");
    const [renterCompany, setRenterCompany] = useState<RenterCompanyDetailViewModel | undefined>(undefined);
    const [isBlocked, setIsBlocked] = useState<boolean | undefined>();
    const companyIdN = parseInt(companyId || "NaN");
    const { doGetLessorBlocked, doBlockLessorCompany } = useSecureService();
    const { doGetRenterCompanyDetail } = useSecureService();
    useEffect(() => {
        if (!Number.isNaN(companyIdN))
            doGetRenterCompanyDetail(companyIdN).then((data) => {
                setRenterCompany(data);
            });
        //eslint-disable-next-line
    }, []);

    const userId = renterCompany?.id;
    useEffect(() => {
        if (userId) {
            doGetLessorBlocked(userId).then((data) => {
                setIsBlocked(data?.isBlocked);
            });
        }
        //eslint-disable-next-line
    }, []);

    const onBlockClick = async () => {
        if (userId === undefined) return;
        doBlockLessorCompany(userId).then(() => {
            doGetLessorBlocked(userId).then((data) => {
                setIsBlocked(data?.isBlocked);
            });
        });
    };
    const navigate = useNavigate();
    const onRequestsClick = () => {
        navigate("/requests?compname=" + renterCompany?.companyName);
    };

    return (
        <ListContainer activePage={"lessor-companies"}>
            <Row gutter={[16, 16]} justify="space-between" align="middle">
                <Col md={24}>
                    <ArrowLeft />
                    {t("company.details")}
                </Col>
                <Col md={24}>
                    <RenterCompanyInfoPart
                        viewType="lessorCompany"
                        isBlocked={isBlocked}
                        renterCompany={renterCompany}
                        compId={companyIdN}
                        onClickRequestFormList={onRequestsClick}
                        userId={userId || 0}
                        onBlockClick={onBlockClick}
                    ></RenterCompanyInfoPart>
                </Col>
            </Row>
        </ListContainer>
    );
};

export default ViewRenterCompany;
