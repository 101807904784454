import BoxContainer from "./box-container";
import styles from "../styles/components.module.scss";

export type BigboxContainerProps = {
    title: string;
    children?: any;
};

const BigboxContainer = (props: BigboxContainerProps) => {
    return (
        <BoxContainer>
            <div className={styles.bigboxContainer}>
                <div className={styles.bigboxTitle}>{props.title}</div>
                {props.children}
            </div>
        </BoxContainer>
    );
};
export default BigboxContainer;
