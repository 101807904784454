/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CompanyBasicModel,
    CompanyBasicModelFromJSON,
    CompanyBasicModelToJSON,
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    RequestFormCreateModel,
    RequestFormCreateModelFromJSON,
    RequestFormCreateModelToJSON,
    RequestFormCreateWithRegisterModel,
    RequestFormCreateWithRegisterModelFromJSON,
    RequestFormCreateWithRegisterModelToJSON,
    RequestFormEditModel,
    RequestFormEditModelFromJSON,
    RequestFormEditModelToJSON,
    RequestFormRejectModel,
    RequestFormRejectModelFromJSON,
    RequestFormRejectModelToJSON,
    RequestFormSearchModel,
    RequestFormSearchModelFromJSON,
    RequestFormSearchModelToJSON,
    RequestFormViewModel,
    RequestFormViewModelFromJSON,
    RequestFormViewModelToJSON,
    RequestFormViewModelPagedDataModel,
    RequestFormViewModelPagedDataModelFromJSON,
    RequestFormViewModelPagedDataModelToJSON,
} from '../models';

export interface RequestFormApproveRequestFormIdPostRequest {
    requestFormId: number;
    requestFormRejectModel?: RequestFormRejectModel;
}

export interface RequestFormCompanyDetailRequestFormIdOfferFormOfferFormIdGetRequest {
    requestFormId: number;
    offerFormId: number;
}

export interface RequestFormCreatePostRequest {
    requestFormCreateModel?: RequestFormCreateModel;
}

export interface RequestFormCreateWithRegisterPostRequest {
    requestFormCreateWithRegisterModel?: RequestFormCreateWithRegisterModel;
}

export interface RequestFormIdGetRequest {
    id: number;
}

export interface RequestFormRejectRequestFormIdPostRequest {
    requestFormId: number;
    requestFormRejectModel?: RequestFormRejectModel;
}

export interface RequestFormSearchPostRequest {
    requestFormSearchModel?: RequestFormSearchModel;
}

export interface RequestFormSuggestRequestFormIdPostRequest {
    requestFormId: number;
    requestBody?: Array<number>;
}

export interface RequestFormUpdatePostRequest {
    requestFormEditModel?: RequestFormEditModel;
}

/**
 * 
 */
export class RequestFormApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async requestFormApproveRequestFormIdPostRaw(requestParameters: RequestFormApproveRequestFormIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestFormId === null || requestParameters.requestFormId === undefined) {
            throw new runtime.RequiredError('requestFormId','Required parameter requestParameters.requestFormId was null or undefined when calling requestFormApproveRequestFormIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/approve/{requestFormId}`.replace(`{${"requestFormId"}}`, encodeURIComponent(String(requestParameters.requestFormId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestFormRejectModelToJSON(requestParameters.requestFormRejectModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async requestFormApproveRequestFormIdPost(requestParameters: RequestFormApproveRequestFormIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.requestFormApproveRequestFormIdPostRaw(requestParameters, initOverrides);
    }

    /**
     * Lessor, Renter
     */
    async requestFormCompanyDetailRequestFormIdOfferFormOfferFormIdGetRaw(requestParameters: RequestFormCompanyDetailRequestFormIdOfferFormOfferFormIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CompanyBasicModel>> {
        if (requestParameters.requestFormId === null || requestParameters.requestFormId === undefined) {
            throw new runtime.RequiredError('requestFormId','Required parameter requestParameters.requestFormId was null or undefined when calling requestFormCompanyDetailRequestFormIdOfferFormOfferFormIdGet.');
        }

        if (requestParameters.offerFormId === null || requestParameters.offerFormId === undefined) {
            throw new runtime.RequiredError('offerFormId','Required parameter requestParameters.offerFormId was null or undefined when calling requestFormCompanyDetailRequestFormIdOfferFormOfferFormIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/company-detail/{requestFormId}/offer-form/{offerFormId}`.replace(`{${"requestFormId"}}`, encodeURIComponent(String(requestParameters.requestFormId))).replace(`{${"offerFormId"}}`, encodeURIComponent(String(requestParameters.offerFormId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyBasicModelFromJSON(jsonValue));
    }

    /**
     * Lessor, Renter
     */
    async requestFormCompanyDetailRequestFormIdOfferFormOfferFormIdGet(requestParameters: RequestFormCompanyDetailRequestFormIdOfferFormOfferFormIdGetRequest, initOverrides?: RequestInit): Promise<CompanyBasicModel> {
        const response = await this.requestFormCompanyDetailRequestFormIdOfferFormOfferFormIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Renter
     */
    async requestFormCreatePostRaw(requestParameters: RequestFormCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestFormViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestFormCreateModelToJSON(requestParameters.requestFormCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFormViewModelFromJSON(jsonValue));
    }

    /**
     * Renter
     */
    async requestFormCreatePost(requestParameters: RequestFormCreatePostRequest = {}, initOverrides?: RequestInit): Promise<RequestFormViewModel> {
        const response = await this.requestFormCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( RenterRequestForm = renterrequestform )
     */
    async requestFormCreateWithRegisterPostRaw(requestParameters: RequestFormCreateWithRegisterPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestFormViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/create-with-register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestFormCreateWithRegisterModelToJSON(requestParameters.requestFormCreateWithRegisterModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFormViewModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( RenterRequestForm = renterrequestform )
     */
    async requestFormCreateWithRegisterPost(requestParameters: RequestFormCreateWithRegisterPostRequest = {}, initOverrides?: RequestInit): Promise<RequestFormViewModel> {
        const response = await this.requestFormCreateWithRegisterPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Renter
     */
    async requestFormIdGetRaw(requestParameters: RequestFormIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestFormViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestFormIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFormViewModelFromJSON(jsonValue));
    }

    /**
     * Admin, Renter
     */
    async requestFormIdGet(requestParameters: RequestFormIdGetRequest, initOverrides?: RequestInit): Promise<RequestFormViewModel> {
        const response = await this.requestFormIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async requestFormRejectRequestFormIdPostRaw(requestParameters: RequestFormRejectRequestFormIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestFormId === null || requestParameters.requestFormId === undefined) {
            throw new runtime.RequiredError('requestFormId','Required parameter requestParameters.requestFormId was null or undefined when calling requestFormRejectRequestFormIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/reject/{requestFormId}`.replace(`{${"requestFormId"}}`, encodeURIComponent(String(requestParameters.requestFormId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestFormRejectModelToJSON(requestParameters.requestFormRejectModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async requestFormRejectRequestFormIdPost(requestParameters: RequestFormRejectRequestFormIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.requestFormRejectRequestFormIdPostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin, Renter
     */
    async requestFormSearchPostRaw(requestParameters: RequestFormSearchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestFormViewModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestFormSearchModelToJSON(requestParameters.requestFormSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFormViewModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin, Renter
     */
    async requestFormSearchPost(requestParameters: RequestFormSearchPostRequest = {}, initOverrides?: RequestInit): Promise<RequestFormViewModelPagedDataModel> {
        const response = await this.requestFormSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( Guest = guest )
     */
    async requestFormStatusGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Captcha ( Guest = guest )
     */
    async requestFormStatusGet(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.requestFormStatusGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async requestFormSuggestRequestFormIdPostRaw(requestParameters: RequestFormSuggestRequestFormIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.requestFormId === null || requestParameters.requestFormId === undefined) {
            throw new runtime.RequiredError('requestFormId','Required parameter requestParameters.requestFormId was null or undefined when calling requestFormSuggestRequestFormIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/suggest/{requestFormId}`.replace(`{${"requestFormId"}}`, encodeURIComponent(String(requestParameters.requestFormId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Admin
     */
    async requestFormSuggestRequestFormIdPost(requestParameters: RequestFormSuggestRequestFormIdPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.requestFormSuggestRequestFormIdPostRaw(requestParameters, initOverrides);
    }

    /**
     * Renter, Admin
     */
    async requestFormUpdatePostRaw(requestParameters: RequestFormUpdatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RequestFormViewModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/request-form/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestFormEditModelToJSON(requestParameters.requestFormEditModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RequestFormViewModelFromJSON(jsonValue));
    }

    /**
     * Renter, Admin
     */
    async requestFormUpdatePost(requestParameters: RequestFormUpdatePostRequest = {}, initOverrides?: RequestInit): Promise<RequestFormViewModel> {
        const response = await this.requestFormUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
