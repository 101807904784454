/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';

/**
 * 
 * @export
 * @interface RenterCompanyViewModel
 */
export interface RenterCompanyViewModel {
    /**
     * 
     * @type {number}
     * @memberof RenterCompanyViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyViewModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyViewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyViewModel
     */
    lastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RenterCompanyViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RenterCompanyViewModel
     */
    requestCount?: number;
    /**
     * 
     * @type {number}
     * @memberof RenterCompanyViewModel
     */
    dealCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RenterCompanyViewModel
     */
    isUserActive?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof RenterCompanyViewModel
     */
    lastRequestDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof RenterCompanyViewModel
     */
    offerCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof RenterCompanyViewModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof RenterCompanyViewModel
     */
    serviceRegions?: Array<BasicModel> | null;
}

export function RenterCompanyViewModelFromJSON(json: any): RenterCompanyViewModel {
    return RenterCompanyViewModelFromJSONTyped(json, false);
}

export function RenterCompanyViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenterCompanyViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'requestCount': !exists(json, 'requestCount') ? undefined : json['requestCount'],
        'dealCount': !exists(json, 'dealCount') ? undefined : json['dealCount'],
        'isUserActive': !exists(json, 'isUserActive') ? undefined : json['isUserActive'],
        'lastRequestDate': !exists(json, 'lastRequestDate') ? undefined : (json['lastRequestDate'] === null ? null : new Date(json['lastRequestDate'])),
        'offerCount': !exists(json, 'offerCount') ? undefined : json['offerCount'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : (json['serviceRegions'] === null ? null : (json['serviceRegions'] as Array<any>).map(BasicModelFromJSON)),
    };
}

export function RenterCompanyViewModelToJSON(value?: RenterCompanyViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'name': value.name,
        'lastname': value.lastname,
        'phone': value.phone,
        'email': value.email,
        'requestCount': value.requestCount,
        'dealCount': value.dealCount,
        'isUserActive': value.isUserActive,
        'lastRequestDate': value.lastRequestDate === undefined ? undefined : (value.lastRequestDate === null ? null : value.lastRequestDate.toISOString()),
        'offerCount': value.offerCount,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'serviceRegions': value.serviceRegions === undefined ? undefined : (value.serviceRegions === null ? null : (value.serviceRegions as Array<any>).map(BasicModelToJSON)),
    };
}

