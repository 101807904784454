/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineSearchFilterModel
 */
export interface MachineSearchFilterModel {
    /**
     * 
     * @type {number}
     * @memberof MachineSearchFilterModel
     */
    categoryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSearchFilterModel
     */
    subCategoryId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MachineSearchFilterModel
     */
    modelName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSearchFilterModel
     */
    brandId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSearchFilterModel
     */
    productionYearFrom?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineSearchFilterModel
     */
    productionYearTo?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof MachineSearchFilterModel
     */
    availableFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MachineSearchFilterModel
     */
    availableTo?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MachineSearchFilterModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineSearchFilterModel
     */
    searchKeyword?: string | null;
}

export function MachineSearchFilterModelFromJSON(json: any): MachineSearchFilterModel {
    return MachineSearchFilterModelFromJSONTyped(json, false);
}

export function MachineSearchFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineSearchFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'modelName': !exists(json, 'modelName') ? undefined : json['modelName'],
        'brandId': !exists(json, 'brandId') ? undefined : json['brandId'],
        'productionYearFrom': !exists(json, 'productionYearFrom') ? undefined : json['productionYearFrom'],
        'productionYearTo': !exists(json, 'productionYearTo') ? undefined : json['productionYearTo'],
        'availableFrom': !exists(json, 'availableFrom') ? undefined : (json['availableFrom'] === null ? null : new Date(json['availableFrom'])),
        'availableTo': !exists(json, 'availableTo') ? undefined : (json['availableTo'] === null ? null : new Date(json['availableTo'])),
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'searchKeyword': !exists(json, 'searchKeyword') ? undefined : json['searchKeyword'],
    };
}

export function MachineSearchFilterModelToJSON(value?: MachineSearchFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categoryId': value.categoryId,
        'subCategoryId': value.subCategoryId,
        'modelName': value.modelName,
        'brandId': value.brandId,
        'productionYearFrom': value.productionYearFrom,
        'productionYearTo': value.productionYearTo,
        'availableFrom': value.availableFrom === undefined ? undefined : (value.availableFrom === null ? null : value.availableFrom.toISOString()),
        'availableTo': value.availableTo === undefined ? undefined : (value.availableTo === null ? null : value.availableTo.toISOString()),
        'companyName': value.companyName,
        'searchKeyword': value.searchKeyword,
    };
}

