import { Col, message, Row, TablePaginationConfig } from "antd";
import { useEffect, useRef, useState } from "react";
import ListContainer from "../../../components/containers/list-container";
import { DistrictModel, RequestFormSearchModel, RequestFormViewModel, SubCategoryModel } from "../../../service/models";
import { PaginationType, useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/components/CommonPropTypes";
import { useAtom } from "jotai";
import { loadingAtom, requestFormAtom } from "../../../store/global-atoms";
import DataTable from "../../../components/atomics/data-table";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import { ReactComponent as CsvDownload } from "../../../components/assets/download-csv.svg";
import RequestAndOfferStatus from "../../../components/atomics/request-and-offer-status";
import { useNavigate } from "react-router-dom";
import RequestListFilter from "../../../components/containers/requests-filter";

type StatusWithCountModel = {
    status?: string | undefined | null;
    count?: number | undefined;
};
type TableModel = {
    nameLastName: string;
    fromAndTo: string;
    districtAndCity: string;
    subCategoryName?: {};
    category?: string | null;
    allAttachments?: string | null;
    statusWithCount?: StatusWithCountModel;
} & RequestFormViewModel;

const castToTableModelArray = (data: RequestFormViewModel[] | null | undefined) => {
    const newList = data?.map((item) => {
        let attachmentsValue = item?.attachments?.map((atc) => {
            return atc.name;
        });
        if (item?.attachmentsOther !== undefined) attachmentsValue?.push(item.attachmentsOther);

        let count = 0;
        if (item?.offerForms) {
            item?.offerForms?.map((val) => {
                if (val.status === "Approved" || val.status === "Offered") {
                    count = count + 1;
                }
                return null
            });
        }
        const tableModel: TableModel = {
            id: item.id,
            nameLastName: item.name + " " + item.lastName,
            companyName: item.companyName,
            email: item.email,
            phone: item.phone,
            districtAndCity: item.district?.name + " " + item.district?.city?.name,
            fromAndTo: item.requestDateFrom?.toLocaleDateString() + " - " + item.requestDateTo?.toLocaleDateString(),
            creationDate: item.creationDate,
            subCategoryName: {
                name: item.subCategory?.name,
                subCategoryOther: item.subCategoryOther
            },
            allAttachments: attachmentsValue?.join(", "),
            description: item.description,
            status: item.status,
            statusWithCount: {
                status: item.status,
                count: count,
            },
            dailyWorkingTime: item.dailyWorkingTime,
            machineCount: item.machineCount,
            ...item.machineFeatures,
            ...item.additionalServices,
            ...item,
        };
        return tableModel;
    });
    return newList;
};
const Requests = () => {
    const navigate = useNavigate();
    const { doGetRequests } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [, ViewRequest] = useAtom(requestFormAtom);
    const [requests, setRequests] = useState<TableModel[] | undefined>(undefined);
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });
    const [filterObj, setFilterObj] = useState<{
        filterModel: RequestFormSearchModel;
    }>({
        filterModel: {},
    });
    const [itemCount, setItemCount] = useState<number | undefined>(1);

    const columns = [
        {
            title: t("requests.table.requestId"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("requests.table.nameLastName"),
            dataIndex: "nameLastName",
            key: "nameLastName",
        },
        {
            title: t("requests.table.machineCategory"),
            dataIndex: "subCategory",
            key: "subCategory",
            render: (val: SubCategoryModel) => <span>{val.category?.name}</span>,
        },
        {
            title: t("requests.table.machineName"),
            dataIndex: "subCategoryName",
            key: "subCategoryName",
            render: (val: any) => <span>{val?.subCategoryOther ?? val?.name}</span>,
        },
        {
            title: t("requests.table.machineCount"),
            dataIndex: "machineCount",
            key: "machineCount",
        },
        {
            title: t("requests.table.fromAndTo"),
            dataIndex: "fromAndTo",
            key: "fromAndTo",
        },
        {
            title: t("requests.table.description"),
            dataIndex: "description",
            key: "description",
            ellipsis: false,
            render: (val: string | undefined) => {
                return <span>{val?.truncate(100, true)}</span>;
            },
        },
        {
            title: t("requests.table.district"),
            dataIndex: "district",
            key: "district",
            render: (dist: DistrictModel) => (
                <span>
                    {dist.name}
                    <br></br>
                    {dist.city?.name}
                </span>
            ),
        },
        {
            title: t("requests.table.isRenterUserActive"),
            dataIndex: "isUserActive",
            key: "isUserActive",
            render: (val: boolean) => <> {val ? t("requests.table.isFullRegister") : t("requests.table.isPreRegister")}</>,
        },
        {
            title: t("requests.table.companyName"),
            dataIndex: "companyName",
            key: "companyName",
        },
        {
            title: t("requests.table.status"),
            dataIndex: "statusWithCount",
            key: "status",
            render: (statusWithCount: StatusWithCountModel) => {
                return (
                    <RequestAndOfferStatus
                        showRequestStatus={true}
                        showOfferStatus={false}
                        requestStatus={statusWithCount.status}
                        count={statusWithCount.count}
                    ></RequestAndOfferStatus>
                );
            },
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current };
        });
    };

    useEffect(() => {
        const fillFormsList = async () => {
            try {
                const result = await doGetRequests({
                    pageNo: pagination.current,
                    pageSize: pagination.pageSize,
                    categoryId: filterObj?.filterModel?.categoryId,
                    subCategoryId: filterObj?.filterModel?.subCategoryId,
                    cityId: filterObj?.filterModel?.cityId,
                    creationDateFrom: filterObj?.filterModel?.creationDateFrom,
                    requestDateFrom: filterObj?.filterModel?.requestDateFrom,
                    status: filterObj?.filterModel?.status,
                });
                if (result?.data !== null) {
                    setItemCount(result?.itemCount);
                    setRequests(castToTableModelArray(result?.data));
                }
            } catch (err: any) {
                message.error(err.toString());
            }
        };
        fillFormsList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        if (!itemCount || itemCount <= 0) {
            return;
        }
        const result = await doGetRequests({ pageNo: 1, pageSize: itemCount });
        const exportData = castToTableModelArray(result?.data);
        if (exportData?.length !== undefined && exportData?.length > 0) {
            setAllRows(exportData);
            csvRef.current?.link.click();
        }
    };
    const rowClickHandler = async (record: RequestFormViewModel) => {
        ViewRequest(record);
        navigate("/requests/view?reqid=" + record.id);
    };

    return (
        <ListContainer>
            <RequestListFilter
                isAdmin={true}
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params?.searchFilterModel || {},
                    });
                }}
            ></RequestListFilter>
            <Row justify="space-between" align="middle">
                <Col>{t("menu.requests")}</Col>
                <Col style={{ float: "right" }}>
                    <SecondaryButton onClick={exportClick} style={{ width: "initial" }}>
                        <CsvDownload style={{ display: "flex" }} />
                    </SecondaryButton>
                </Col>
            </Row>
            <CSVLink filename="requestforms.csv" ref={csvRef} data={allRows} separator={";"}></CSVLink>
            <br />
            <br />
            <DataTable
                onRowClick={rowClickHandler}
                onChange={handleChange}
                columns={columns}
                dataSource={requests}
                loading={loading}
                pagination={{ ...pagination, total: itemCount }}
            />
        </ListContainer>
    );
};

export default Requests;
