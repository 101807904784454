/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActivationModel,
    ActivationModelFromJSON,
    ActivationModelToJSON,
    BasicModel,
    BasicModelFromJSON,
    BasicModelToJSON,
    BlockStatusViewModel,
    BlockStatusViewModelFromJSON,
    BlockStatusViewModelToJSON,
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    ForgotPasswordViewModel,
    ForgotPasswordViewModelFromJSON,
    ForgotPasswordViewModelToJSON,
    Int32ValidatedModel,
    Int32ValidatedModelFromJSON,
    Int32ValidatedModelToJSON,
    LoginRequestModel,
    LoginRequestModelFromJSON,
    LoginRequestModelToJSON,
    LoginResponseModel,
    LoginResponseModelFromJSON,
    LoginResponseModelToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RenterCompanyAgreementsViewModel,
    RenterCompanyAgreementsViewModelFromJSON,
    RenterCompanyAgreementsViewModelToJSON,
    RenterCompanyCreateModel,
    RenterCompanyCreateModelFromJSON,
    RenterCompanyCreateModelToJSON,
    RenterCompanyDetailViewModel,
    RenterCompanyDetailViewModelFromJSON,
    RenterCompanyDetailViewModelToJSON,
    RenterCompanyInfoModel,
    RenterCompanyInfoModelFromJSON,
    RenterCompanyInfoModelToJSON,
    RenterCompanyInfoUpdateModel,
    RenterCompanyInfoUpdateModelFromJSON,
    RenterCompanyInfoUpdateModelToJSON,
    RenterCompanyPaymentInfoViewModel,
    RenterCompanyPaymentInfoViewModelFromJSON,
    RenterCompanyPaymentInfoViewModelToJSON,
    RenterCompanyPaymentUpdateModel,
    RenterCompanyPaymentUpdateModelFromJSON,
    RenterCompanyPaymentUpdateModelToJSON,
    RenterCompanyProfileCreateModel,
    RenterCompanyProfileCreateModelFromJSON,
    RenterCompanyProfileCreateModelToJSON,
    RenterCompanyProfileViewModel,
    RenterCompanyProfileViewModelFromJSON,
    RenterCompanyProfileViewModelToJSON,
    RenterCompanySearchModel,
    RenterCompanySearchModelFromJSON,
    RenterCompanySearchModelToJSON,
    RenterCompanyViewModelPagedDataModel,
    RenterCompanyViewModelPagedDataModelFromJSON,
    RenterCompanyViewModelPagedDataModelToJSON,
    RenterUserCreateModel,
    RenterUserCreateModelFromJSON,
    RenterUserCreateModelToJSON,
    ResetPasswordViewModel,
    ResetPasswordViewModelFromJSON,
    ResetPasswordViewModelToJSON,
} from '../models';

export interface RenterCompanyActivatePostRequest {
    activationModel?: ActivationModel;
}

export interface RenterCompanyChangeBlockStatusUserIdGetRequest {
    userId: number;
}

export interface RenterCompanyForgotPasswordPostRequest {
    forgotPasswordViewModel?: ForgotPasswordViewModel;
}

export interface RenterCompanyGetBlockStatusUserIdGetRequest {
    userId: number;
}

export interface RenterCompanyIdGetRequest {
    id: number;
}

export interface RenterCompanyInfoUserIdGetRequest {
    userId: number;
}

export interface RenterCompanyInfoUserIdPostRequest {
    userId: number;
    renterCompanyInfoUpdateModel?: RenterCompanyInfoUpdateModel;
}

export interface RenterCompanyPaymentInfoUserIdGetRequest {
    userId: number;
}

export interface RenterCompanyPaymentInfoUserIdPostRequest {
    userId: number;
    renterCompanyPaymentUpdateModel?: RenterCompanyPaymentUpdateModel;
}

export interface RenterCompanyProfileUserIdGetRequest {
    userId: number;
}

export interface RenterCompanyProfileUserIdPostRequest {
    userId: number;
    renterCompanyProfileCreateModel?: RenterCompanyProfileCreateModel;
}

export interface RenterCompanyRegisterWithRequestPostRequest {
    renterUserCreateModel?: RenterUserCreateModel;
}

export interface RenterCompanyResendActivationCodeUserIdGetRequest {
    userId: number;
}

export interface RenterCompanyResetPasswordPostRequest {
    resetPasswordViewModel?: ResetPasswordViewModel;
}

export interface RenterCompanySearchPostRequest {
    renterCompanySearchModel?: RenterCompanySearchModel;
}

export interface RenterCompanySignInPostRequest {
    loginRequestModel?: LoginRequestModel;
}

export interface RenterCompanySignUpPostRequest {
    renterCompanyCreateModel?: RenterCompanyCreateModel;
}

export interface RenterCompanyUpdateAgreementsVersionsPostRequest {
    renterCompanyAgreementsViewModel?: RenterCompanyAgreementsViewModel;
}

/**
 * 
 */
export class RenterCompanyApi extends runtime.BaseAPI {

    /**
     * Captcha ( RenterActivate = renteractivate )
     */
    async renterCompanyActivatePostRaw(requestParameters: RenterCompanyActivatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ActivationModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/activate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ActivationModelToJSON(requestParameters.activationModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ActivationModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( RenterActivate = renteractivate )
     */
    async renterCompanyActivatePost(requestParameters: RenterCompanyActivatePostRequest = {}, initOverrides?: RequestInit): Promise<ActivationModel> {
        const response = await this.renterCompanyActivatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async renterCompanyChangeBlockStatusUserIdGetRaw(requestParameters: RenterCompanyChangeBlockStatusUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BlockStatusViewModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling renterCompanyChangeBlockStatusUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/change-block-status/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockStatusViewModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async renterCompanyChangeBlockStatusUserIdGet(requestParameters: RenterCompanyChangeBlockStatusUserIdGetRequest, initOverrides?: RequestInit): Promise<BlockStatusViewModel> {
        const response = await this.renterCompanyChangeBlockStatusUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async renterCompanyCompanyListGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BasicModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/company-list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BasicModelFromJSON));
    }

    /**
     * Admin
     */
    async renterCompanyCompanyListGet(initOverrides?: RequestInit): Promise<Array<BasicModel>> {
        const response = await this.renterCompanyCompanyListGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( RenterForgotPassword = renterforgotpassword )
     */
    async renterCompanyForgotPasswordPostRaw(requestParameters: RenterCompanyForgotPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordViewModelToJSON(requestParameters.forgotPasswordViewModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( RenterForgotPassword = renterforgotpassword )
     */
    async renterCompanyForgotPasswordPost(requestParameters: RenterCompanyForgotPasswordPostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.renterCompanyForgotPasswordPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async renterCompanyGetBlockStatusUserIdGetRaw(requestParameters: RenterCompanyGetBlockStatusUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BlockStatusViewModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling renterCompanyGetBlockStatusUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/get-block-status/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockStatusViewModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async renterCompanyGetBlockStatusUserIdGet(requestParameters: RenterCompanyGetBlockStatusUserIdGetRequest, initOverrides?: RequestInit): Promise<BlockStatusViewModel> {
        const response = await this.renterCompanyGetBlockStatusUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin
     */
    async renterCompanyIdGetRaw(requestParameters: RenterCompanyIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RenterCompanyDetailViewModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling renterCompanyIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenterCompanyDetailViewModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async renterCompanyIdGet(requestParameters: RenterCompanyIdGetRequest, initOverrides?: RequestInit): Promise<RenterCompanyDetailViewModel> {
        const response = await this.renterCompanyIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Renter
     */
    async renterCompanyInfoUserIdGetRaw(requestParameters: RenterCompanyInfoUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RenterCompanyInfoModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling renterCompanyInfoUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/info/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenterCompanyInfoModelFromJSON(jsonValue));
    }

    /**
     * Admin, Renter
     */
    async renterCompanyInfoUserIdGet(requestParameters: RenterCompanyInfoUserIdGetRequest, initOverrides?: RequestInit): Promise<RenterCompanyInfoModel> {
        const response = await this.renterCompanyInfoUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Renter
     */
    async renterCompanyInfoUserIdPostRaw(requestParameters: RenterCompanyInfoUserIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RenterCompanyInfoUpdateModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling renterCompanyInfoUserIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/info/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenterCompanyInfoUpdateModelToJSON(requestParameters.renterCompanyInfoUpdateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenterCompanyInfoUpdateModelFromJSON(jsonValue));
    }

    /**
     * Admin, Renter
     */
    async renterCompanyInfoUserIdPost(requestParameters: RenterCompanyInfoUserIdPostRequest, initOverrides?: RequestInit): Promise<RenterCompanyInfoUpdateModel> {
        const response = await this.renterCompanyInfoUserIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Renter
     */
    async renterCompanyPaymentInfoUserIdGetRaw(requestParameters: RenterCompanyPaymentInfoUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RenterCompanyPaymentInfoViewModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling renterCompanyPaymentInfoUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/payment-info/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenterCompanyPaymentInfoViewModelFromJSON(jsonValue));
    }

    /**
     * Admin, Renter
     */
    async renterCompanyPaymentInfoUserIdGet(requestParameters: RenterCompanyPaymentInfoUserIdGetRequest, initOverrides?: RequestInit): Promise<RenterCompanyPaymentInfoViewModel> {
        const response = await this.renterCompanyPaymentInfoUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Renter
     */
    async renterCompanyPaymentInfoUserIdPostRaw(requestParameters: RenterCompanyPaymentInfoUserIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RenterCompanyPaymentUpdateModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling renterCompanyPaymentInfoUserIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/payment-info/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenterCompanyPaymentUpdateModelToJSON(requestParameters.renterCompanyPaymentUpdateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenterCompanyPaymentUpdateModelFromJSON(jsonValue));
    }

    /**
     * Admin, Renter
     */
    async renterCompanyPaymentInfoUserIdPost(requestParameters: RenterCompanyPaymentInfoUserIdPostRequest, initOverrides?: RequestInit): Promise<RenterCompanyPaymentUpdateModel> {
        const response = await this.renterCompanyPaymentInfoUserIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Renter
     */
    async renterCompanyProfileUserIdGetRaw(requestParameters: RenterCompanyProfileUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RenterCompanyProfileViewModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling renterCompanyProfileUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/profile/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenterCompanyProfileViewModelFromJSON(jsonValue));
    }

    /**
     * Admin, Renter
     */
    async renterCompanyProfileUserIdGet(requestParameters: RenterCompanyProfileUserIdGetRequest, initOverrides?: RequestInit): Promise<RenterCompanyProfileViewModel> {
        const response = await this.renterCompanyProfileUserIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Renter
     */
    async renterCompanyProfileUserIdPostRaw(requestParameters: RenterCompanyProfileUserIdPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RenterCompanyProfileCreateModel>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling renterCompanyProfileUserIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/profile/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenterCompanyProfileCreateModelToJSON(requestParameters.renterCompanyProfileCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenterCompanyProfileCreateModelFromJSON(jsonValue));
    }

    /**
     * Admin, Renter
     */
    async renterCompanyProfileUserIdPost(requestParameters: RenterCompanyProfileUserIdPostRequest, initOverrides?: RequestInit): Promise<RenterCompanyProfileCreateModel> {
        const response = await this.renterCompanyProfileUserIdPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( RenterRequestForm = renterrequestform )
     */
    async renterCompanyRegisterWithRequestPostRaw(requestParameters: RenterCompanyRegisterWithRequestPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/register-with-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenterUserCreateModelToJSON(requestParameters.renterUserCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( RenterRequestForm = renterrequestform )
     */
    async renterCompanyRegisterWithRequestPost(requestParameters: RenterCompanyRegisterWithRequestPostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.renterCompanyRegisterWithRequestPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( RenterResendActivate = renterresendactivate )
     */
    async renterCompanyResendActivationCodeUserIdGetRaw(requestParameters: RenterCompanyResendActivationCodeUserIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling renterCompanyResendActivationCodeUserIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/resend-activation-code/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Captcha ( RenterResendActivate = renterresendactivate )
     */
    async renterCompanyResendActivationCodeUserIdGet(requestParameters: RenterCompanyResendActivationCodeUserIdGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.renterCompanyResendActivationCodeUserIdGetRaw(requestParameters, initOverrides);
    }

    /**
     * Captcha ( RenterResetPassword = renterresetpassword )
     */
    async renterCompanyResetPasswordPostRaw(requestParameters: RenterCompanyResetPasswordPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordViewModelToJSON(requestParameters.resetPasswordViewModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Captcha ( RenterResetPassword = renterresetpassword )
     */
    async renterCompanyResetPasswordPost(requestParameters: RenterCompanyResetPasswordPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.renterCompanyResetPasswordPostRaw(requestParameters, initOverrides);
    }

    /**
     * Admin, Renter
     */
    async renterCompanySearchPostRaw(requestParameters: RenterCompanySearchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RenterCompanyViewModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenterCompanySearchModelToJSON(requestParameters.renterCompanySearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenterCompanyViewModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin, Renter
     */
    async renterCompanySearchPost(requestParameters: RenterCompanySearchPostRequest = {}, initOverrides?: RequestInit): Promise<RenterCompanyViewModelPagedDataModel> {
        const response = await this.renterCompanySearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Captcha ( RenterSignIn = rentersignin )
     */
    async renterCompanySignInPostRaw(requestParameters: RenterCompanySignInPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestModelToJSON(requestParameters.loginRequestModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( RenterSignIn = rentersignin )
     */
    async renterCompanySignInPost(requestParameters: RenterCompanySignInPostRequest = {}, initOverrides?: RequestInit): Promise<LoginResponseModel> {
        const response = await this.renterCompanySignInPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Renter
     */
    async renterCompanySignOutGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/sign-out`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Renter
     */
    async renterCompanySignOutGet(initOverrides?: RequestInit): Promise<void> {
        await this.renterCompanySignOutGetRaw(initOverrides);
    }

    /**
     * Captcha ( RenterSignUp = rentersignup )
     */
    async renterCompanySignUpPostRaw(requestParameters: RenterCompanySignUpPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Int32ValidatedModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/sign-up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenterCompanyCreateModelToJSON(requestParameters.renterCompanyCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Int32ValidatedModelFromJSON(jsonValue));
    }

    /**
     * Captcha ( RenterSignUp = rentersignup )
     */
    async renterCompanySignUpPost(requestParameters: RenterCompanySignUpPostRequest = {}, initOverrides?: RequestInit): Promise<Int32ValidatedModel> {
        const response = await this.renterCompanySignUpPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Renter
     */
    async renterCompanySwitchToLessorDashboardGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<LoginResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/switch-to-lessor-dashboard`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseModelFromJSON(jsonValue));
    }

    /**
     * Renter
     */
    async renterCompanySwitchToLessorDashboardGet(initOverrides?: RequestInit): Promise<LoginResponseModel> {
        const response = await this.renterCompanySwitchToLessorDashboardGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Renter
     */
    async renterCompanyUpdateAgreementsVersionsPostRaw(requestParameters: RenterCompanyUpdateAgreementsVersionsPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/renter-company/update-agreements-versions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenterCompanyAgreementsViewModelToJSON(requestParameters.renterCompanyAgreementsViewModel),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Renter
     */
    async renterCompanyUpdateAgreementsVersionsPost(requestParameters: RenterCompanyUpdateAgreementsVersionsPostRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.renterCompanyUpdateAgreementsVersionsPostRaw(requestParameters, initOverrides);
    }

}
