/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OfferStatus,
    OfferStatusFromJSON,
    OfferStatusFromJSONTyped,
    OfferStatusToJSON,
} from './OfferStatus';

/**
 * 
 * @export
 * @interface OfferFormSearchModel
 */
export interface OfferFormSearchModel {
    /**
     * 
     * @type {number}
     * @memberof OfferFormSearchModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormSearchModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormSearchModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferFormSearchModel
     */
    lessorCompanyName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormSearchModel
     */
    categoryId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormSearchModel
     */
    subCategoryId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormSearchModel
     */
    creationDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormSearchModel
     */
    creationDateTo?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormSearchModel
     */
    requestDateFrom?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormSearchModel
     */
    requestDateTo?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormSearchModel
     */
    districtId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferFormSearchModel
     */
    cityId?: number | null;
    /**
     * 
     * @type {Array<OfferStatus>}
     * @memberof OfferFormSearchModel
     */
    status?: Array<OfferStatus> | null;
}

export function OfferFormSearchModelFromJSON(json: any): OfferFormSearchModel {
    return OfferFormSearchModelFromJSONTyped(json, false);
}

export function OfferFormSearchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormSearchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'lessorCompanyName': !exists(json, 'lessorCompanyName') ? undefined : json['lessorCompanyName'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'subCategoryId': !exists(json, 'subCategoryId') ? undefined : json['subCategoryId'],
        'creationDateFrom': !exists(json, 'creationDateFrom') ? undefined : (json['creationDateFrom'] === null ? null : new Date(json['creationDateFrom'])),
        'creationDateTo': !exists(json, 'creationDateTo') ? undefined : (json['creationDateTo'] === null ? null : new Date(json['creationDateTo'])),
        'requestDateFrom': !exists(json, 'requestDateFrom') ? undefined : (json['requestDateFrom'] === null ? null : new Date(json['requestDateFrom'])),
        'requestDateTo': !exists(json, 'requestDateTo') ? undefined : (json['requestDateTo'] === null ? null : new Date(json['requestDateTo'])),
        'districtId': !exists(json, 'districtId') ? undefined : json['districtId'],
        'cityId': !exists(json, 'cityId') ? undefined : json['cityId'],
        'status': !exists(json, 'status') ? undefined : (json['status'] === null ? null : (json['status'] as Array<any>).map(OfferStatusFromJSON)),
    };
}

export function OfferFormSearchModelToJSON(value?: OfferFormSearchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'lessorCompanyName': value.lessorCompanyName,
        'categoryId': value.categoryId,
        'subCategoryId': value.subCategoryId,
        'creationDateFrom': value.creationDateFrom === undefined ? undefined : (value.creationDateFrom === null ? null : value.creationDateFrom.toISOString()),
        'creationDateTo': value.creationDateTo === undefined ? undefined : (value.creationDateTo === null ? null : value.creationDateTo.toISOString()),
        'requestDateFrom': value.requestDateFrom === undefined ? undefined : (value.requestDateFrom === null ? null : value.requestDateFrom.toISOString()),
        'requestDateTo': value.requestDateTo === undefined ? undefined : (value.requestDateTo === null ? null : value.requestDateTo.toISOString()),
        'districtId': value.districtId,
        'cityId': value.cityId,
        'status': value.status === undefined ? undefined : (value.status === null ? null : (value.status as Array<any>).map(OfferStatusToJSON)),
    };
}

