/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelToJSON,
    ErrorModel,
    ErrorModelFromJSON,
    ErrorModelToJSON,
    MachineCreateModel,
    MachineCreateModelFromJSON,
    MachineCreateModelToJSON,
    MachineDetailModel,
    MachineDetailModelFromJSON,
    MachineDetailModelToJSON,
    MachineDetailModelPagedDataModel,
    MachineDetailModelPagedDataModelFromJSON,
    MachineDetailModelPagedDataModelToJSON,
    MachineEditModel,
    MachineEditModelFromJSON,
    MachineEditModelToJSON,
    MachineSearchModel,
    MachineSearchModelFromJSON,
    MachineSearchModelToJSON,
    OfferFormMachineDetailViewModel,
    OfferFormMachineDetailViewModelFromJSON,
    OfferFormMachineDetailViewModelToJSON,
} from '../models';

export interface IdsDeleteRequest {
    requestBody?: Array<number>;
}

export interface MachineCreatePostRequest {
    machineCreateModel?: MachineCreateModel;
}

export interface MachineGetSuitableMachinesForOfferOfferIdGetRequest {
    offerId: number;
}

export interface MachineIdDeleteRequest {
    id: number;
}

export interface MachineIdGetRequest {
    id: number;
}

export interface MachineIdOfferFormsGetRequest {
    id: number;
}

export interface MachineMachineIdOfferFormOfferFormIdGetRequest {
    machineId: number;
    offerFormId: number;
}

export interface MachineSearchPostRequest {
    machineSearchModel?: MachineSearchModel;
}

export interface MachineUpdatePostRequest {
    machineEditModel?: MachineEditModel;
}

/**
 * 
 */
export class MachineApi extends runtime.BaseAPI {

    /**
     * Admin
     */
    async idsDeleteRaw(requestParameters: IdsDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MachineDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ids`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin
     */
    async idsDelete(requestParameters: IdsDeleteRequest = {}, initOverrides?: RequestInit): Promise<MachineDetailModel> {
        const response = await this.idsDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineCreatePostRaw(requestParameters: MachineCreatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MachineDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineCreateModelToJSON(requestParameters.machineCreateModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineCreatePost(requestParameters: MachineCreatePostRequest = {}, initOverrides?: RequestInit): Promise<MachineDetailModel> {
        const response = await this.machineCreatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Lessor
     */
    async machineGetSuitableMachinesForOfferOfferIdGetRaw(requestParameters: MachineGetSuitableMachinesForOfferOfferIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BasicModel>>> {
        if (requestParameters.offerId === null || requestParameters.offerId === undefined) {
            throw new runtime.RequiredError('offerId','Required parameter requestParameters.offerId was null or undefined when calling machineGetSuitableMachinesForOfferOfferIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/get-suitable-machines-for-offer/{offerId}`.replace(`{${"offerId"}}`, encodeURIComponent(String(requestParameters.offerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BasicModelFromJSON));
    }

    /**
     * Lessor
     */
    async machineGetSuitableMachinesForOfferOfferIdGet(requestParameters: MachineGetSuitableMachinesForOfferOfferIdGetRequest, initOverrides?: RequestInit): Promise<Array<BasicModel>> {
        const response = await this.machineGetSuitableMachinesForOfferOfferIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineIdDeleteRaw(requestParameters: MachineIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MachineDetailModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling machineIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineIdDelete(requestParameters: MachineIdDeleteRequest, initOverrides?: RequestInit): Promise<MachineDetailModel> {
        const response = await this.machineIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineIdGetRaw(requestParameters: MachineIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MachineDetailModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling machineIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineIdGet(requestParameters: MachineIdGetRequest, initOverrides?: RequestInit): Promise<MachineDetailModel> {
        const response = await this.machineIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineIdOfferFormsGetRaw(requestParameters: MachineIdOfferFormsGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<OfferFormMachineDetailViewModel>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling machineIdOfferFormsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/{id}/offer-forms`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OfferFormMachineDetailViewModelFromJSON));
    }

    /**
     * Admin, Lessor
     */
    async machineIdOfferFormsGet(requestParameters: MachineIdOfferFormsGetRequest, initOverrides?: RequestInit): Promise<Array<OfferFormMachineDetailViewModel>> {
        const response = await this.machineIdOfferFormsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Renter, Admin
     */
    async machineMachineIdOfferFormOfferFormIdGetRaw(requestParameters: MachineMachineIdOfferFormOfferFormIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MachineDetailModel>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling machineMachineIdOfferFormOfferFormIdGet.');
        }

        if (requestParameters.offerFormId === null || requestParameters.offerFormId === undefined) {
            throw new runtime.RequiredError('offerFormId','Required parameter requestParameters.offerFormId was null or undefined when calling machineMachineIdOfferFormOfferFormIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/{machineId}/offer-form/{offerFormId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))).replace(`{${"offerFormId"}}`, encodeURIComponent(String(requestParameters.offerFormId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Renter, Admin
     */
    async machineMachineIdOfferFormOfferFormIdGet(requestParameters: MachineMachineIdOfferFormOfferFormIdGetRequest, initOverrides?: RequestInit): Promise<MachineDetailModel> {
        const response = await this.machineMachineIdOfferFormOfferFormIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineSearchPostRaw(requestParameters: MachineSearchPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MachineDetailModelPagedDataModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineSearchModelToJSON(requestParameters.machineSearchModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelPagedDataModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineSearchPost(requestParameters: MachineSearchPostRequest = {}, initOverrides?: RequestInit): Promise<MachineDetailModelPagedDataModel> {
        const response = await this.machineSearchPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Admin, Lessor
     */
    async machineUpdatePostRaw(requestParameters: MachineUpdatePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MachineDetailModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/machine/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineEditModelToJSON(requestParameters.machineEditModel),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDetailModelFromJSON(jsonValue));
    }

    /**
     * Admin, Lessor
     */
    async machineUpdatePost(requestParameters: MachineUpdatePostRequest = {}, initOverrides?: RequestInit): Promise<MachineDetailModel> {
        const response = await this.machineUpdatePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
