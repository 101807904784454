import DataTable from "../components/atomics/data-table";
import { OfferFormCommentViewModel } from "../service";
import { t } from "../translate";
import BigboxContainer from "../components/containers/bigbox-container";
import { moment } from "../helpers/moment";

type OfferHistoryPartProps = {
    comments?: OfferFormCommentViewModel[] | null;
};

const OfferHistoryPart = (props: OfferHistoryPartProps) => {
    const cols = [
        {
            title: t("comments.table.date"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("comments.table.user"),
            dataIndex: "user",
            key: "user",
        },
        {
            title: t("comments.table.status"),
            dataIndex: "status",
            key: "status",
            render: (val: string) => {
                return (val === "Edited" ? t("comments.table.edited") : val === "Rejected" ? t("comments.table.rejected") : t("comments.table.approved") )
            }
        },
        {
            title: t("comments.table.note"),
            dataIndex: "comment",
            key: "comment",
            render: (val: string) => {
                return val
            }
        },
    ];
    return (
        <BigboxContainer title={t("offer.view.notesTitle")}>
            {props.comments && <DataTable dataSource={props.comments} columns={cols} pagination={false} />}
        </BigboxContainer>
    );
};

export default OfferHistoryPart;
