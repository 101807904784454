import Login from "./pages";
import ForgotPassword from "./pages/forgot-password";
import Machines from "./pages/admin";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Activate from "./pages/activate";
import AddMachine from "./pages/admin/machines/add";
import ReviewMachine from "./pages/admin/machines/review";
import Dashboard from "./pages/admin/dashboard";
import Requests from "./pages/admin/requests/requests";
import ViewMachine from "./pages/admin/machines/view";
import EditMachine from "./pages/admin/machines/edit";
import ViewRequest from "./pages/admin/requests/view";
import EditRequest from "./pages/admin/requests/edit";
import Offers from "./pages/admin/offers/offers";
import ViewOffer from "./pages/admin/offers/view";
import Lessor from "./pages/admin/companies/lessor";
import ViewLessor from "./pages/admin/companies/lessor/view";
import ViewRenter from "./pages/admin/companies/renter/view";
import Renter from "./pages/admin/companies/renter";
import Applicants from "./pages/admin/applicants";
import Notifications from "./pages/admin/notifications";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/activation" element={<Activate />} />
                <Route path="/reset-password" element={<Activate />} />
                <Route path="/machines" element={<Machines />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/machines/add" element={<AddMachine />} />
                <Route path="/machines/review" element={<ReviewMachine />} />
                <Route path="/machines/view" element={<ViewMachine />} />
                <Route path="/machines/edit" element={<EditMachine />} />
                <Route path="/requests/" element={<Requests />} />
                <Route path="/requests/view" element={<ViewRequest />} />
                <Route path="/requests/edit" element={<EditRequest />} />
                <Route path="/offers/" element={<Offers />} />
                <Route path="/offers/view" element={<ViewOffer />} />
                <Route path="/renter-company/" element={<Renter />} />
                <Route path="/lessor-company/" element={<Lessor />} />
                <Route path="/lessor-company/view" element={<ViewLessor />} />
                <Route path="/renter-company/view" element={<ViewRenter />} />
                <Route path="/applicants" element={<Applicants />} />
                <Route path="/notifications" element={<Notifications />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
