import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import CheckboxReviewer from "../components/atomics/checkbox-reviewer";
import LightTitle from "../components/atomics/light-title";
import SecondaryButton from "../components/atomics/buttons/secondary-button";
import SelectedItemText from "../components/atomics/selected-item-text";
import BigboxContainer from "../components/containers/bigbox-container";
import GrayboxContainer from "../components/containers/graybox-container";
import { BasicModel } from "../service";
import {activeApiBaseUrl} from "../store/app-constants";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import { ViewPartProps } from "../viewmodels/view-page-part-props";

const ViewAdditionalInfoPart = (props: ViewPartProps) => {
    const { doGetRentalTypes } = useSecureService();
    const [availableRentalTypes, setAvailableRentalTypes] = useState<BasicModel[] | undefined>(undefined);
    useEffect(() => {
        const getServiceDatas = async () => {
            const result = await doGetRentalTypes();
            setAvailableRentalTypes(result);
        };
        if (props.viewType === "machine") getServiceDatas();
        // eslint-disable-next-line
    }, []);
    const notes = props.machine ? props.machine.notes : props.request?.description;
    return (
        <BigboxContainer title={t("machines.add.additionalInfoTitle")}>
            <Row gutter={[16, 16]}>
                <BasicModelViewer xs={24} md={24} objKey={t("common.description")} value={notes} />
                {props.machine && (
                    <Col xs={24}>
                        <LightTitle>{t("machines.add.rentalTypesTitle")}</LightTitle>
                        <Row gutter={[12, 12]}>
                            {availableRentalTypes?.map((rentalType) => {
                                var checked = false;
                                var val: string | number | undefined = "";
                                var selected = props.machine?.rentalTypes?.find((item) => item.rentalType?.id === rentalType.id);
                                if (selected) {
                                    checked = true;
                                    val = selected.price + " " + selected.currency?.name;
                                }
                                return (
                                    <CheckboxReviewer
                                        xs={12}
                                        md={6}
                                        inputVisible
                                        checkboxChecked={checked}
                                        checkboxLabel={rentalType.name}
                                        inputLabel={t("common.price")}
                                        inputVal={val}
                                    />
                                );
                            })}
                        </Row>
                    </Col>
                )}
                {props.machine && (
                    <Col xs={24}>
                        <LightTitle>{t("machines.add.documents")}</LightTitle>
                        <Row gutter={[16, 16]}>
                            {
                                //TODO: dokuman tipine gore filter yapmaliyiz.
                                props.machine?.documents
                                    ?.filter((doc) => doc.documentCategory !== "Photo")
                                    .map((doc) => {
                                        return (
                                            <Col xs={24} md={12}>
                                                <GrayboxContainer title={t("machines.add-info."+doc.documentCategory?.charAt(0).toLowerCase() + doc.documentCategory?.slice(1))}>
                                                    <SecondaryButton
                                                        onClick={() => {
                                                            window.open(activeApiBaseUrl + "/Document/" + doc.document?.id);
                                                        }}
                                                    >
                                                        {t("common.review")}
                                                    </SecondaryButton>
                                                </GrayboxContainer>
                                            </Col>
                                        );
                                    })
                            }
                        </Row>
                    </Col>
                )}
                {props.request && (
                    <Col xs={24}>
                        <LightTitle>{t("common.attachments")}</LightTitle>

                        <Row gutter={[8, 8]}>
                            {props.request.attachments?.map((atc) => {
                                return (
                                    <Col xs={12} sm={8}>
                                        <SelectedItemText>{atc.name}</SelectedItemText>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                )}
                {props.request && (
                    <Col xs={24}>
                        <LightTitle>{t("machines.add.documents")}</LightTitle>
                        <Row gutter={[16, 16]}>
                            {
                                //TODO: dokuman tipine gore filter yapmaliyiz.
                                props.request?.documents?.map((doc) => {
                                    return (
                                        <Col xs={24} md={12}>
                                            <GrayboxContainer title={doc.fileName}>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        window.open(activeApiBaseUrl + "/Document/" + doc.id);
                                                    }}
                                                >
                                                    {t("common.review")}
                                                </SecondaryButton>
                                            </GrayboxContainer>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Col>
                )}
            </Row>
        </BigboxContainer>
    );
};

export default ViewAdditionalInfoPart;
