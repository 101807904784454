import { Table, TableProps } from "antd";
import styles from "../../styles/components.module.css";

export type DataTableProps = {
    onRowClick?: (record: any) => void;
} & TableProps<any>;

const DataTable = (props: DataTableProps) => {
    const onRowHandler = (record: any, rowIndex: number | undefined) => {
        return {
            onClick: (event: any) => {
                if (props.onRowClick) props.onRowClick(record);
            },
        };
    };
    return <Table scroll={{ x: "" }} onRow={onRowHandler} {...props} className={styles.dataTable} />;
};

export default DataTable;
