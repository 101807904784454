import styles from "../../styles/btn.module.scss";

type MenuItemButtonProps = {
    isActive?: boolean;
    isVertical?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const MenuItemButton = (props: MenuItemButtonProps) => {
    if (props.isActive)
        return (
            <>
                <div {...props} className={styles.activeMenuItemBtn}>
                    {props.children}
                </div>
            </>
        );
    else
        return (
            <>
                <div {...props} className={styles.menuItemBtn}>
                    {props.children}
                </div>
            </>
        );
};
export default MenuItemButton;
