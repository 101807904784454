import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Row } from "antd";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import CardContainer from "../components/containers/card-container";
import PreloginLayout from "../components/containers/prelogin-layout";
import { LoginRequestModel } from "../service/models/LoginRequestModel";
import { loadingAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import PhoneInput from "../components/atomics/inputs/phone-input";
import { useEffect, useState } from "react";
import SmsInput from "../components/atomics/inputs/sms-input";
import TextButton from "../components/atomics/buttons/text-button";
import { AdminLoginWithOtpModel } from "../service";

const Login = () => {
    const { doLogin, doSendOtp } = useSecureService();
    const navigate = useNavigate();
    const [loading] = useAtom(loadingAtom);
    const [isOtpSend, setIsOtpSend] = useState<boolean>(false);
    const [userId, setUserId] = useState<number>();
    const [counter, setCounter] = useState(0);
    const [userLoginParameters, setUserLoginParameters] = useState<LoginRequestModel>();

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    const onLoginSubmit = async (parameters: any) => {

        if (isOtpSend && counter > 0) {
            let loginModel: AdminLoginWithOtpModel = {
                adminId: userId ?? 0,
                otpCode: parameters?.sms
            };
            const result = await doLogin(loginModel);
            if (result) navigate("./machines");
        }
        else {
            let sendOtpModel: LoginRequestModel = {
                username: parameters?.username,
                password: parameters?.password
            }
            setUserLoginParameters(sendOtpModel);
            const result = await doSendOtp(sendOtpModel);
            if (result) {
                setIsOtpSend(true);
                setCounter(120);
                setUserId(result);
            }
        }
        
    };

    const onResendCodeClick = async () => {
        let sendOtpModel: LoginRequestModel = {
            username: userLoginParameters?.username ?? "",
            password: userLoginParameters?.password ?? "",
        };
        const result = await doSendOtp(sendOtpModel);
        if (result) {
            setIsOtpSend(true);
            setCounter(120);
            setUserId(result);
        }
    }

    return (
        <PreloginLayout footer={t("copyright")}>
            <CardContainer title={t("login.cardTitle")}>
                <Form name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={onLoginSubmit}>
                    {!isOtpSend ? (
                        <div>
                            <PhoneInput prefix />
                            <Form.Item name="password" rules={[{ required: true, message: t("login.password-error") }]}>
                                <Input
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder={t("login.password")}
                                />
                            </Form.Item>
                            <Row justify="space-between">
                                <Form.Item>
                                    <PrimaryButton loading={loading} type="primary" htmlType="submit" className="login-form-button">
                                        {t("login.primary")}
                                    </PrimaryButton>
                                </Form.Item>
                                <Button type="link" onClick={(evt) => navigate("./forgot-password")}>
                                    {t("login.forgot")}
                                </Button>
                            </Row>{" "}
                        </div>
                    ) : (
                        <div>
                            <div>
                                <SmsInput
                                    placeholder={t("sms-verification.smsInput.placeholder")}
                                    validationMessage={t("sms-verification.smsInput.validationMessage")}
                                    label={t("sms-verification.smsInput.label")}
                                />
                            </div>
                            <div>
                                <PrimaryButton loading={loading} htmlType="submit">
                                    {t("common.continue")}
                                </PrimaryButton>
                            </div>
                            <div>
                                <TextButton onClick={() => onResendCodeClick()} type="link" disabled={counter !== 0}>
                                    {t("sms-verification.resend")}
                                    {counter !== 0 && <span>{`(${counter} s)`}</span>}
                                </TextButton>
                            </div>
                        </div>
                    )}
                </Form>
            </CardContainer>
        </PreloginLayout>
    );
};

export default Login;
