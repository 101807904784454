import { Col, Row } from "antd";
import { moment } from "../helpers/moment";
import { useEffect, useState } from "react";
import AccordionPayment from "../components/atomics/accordion-payment";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/containers/modal-layout";
import ModalCardContainer from "../components/containers/modalcard-container";
import { PaymentContainer } from "../components/containers/payment-container";
import { OfferFormBidCommissionCreateModel, OfferFormBidViewModel, OfferFormViewModel } from "../service";
import { t } from "../translate";

type EditPaymentModalProps = {
    req?: OfferFormViewModel;
    bids?: OfferFormBidViewModel[] | null;
    onApproveClick: (data: OfferFormBidCommissionCreateModel[] | undefined) => void;
} & ModalLayoutProps;

const EditPaymentModal = (props: EditPaymentModalProps) => {
    const [bids, setBids] = useState<OfferFormBidViewModel[] | null | undefined>(undefined);

    useEffect(() => {
        setBids(props.bids);
        //eslint-disable-next-line
    }, [props.bids]);

    const onApproveClick = async () => {
        //TODO: Validasyonları ekle. Valid ise devam et. değilse return et.

        const approvedData = bids?.map((bid) => {
            const comm: OfferFormBidCommissionCreateModel = {
                advancePayment: bid.advancePayment,
                predictedTotal: bid.predictedTotal,
                renterPeriodPayment: bid.renterPeriodPayment,
                lessorMachineGoCommission: bid.lessorMachineGoCommission,
                lessorPeriodPayment: bid.lessorPeriodPayment,
                machineGoCommission: bid.machineGoCommission,
                maturityOptionId: bid.maturityOption?.id,
                paymentLink: bid.paymentLink ? bid.paymentLink : "",
            };
            return comm;
        });
        if (approvedData) {
            if (props.onApproveClick) props.onApproveClick(approvedData);
        } else {
            //TODO : Hata mesajı eklenecek
        }
    };

    const bidChange = (param: OfferFormBidViewModel) => {
        setBids((oldValues) => {
            let newVal = oldValues ? [...oldValues] : [];
            const changedItem = oldValues?.find((item) => item.maturityOption?.id === param.maturityOption?.id);
            const changedItemIndex = changedItem ? oldValues?.indexOf(changedItem) : undefined;
            //if'in ilk parametresi !!! şeklinde de kontrol edilebilirdi.
            if (changedItemIndex !== undefined && changedItemIndex !== -1) {
                newVal[changedItemIndex] = {
                    ...changedItem,

                    advancePayment: param.advancePayment,
                    predictedTotal: param.predictedTotal,
                    renterPeriodPayment: param.renterPeriodPayment,
                    lessorMachineGoCommission: param.lessorMachineGoCommission,
                    lessorPeriodPayment: param.lessorPeriodPayment,
                    total: param.total,
                    machineGoCommission: param.machineGoCommission,
                    paymentLink: param.paymentLink,
                };
            }
            return newVal;
        });
    };

    return (
        <ModalLayout {...props}>
            <ModalCardContainer title={t("common.paymentPlan")}>
                <Row>
                    <Col
                        style={{
                            background: "#F4F4F4",
                            padding: "0.5rem",
                            marginBottom: "0.5rem",
                            borderRadius: "0.75rem",
                            fontWeight: "700",
                        }}
                        md={24}
                    >
                        <span style={{ color: "#949394" }}>{t("offers.view.requestDateFromTo")}:</span>
                        {moment(props.req?.requestForm?.requestDateFrom) + " - " + moment(props.req?.requestForm?.requestDateTo)}
                    </Col>
                </Row>

                {bids?.map((bid, index) => (
                    <AccordionPayment panelHeader={bid?.maturityOption?.name} activeKey={bid?.maturityOption?.id}>
                        <PaymentContainer onBidChange={bidChange} bid={bid}></PaymentContainer>
                    </AccordionPayment>
                ))}

                <Col xs={12} offset={6}>
                    <PrimaryButton onClick={onApproveClick} style={{ marginRight: "0.5rem", width: "inherit", marginBottom: "0px" }}>
                        {t("common.accept")}
                    </PrimaryButton>
                </Col>
            </ModalCardContainer>
        </ModalLayout>
    );
};

export default EditPaymentModal;
