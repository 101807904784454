import { Col, Input, InputNumber, Row, Select } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import {  LessorCompanySearchFilterModel, LessorCompanySearchSortField } from "../../service/models";
import { allCategories } from "../../store/all-categories";
import { loadingAtom } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "./box-container";
//import styles from '../styles/filter-components.module.scss';
type LessorListFilterProps = {
    onChange?: (params: LessorListFilterViewModel) => void;
    children?: any;
    isAdmin?: boolean;
};

type LessorListFilterViewModel = {
    searchFilterModel?: LessorCompanySearchFilterModel;
    companyName?: string | null;
    cityName?: string | null;
    categoryName?: string | null;
    sortBy: {
        field: LessorCompanySearchSortField;
        sortAscending: boolean;
    };
};

const RenterListFilter = (props: LessorListFilterProps) => {
    const [loading] = useAtom(loadingAtom);
    const { Option } = Select;
    const [filterModel, setFilterModel] = useState<LessorListFilterViewModel>({
        sortBy: {
            
            field: LessorCompanySearchSortField.MachineCount,
            sortAscending: true,
        },
    });


    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    
    const companyIdChange = (val: string | number) => {
        let compId: number | undefined = undefined;
        if (typeof val === "number") compId = val;
        setFilterModel((filt) => {
            return {
                ...filt,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    id:compId,
                },
            };
        });
    };

    const companyNameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    companyName: val,
                },
            };
        });
    };

    const onMachineCategorySelected = async (selectedCategoryName?: string) => {
        const selectedCategory = allCategories?.find((cat) => cat.name === selectedCategoryName);
        setFilterModel((filt) => {
            return {
                ...filt,
                categoryName: selectedCategory?.name,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    categoryId: selectedCategory?.id,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    {t("common.filterBy")}
                    <Row gutter={[12, 12]}>
                        <Col xs={12} md={3}>
                            <InputNumber
                                style={{ width: "100%" }}
                                placeholder={t("renter.filter.id")}
                                value={filterModel.searchFilterModel?.id || undefined}
                                onChange={(val) => {
                                    companyIdChange(val);
                                }}
                            />
                        </Col>
                        {props.isAdmin && (
                            <Col xs={12} md={3}>
                                <Input
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder={t("renter.filter.companyName")}
                                    value={filterModel.searchFilterModel?.companyName || undefined}
                                    onChange={(val) => {
                                        companyNameChange(val.currentTarget.value);
                                    }}
                                />
                            </Col>
                        )}
                        <Col xs={12} md={3}>
                            <Select
                                allowClear
                                placeholder={t("machines.add.category")}
                                style={{ width: "100%" }}
                                value={filterModel.categoryName}
                                showSearch
                                loading={loading}
                                onSelect={onMachineCategorySelected}
                                onClear={onMachineCategorySelected}
                            >
                                {allCategories?.map((mc) => (
                                    <Option value={mc.name}>{mc.name}</Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default RenterListFilter;
