/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    DocumentModel,
    DocumentModelFromJSON,
    DocumentModelFromJSONTyped,
    DocumentModelToJSON,
} from './DocumentModel';
import {
    OfferFormBidViewModel,
    OfferFormBidViewModelFromJSON,
    OfferFormBidViewModelFromJSONTyped,
    OfferFormBidViewModelToJSON,
} from './OfferFormBidViewModel';
import {
    OfferFormCommentViewModel,
    OfferFormCommentViewModelFromJSON,
    OfferFormCommentViewModelFromJSONTyped,
    OfferFormCommentViewModelToJSON,
} from './OfferFormCommentViewModel';
import {
    RequestFormAdditionalServices,
    RequestFormAdditionalServicesFromJSON,
    RequestFormAdditionalServicesFromJSONTyped,
    RequestFormAdditionalServicesToJSON,
} from './RequestFormAdditionalServices';
import {
    RequestFormOfferViewModel,
    RequestFormOfferViewModelFromJSON,
    RequestFormOfferViewModelFromJSONTyped,
    RequestFormOfferViewModelToJSON,
} from './RequestFormOfferViewModel';

/**
 * 
 * @export
 * @interface OfferFormViewModel
 */
export interface OfferFormViewModel {
    /**
     * 
     * @type {number}
     * @memberof OfferFormViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferFormViewModel
     */
    lessorCompanyName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormViewModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormViewModel
     */
    expireDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormViewModel
     */
    approvementDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OfferFormViewModel
     */
    status?: string | null;
    /**
     * 
     * @type {RequestFormOfferViewModel}
     * @memberof OfferFormViewModel
     */
    requestForm?: RequestFormOfferViewModel;
    /**
     * 
     * @type {Array<OfferFormCommentViewModel>}
     * @memberof OfferFormViewModel
     */
    comments?: Array<OfferFormCommentViewModel> | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof OfferFormViewModel
     */
    machines?: Array<BasicModel> | null;
    /**
     * 
     * @type {Array<OfferFormBidViewModel>}
     * @memberof OfferFormViewModel
     */
    bids?: Array<OfferFormBidViewModel> | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof OfferFormViewModel
     */
    paymentTypes?: Array<BasicModel> | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof OfferFormViewModel
     */
    billingTimeOptions?: Array<BasicModel> | null;
    /**
     * 
     * @type {Array<DocumentModel>}
     * @memberof OfferFormViewModel
     */
    documents?: Array<DocumentModel> | null;
    /**
     * 
     * @type {string}
     * @memberof OfferFormViewModel
     */
    notes?: string | null;
    /**
     * 
     * @type {RequestFormAdditionalServices}
     * @memberof OfferFormViewModel
     */
    additionalServices?: RequestFormAdditionalServices;
}

export function OfferFormViewModelFromJSON(json: any): OfferFormViewModel {
    return OfferFormViewModelFromJSONTyped(json, false);
}

export function OfferFormViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lessorCompanyName': !exists(json, 'lessorCompanyName') ? undefined : json['lessorCompanyName'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'expireDate': !exists(json, 'expireDate') ? undefined : (new Date(json['expireDate'])),
        'approvementDate': !exists(json, 'approvementDate') ? undefined : (json['approvementDate'] === null ? null : new Date(json['approvementDate'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'requestForm': !exists(json, 'requestForm') ? undefined : RequestFormOfferViewModelFromJSON(json['requestForm']),
        'comments': !exists(json, 'comments') ? undefined : (json['comments'] === null ? null : (json['comments'] as Array<any>).map(OfferFormCommentViewModelFromJSON)),
        'machines': !exists(json, 'machines') ? undefined : (json['machines'] === null ? null : (json['machines'] as Array<any>).map(BasicModelFromJSON)),
        'bids': !exists(json, 'bids') ? undefined : (json['bids'] === null ? null : (json['bids'] as Array<any>).map(OfferFormBidViewModelFromJSON)),
        'paymentTypes': !exists(json, 'paymentTypes') ? undefined : (json['paymentTypes'] === null ? null : (json['paymentTypes'] as Array<any>).map(BasicModelFromJSON)),
        'billingTimeOptions': !exists(json, 'billingTimeOptions') ? undefined : (json['billingTimeOptions'] === null ? null : (json['billingTimeOptions'] as Array<any>).map(BasicModelFromJSON)),
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] === null ? null : (json['documents'] as Array<any>).map(DocumentModelFromJSON)),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'additionalServices': !exists(json, 'additionalServices') ? undefined : RequestFormAdditionalServicesFromJSON(json['additionalServices']),
    };
}

export function OfferFormViewModelToJSON(value?: OfferFormViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lessorCompanyName': value.lessorCompanyName,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'expireDate': value.expireDate === undefined ? undefined : (value.expireDate.toISOString()),
        'approvementDate': value.approvementDate === undefined ? undefined : (value.approvementDate === null ? null : value.approvementDate.toISOString()),
        'status': value.status,
        'requestForm': RequestFormOfferViewModelToJSON(value.requestForm),
        'comments': value.comments === undefined ? undefined : (value.comments === null ? null : (value.comments as Array<any>).map(OfferFormCommentViewModelToJSON)),
        'machines': value.machines === undefined ? undefined : (value.machines === null ? null : (value.machines as Array<any>).map(BasicModelToJSON)),
        'bids': value.bids === undefined ? undefined : (value.bids === null ? null : (value.bids as Array<any>).map(OfferFormBidViewModelToJSON)),
        'paymentTypes': value.paymentTypes === undefined ? undefined : (value.paymentTypes === null ? null : (value.paymentTypes as Array<any>).map(BasicModelToJSON)),
        'billingTimeOptions': value.billingTimeOptions === undefined ? undefined : (value.billingTimeOptions === null ? null : (value.billingTimeOptions as Array<any>).map(BasicModelToJSON)),
        'documents': value.documents === undefined ? undefined : (value.documents === null ? null : (value.documents as Array<any>).map(DocumentModelToJSON)),
        'notes': value.notes,
        'additionalServices': RequestFormAdditionalServicesToJSON(value.additionalServices),
    };
}

