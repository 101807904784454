import { Col, Input } from "antd";
import { useAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/containers/modal-layout";
import ModalCardContainer from "../components/containers/modalcard-container";
import { postloginMessageAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";

type RequestModalProps = {
    requestType: "approve" | "reject";
    requestId: number;
} & ModalLayoutProps;
const RequestModal = (props: RequestModalProps) => {
    const navigate = useNavigate();
    const { doRejectRequest, doApproveRequest } = useSecureService();
    const [note, setNote] = useState("");
    const [, setMessage] = useAtom(postloginMessageAtom);
    const onContinueClick = async () => {
        let result = false;
        if (props.requestType === "approve") {
            result = await doApproveRequest(props.requestId, note);
            if (result) {
                setMessage({
                    message: t("requests.view.approveSuccess"),
                    type: "success",
                });
            }
        } else if (props.requestType === "reject") {
            result = await doRejectRequest(props.requestId, note);
            if (result) {
                setMessage({
                    message: t("requests.view.rejectSuccess"),
                    type: "success",
                });
            }
        } else {
            setMessage({
                message: t("common.genericError"),
                type: "error",
            });
        }
        if (result) navigate("/requests/");
    };

    const { TextArea } = Input;
    const onTextAreaChanged = (e: any) => {
        setNote(e.currentTarget.value);
    };
    return (
        <ModalLayout {...props}>
            <ModalCardContainer title={props.requestType === "reject" ? t("common.rejectionreason") : t("common.approvereason")}>
                <Col>
                    <TextArea
                        onChange={onTextAreaChanged}
                        rows={4}
                        placeholder={props.requestType === "reject" ? t("common.rejectionreason") : t("common.approvereason")}
                    />
                </Col>
                <Col span={18} offset={3}>
                    <PrimaryButton onClick={onContinueClick}>{t("common.continue")}</PrimaryButton>
                </Col>
            </ModalCardContainer>
        </ModalLayout>
    );
};
export default RequestModal;
