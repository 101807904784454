import { useAtom } from "jotai";
import { t } from "../translate";
import { useGoogleReCaptcha } from "react-google-recaptcha-hook";
import {
    AttachmentApi,
    BrandApi,
    CameraResolutionApi,
    CategoryApi,
    CityApi,
    CityModel,
    ContactFormApi,
    CurrencyApi,
    DocumentApi,
    ElevatorTypeApi,
    ErrorLogApi,
    FuelTypeApi,
    LessorCompanyApi,
    MachineApi,
    MaturityOptionApi,
    Middleware,
    NotificationApi,
    OfferFormApi,
    RentalTypeApi,
    RenterCompanyApi,
    RequestFormApi,
    ResponseContext,
} from "../service";
import {
    ActivationModel,
    BasicModel,
    CategoryModel,
    LessorCompanyCreateModel,
    LoginRequestModel,
    MachineCreateModel,
    MachineDetailModel,
    RenterCompanyCreateModel,
} from "../service";
import { loadingAtom, postloginMessageAtom, preloginMessageAtom, userAtom } from "./global-atoms";
import { dummyAttachments } from "./all-categories";
import {
    AdminLoginWithOtpModel,
    LessorCompanySearchModel,
    MachineEditModel,
    MachineSearchModel,
    OfferFormBidCommissionCreateModel,
    OfferFormSearchModel,
    RenterCompanySearchModel,
    RequestFormEditModel,
    RequestFormSearchModel,
    SearchModel,
} from "../service/models";
import { AdminForgotPasswordViewModel } from "../service/models/AdminForgotPasswordViewModel";
import { AdminResetPasswordViewModel } from "../service/models/AdminResetPasswordViewModel";
import { ExtendedMachineDetailModel, SearchMachineModel } from "../service/models/ExtendedMachineDetailModel";
import { AdminApi } from "../service";
import {} from "../service/models";
import { activeRecaptcha } from "./app-constants";

const categoryApi = new CategoryApi();
const fuelTypesApi = new FuelTypeApi();
const elevatorTypesApi = new ElevatorTypeApi();
const cameraResolutionApi = new CameraResolutionApi();
const baseCitiesApi = new CityApi();
const brandApi = new BrandApi();
const attachmentApi = new AttachmentApi();
const rentalTypesApi = new RentalTypeApi();
const currenciesApi = new CurrencyApi();
const baseMachineApi = new MachineApi();
const adminApi = new AdminApi();
const baseRenterCompanyApi = new RenterCompanyApi();
const baseLessorCompanyapi = new LessorCompanyApi();
const baseRequestFormApi = new RequestFormApi();
const baseOfferFormApi = new OfferFormApi();
const baseMaturityOptionsApi = new MaturityOptionApi();
const contactFormApi = new ContactFormApi();
const notificationApi = new NotificationApi();
const documentApi = new DocumentApi();
const errorLogApi = new ErrorLogApi();

const postConfig = (prelogin?: boolean, additionalHeaders?: Record<string, string>) => {
    const headers = {
        "Content-Type": "application/json",
        ...additionalHeaders,
    };

    const initObj = getModeAndCredentialsHeader(prelogin);
    initObj.headers = headers;
    return initObj;
};

const getConfig = (prelogin?: boolean, additionalHeaders?: Record<string, string>) => {
    const initObj = getModeAndCredentialsHeader(prelogin);
    console.log(additionalHeaders);
    initObj.headers = { ...additionalHeaders };
    return initObj;
};

const getModeAndCredentialsHeader = (prelogin?: boolean) => {
    const initObj: RequestInit = {
        mode: "cors",
    };
    if (!prelogin) initObj.credentials = "include";
    return initObj;
};

export type PaginationType = {
    current: undefined | number;
    pageSize: undefined | number;
};

let allElevatorTypes: BasicModel[] | undefined = undefined;
let allFuelTypes: BasicModel[] | undefined = undefined;
let allCameraResolutionTypes: BasicModel[] | undefined = undefined;
let allCategoryModels: CategoryModel[] | undefined = undefined;
let allCities: CityModel[] | undefined = undefined;
let allExactCities: CityModel[] | undefined = undefined;
let allRentalTypes: BasicModel[] | undefined = undefined;
let allCurrencies: BasicModel[] | undefined = undefined;
let allAttachments: BasicModel[] | undefined = undefined;
let allBrands: BasicModel[] | undefined = undefined;
let allMaturityOptions: BasicModel[] | undefined = undefined;

const findCategory = (subCatId: number | undefined) => {
    if (!allCategoryModels || !subCatId) return undefined;
    let targetCategory: CategoryModel | undefined = undefined;
    for (let i = 0; i < allCategoryModels.length; i++) {
        let sub = allCategoryModels[i].subCategories?.find((sub) => {
            return sub.id === subCatId;
        });
        if (sub) {
            targetCategory = allCategoryModels[i];
            break;
        }
    }
    return targetCategory;
};

type ActionType =
    | "guest"
    | "contactform"
    | "rentersignup"
    | "rentersignin"
    | "renterforgotpassword"
    | "renteractivate"
    | "renterresendactivate"
    | "renterresetpassword"
    | "renterrequestform"
    | "lessorsignup"
    | "lessorsignin"
    | "lessorforgotpassword"
    | "lessoractivate"
    | "lessorresendactivate"
    | "lessorresetpassword"
    | "adminsignin"
    | "adminforgotpassword"
    | "adminresetpassword";

export const useSecureService = () => {
    const [, setLoading] = useAtom(loadingAtom);
    const [, setUser] = useAtom(userAtom);
    const [, setAuthMessage] = useAtom(preloginMessageAtom);
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const { executeGoogleReCaptcha } = useGoogleReCaptcha(activeRecaptcha);

    const generateRecaptcha = async (actionType: ActionType) => {
        const token = await executeGoogleReCaptcha(actionType);
        const record = { "x-recaptcha-token": token };
        return record;
    };

    const handleSecureCatchErrors = async (err: any) => {
        if (err.response) {
            err = err.response;
        } else {
            setErrorMessage({ message: t("common.genericError"), type: "error" });
            setLoading(false);
            return;
        }
        if (err?.status === 401) {
            setUser(undefined);
            setAuthMessage({ message: t("common.401"), type: "error" });
        } else if (err?.status === 500) {
            setErrorMessage({ message: t("common.genericError"), type: "error" });
        } else {
            const msg = await err.json();
            if (typeof msg === "string") {
                setErrorMessage({ message: msg.toString(), type: "error" });
            } else if (msg.title !== undefined) {
                setErrorMessage({ message: msg.title, type: "error" });
            } else {
                setErrorMessage({ message: t("common.genericError"), type: "error" });
            }
        }
        setLoading(false);
    };
    const baseMiddleware: Middleware = {
        pre: async (params) => {
            console.log(":::REQUEST SENT:::");
            console.log(params);
            setLoading(true);
        },
        post: async (params: ResponseContext) => {
            console.log(params);
            console.log(":::RESPONSE RECEIVED:::");
            if (!params.response.ok) handleSecureCatchErrors(params.response);
            setLoading(false);
        },
    };

    const lessorCompanyApi = baseLessorCompanyapi.withMiddleware(baseMiddleware);
    const renterCompanyApi = baseRenterCompanyApi.withMiddleware(baseMiddleware);
    const machineApi = baseMachineApi.withMiddleware(baseMiddleware);
    const requestFormApi = baseRequestFormApi.withMiddleware(baseMiddleware);
    const offerFormApi = baseOfferFormApi.withMiddleware(baseMiddleware);
    const maturityOptionApi = baseMaturityOptionsApi.withMiddleware(baseMiddleware);
    const citiesApi = baseCitiesApi.withMiddleware(baseMiddleware);

   const doSendOtp = async (model: LoginRequestModel, additionalHeaders?: Record<string, string>) => {
        setLoading(true);
        try {
            const result = await adminApi.adminSendOtpPost(
                { loginRequestModel: model },
                postConfig(false, { ...additionalHeaders })
            );
            setLoading(false);
            return result.data;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return false;
        }
    };

    const doLogin = async (model: AdminLoginWithOtpModel, additionalHeaders?: Record<string, string>) => {
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("adminsignin");
            if (!recaptcha) return undefined;

            const user = await adminApi.adminSignInPost(
                { adminLoginWithOtpModel: model },
                postConfig(false, { ...additionalHeaders, ...recaptcha })
            );
            setLoading(false);
            setUser(user);
            return true;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return false;
        }
    };

    const doLessorSignup = async (model: LessorCompanyCreateModel, additionalHeaders: Record<string, string>) => {
        setLoading(true);
        try {
            const result = await lessorCompanyApi.lessorCompanySignUpPost(
                { lessorCompanyCreateModel: model },
                postConfig(false, additionalHeaders)
            );
            setLoading(false);
            return result.data;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doRenterSignup = async (model: RenterCompanyCreateModel, additionalHeaders: Record<string, string>) => {
        setLoading(true);
        try {
            const result = await renterCompanyApi.renterCompanySignUpPost(
                { renterCompanyCreateModel: model },
                postConfig(false, additionalHeaders)
            );
            setLoading(false);
            return result.data;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doResendActivationCode = async (role: string, userId: number) => {
        setLoading(true);

        try {
            if (role === "lessor") await lessorCompanyApi.lessorCompanyResendActivationCodeUserIdGet({ userId }, getConfig(true));
            else await renterCompanyApi.renterCompanyResendActivationCodeUserIdGet({ userId }, getConfig(true));
            setLoading(false);
        } catch (err: any) {
            await handleSecureCatchErrors(err);
        }
    };

    const doActivate = async (role: string, activationModel: ActivationModel) => {
        setLoading(true);
        try {
            if (role === "lessor") await lessorCompanyApi.lessorCompanyActivatePost({ activationModel }, postConfig(true));
            else await renterCompanyApi.renterCompanyActivatePost({ activationModel }, postConfig(true));
            setLoading(false);
            return true;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return false;
        }
    };

    const doForgotPassword = async (model: AdminForgotPasswordViewModel, additionalHeaders?: Record<string, string>) => {
        setLoading(true);
        const recaptcha = await generateRecaptcha("adminforgotpassword");
        if (!recaptcha) return undefined;
        const configHeaders = postConfig(true, { ...additionalHeaders, ...recaptcha });
        try {
            await adminApi.adminForgotPasswordPost({ adminForgotPasswordViewModel: model }, configHeaders);
            additionalHeaders
                ? setAuthMessage({ message: t("forgot-password.success"), type: "success" })
                : setErrorMessage({ message: "Şifre başarıyla gönderildi", type: "success" });
            setLoading(false);
        } catch (err: any) {
            await handleSecureCatchErrors(err);
        }
    };

    const doPostPasswordReset = async (model: AdminResetPasswordViewModel) => {
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("adminresetpassword");
            if (!recaptcha) return undefined;

            await adminApi.adminResetPasswordPost({ adminResetPasswordViewModel: model }, postConfig(true, recaptcha));
            setAuthMessage({ message: t("activate.success"), type: "success" });
            setLoading(false);
            return true;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return false;
        }
    };
    const doLogout = async () => {
        setLoading(true);
        try {
            await adminApi.adminSignOutGet(postConfig());
            setUser(undefined);
        } catch (err: any) {
            await handleSecureCatchErrors(err);
        } finally {
            setLoading(false);
        }
    };

    const doGetMachineSubcategory = async () => {
        if (allCategoryModels) return allCategoryModels;
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allCategoryModels = await categoryApi.categoryWithChildrenGet(getConfig(false, recaptcha));
            setLoading(false);
            return allCategoryModels;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetFuelTypes = async () => {
        if (allFuelTypes) return allFuelTypes;
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allFuelTypes = await fuelTypesApi.fuelTypeGet(getConfig(undefined, recaptcha));
            setLoading(false);
            return allFuelTypes;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetCameraResolutionTypes = async () => {
        if (allCameraResolutionTypes) return allCameraResolutionTypes;
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;

            allCameraResolutionTypes = await cameraResolutionApi.cameraResolutionGet(getConfig(undefined, recaptcha));
            setLoading(false);
            return allCameraResolutionTypes;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetElevatorTypes = async () => {
        if (allElevatorTypes) return allElevatorTypes;
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;

            allElevatorTypes = await elevatorTypesApi.elevatorTypeGet(getConfig(undefined, recaptcha));
            setLoading(false);
            return allElevatorTypes;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetRentalTypes = async () => {
        if (allRentalTypes) return allRentalTypes;
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allRentalTypes = await rentalTypesApi.rentalTypeGet(getConfig(undefined, recaptcha));
            setLoading(false);
            return allRentalTypes;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetCurrencies = async () => {
        if (allCurrencies) return allCurrencies;
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allCurrencies = await currenciesApi.currencyGet(getConfig(undefined, recaptcha));
            setLoading(false);
            return allCurrencies;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetCities = async () => {
        if (allCities) return allCities;
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allCities = await citiesApi.cityGet(getConfig(undefined, recaptcha));
            setLoading(false);
            //const brs:BasicModel = {id:0,name:"Nilufer"};
            /*const obj:[{id?:number|null,name?:string|null,districts?:BasicModel[]|null}]|undefined = [{
                id:0,
                name:"Bursa",
                districts:[brs]
            }];*/
            return allCities;
        } catch (err: any) {
            //await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetExactCities = async () => {
        if (allExactCities) return allExactCities;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;
            allExactCities = await citiesApi.cityExactGet(getConfig(undefined, recaptcha));
            /*const brs:BasicModel = {id:192,name:"Osmangazi"};
            allExactCities = [{
                id:16,
                name:"Bursa",
                districts:[brs]
            }];*/
            return allExactCities;
        } catch {
            return undefined;
        }
    };

    const doGetAttachments = async (id: number) => {
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;

            const result = await attachmentApi.attachmentSubCategoryIdGet({ subCategoryId: id }, getConfig(undefined, recaptcha));
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return dummyAttachments;
        }
    };

    const doGetBrandsByCategory = async (catId: number) => {
        setLoading(true);
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;

            const brands = await brandApi.brandCategoryIdGet({ categoryId: catId }, getConfig(undefined, recaptcha));
            allBrands = brands;
            setLoading(false);
            return brands;
        } catch (err: any) {
            console.log("err: ", err);
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetLessorCompanyShortList = async () => {
        setLoading(true);
        try {
            const result = await lessorCompanyApi.lessorCompanyShortListGet(getConfig());
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doPostCreateMachine = async (model: ExtendedMachineDetailModel, createCount: number) => {
        setLoading(true);

        let createModel: MachineCreateModel = {};
        let attachments: number[] = [];
        model.attachmentsList?.forEach((item) => {
            const atc: BasicModel | undefined = allAttachments?.find((at) => at.name === item);
            if (atc && atc.id) attachments.push(atc.id);
        });
        createModel.attachments = attachments;
        createModel.attachmentOther = model.otherAttachmentsName;
        createModel.bladeWidth = model.bladeWidth;
        createModel.bomLength = model.bomLength;
        createModel.brandId = allBrands?.find((brn) => brn.name === model.brandName)?.id;
        createModel.brandOther = model.otherBrandName;
        createModel.breakingInfo = model.breakingInfo;
        createModel.cameraResolutionId = allCameraResolutionTypes?.find((crt) => crt.name === model.cameraResolutionName)?.id;
        createModel.carryingCapacity = model.carryingCapacity;
        createModel.cleaningCapacity = model.cleaningCapacity;
        createModel.compressionWidth = model.compressionWidth;
        createModel.concretePumpingCapacity = model.concretePumpingCapacity;
        createModel.drivingEquipment = model.drivingEquipment;
        createModel.elevatorTypeId = allElevatorTypes?.find((elt) => elt.name === model.elevatorTypeName)?.id;
        createModel.engineBrand = model.engineBrand;
        createModel.enginePower = model.enginePower;
        createModel.flightDistance = model.flightDistance;
        createModel.flightTime = model.flightTime;
        createModel.floorCount = model.floorCount;
        createModel.freeAirOutput = model.freeAirOutput;
        createModel.frequency = model.frequency;
        createModel.fuelIncluded = model.fuelIncluded;
        createModel.fuelTypeId = allFuelTypes?.find((fty) => fty.name === model.fuelTypeName)?.id;
        createModel.garbageCollectionBin = model.garbageCollectionBin;
        createModel.hasCabin = model.hasCabin;
        createModel.hasOperator = model.hasOperator;
        createModel.hasPlatform = model.hasPlatform;
        createModel.hasTowerElevationSystem = model.hasTowerElevationSystem;
        createModel.height = model.height;
        createModel.lessorCompanyId = model.lessorCompanyId;
        createModel.lightningCapacity = model.lightningCapacity;
        createModel.loadCapacity = model.loadCapacity;
        createModel.maxDepth = model.maxDepth;
        createModel.maxFeedingSize = model.maxFeedingSize;
        createModel.maxHeight = model.maxHeight;
        createModel.maxLoadCapacity = model.maxLoadCapacity;
        createModel.maxPower = model.maxPower;
        createModel.maxPressure = model.maxPressure;
        createModel.maxWidth = model.maxWidth;
        createModel.minPower = model.minPower;
        createModel.modelName = model.modelName;
        createModel.nominalCapacity = model.nominalCapacity;
        createModel.notes = model.notes;
        createModel.operatingArea = model.operatingArea;
        createModel.operatingCapacity = model.operatingCapacity;
        createModel.operatingHeight = model.operatingHeight;
        createModel.operatingLength = model.operatingLength;
        createModel.operatingWeight = model.operatingWeight;
        createModel.operationHour = model.operationHour;
        createModel.operationMileage = model.operationMileage;
        createModel.operationWidth = model.operationWidth;
        createModel.operatorCostIncluded = model.operatorCostIncluded;
        createModel.operatorExperienceYear = model.operatorExperienceYear;
        createModel.operatorInsuranceIncluded = model.operatorInsuranceIncluded;
        createModel.platformLength = model.platformLength;
        createModel.platformWidth = model.platformWidth;
        createModel.productionYear = model.productionYear;
        createModel.rentalTypes = model.rentalTypes?.map((item) => {
            return {
                currencyId: item.currency?.id ? item.currency.id : 1,
                price: item.price ? item.price : 0,
                rentalTypeId: item.rentalType?.id ? item.rentalType.id : 0,
            };
        });
        let regions: number[] = [];
        model.serviceRegionsList?.forEach((regs) => {
            const sr: BasicModel | undefined = allCities?.find((cty) => cty.name === regs);
            if (sr && sr.id) regions.push(sr.id);
        });
        createModel.serviceRegions = regions;
        createModel.shipingIncluded = model.shipingIncluded;
        createModel.spraySpeed = model.spraySpeed;
        createModel.subCategoryId = model.subCategoryId;
        await doGetMachineSubcategory();
        let id: number | undefined = 0;
        allCategoryModels?.forEach((cat) => {
            const sub = cat.subCategories?.find((sub) => sub.name === model.subcategoryName);
            if (sub) id = sub.id;
        });
        createModel.subCategoryId = id;
        createModel.tankCapacity = model.tankCapacity;
        createModel.tankVolume = model.tankVolume;
        createModel.usageFields = model.usageFields;
        createModel.volume = model.volume;
        createModel.waterTankCapacity = model.waterTankCapacity;
        createModel.width = model.width;
        createModel.workingRadius = model.workingRadius;
        createModel.workingSpeed = model.workingSpeed;
        createModel.documents = model.documents?.map((doc) => {
            return {
                documentCategory: doc.documentCategory,
                documentId: doc.document?.id,
            };
        });

        try {
            let result: MachineDetailModel | undefined = undefined;
            for (let i = 0; i < createCount; i++) {
                result = await machineApi.machineCreatePost({ machineCreateModel: createModel }, postConfig());
            }
            if (result) return true;
        } catch (err: any) {}
        return false;
    };
    const doSearchMachine = async (model: MachineSearchModel) => {
        try {
            await doGetMachineSubcategory();
        } catch {}
        try {
            let result = (await machineApi.machineSearchPost({ machineSearchModel: model }, postConfig())) as SearchMachineModel;
            if (result) result.machines = [];
            result.data?.forEach((mach) => {
                let extended: ExtendedMachineDetailModel = { ...mach };
                extended.category = findCategory(mach.subCategoryId);
                //TODO: subCategory boş geliyordu. Düzelirse bu satırı sil
                extended.subCategory = extended.category?.subCategories?.find((sub) => sub.id === extended.subCategoryId);
                result.machines?.push(extended);
            });
            return result;
        } catch {}
        return undefined;
    };
    const doGetRequests = async (model: RequestFormSearchModel) => {
        setLoading(true);
        try {
            const result = await requestFormApi.requestFormSearchPost({ requestFormSearchModel: model }, postConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };
    const doGetRequestDetail = async (reqId: number) => {
        try {
            //await doGetMachineSubcategory();
        } catch {}
        try {
            let result = await requestFormApi.requestFormIdGet({ id: reqId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doGetOfferedCompanies = async (reqId: number) => {
        try {
            //await doGetMachineSubcategory();
        } catch {}
        try {
            let result = await requestFormApi.requestFormIdGet({ id: reqId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doUpdateRequest = async (model: RequestFormEditModel) => {
        try {
            let result = await requestFormApi.requestFormUpdatePost({ requestFormEditModel: model }, postConfig());
            if (result) {
                setErrorMessage({
                    type: "success",
                    message: t("common.success"),
                });
            }
            return result;
        } catch {}
        return undefined;
    };

    const doGetMachine = async (machineId: number) => {
        try {
            const mach = await machineApi.machineIdGet({ id: machineId }, getConfig());
            let extended: ExtendedMachineDetailModel = { ...mach };
            extended.category = findCategory(mach.subCategoryId);
            extended.categoryName = extended.category?.name || undefined;
            extended.subcategoryName = mach.subCategory?.name || undefined;
            extended.brandName = mach.brand?.name || undefined;
            extended.cameraResolutionName = mach.cameraResolution?.name || undefined;
            extended.fuelTypeName = mach.fuelType?.name || undefined;
            extended.elevatorTypeName = mach.elevatorType?.name || undefined;
            extended.serviceRegionsList = mach.serviceRegions?.map((reg) => reg.name || "").filter((item) => item !== "");
            extended.attachmentsList = mach.attachments?.map((atc) => atc.name || "").filter((item) => item !== "");
            //extended.subCategory = extended.category?.subCategories?.find((sub)=>sub.id===extended.subCategoryId);
            return extended;
        } catch {}
        return undefined;
    };
    const doGetSuitableCompanies = async (reqId: number) => {
        try {
            let result = await lessorCompanyApi.lessorCompanyGetSuitableCompaniesForRequestRequestIdGet({ requestId: reqId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doUpdateMachine = async (model: ExtendedMachineDetailModel) => {
        let createModel: MachineEditModel = {};
        let attachments: number[] = [];
        model.attachmentsList?.forEach((item) => {
            const atc: BasicModel | undefined = allAttachments?.find((at) => at.name === item);
            if (atc && atc.id) attachments.push(atc.id);
        });

        createModel.attachments = attachments;
        createModel.attachmentOther = model.otherAttachmentsName;
        createModel.bladeWidth = model.bladeWidth;
        createModel.bomLength = model.bomLength;
        createModel.brandId = model.brandId;
        createModel.brandOther = model.otherBrandName;
        createModel.breakingInfo = model.breakingInfo;
        createModel.cameraResolutionId = allCameraResolutionTypes?.find((crt) => crt.name === model.cameraResolutionName)?.id;
        createModel.carryingCapacity = model.carryingCapacity;
        createModel.cleaningCapacity = model.cleaningCapacity;
        createModel.compressionWidth = model.compressionWidth;
        createModel.concretePumpingCapacity = model.concretePumpingCapacity;
        createModel.drivingEquipment = model.drivingEquipment;
        createModel.elevatorTypeId = allElevatorTypes?.find((elt) => elt.name === model.elevatorTypeName)?.id;
        createModel.engineBrand = model.engineBrand;
        createModel.enginePower = model.enginePower;
        createModel.flightDistance = model.flightDistance;
        createModel.flightTime = model.flightTime;
        createModel.floorCount = model.floorCount;
        createModel.freeAirOutput = model.freeAirOutput;
        createModel.frequency = model.frequency;
        createModel.fuelIncluded = model.fuelIncluded;
        createModel.fuelTypeId = allFuelTypes?.find((fty) => fty.name === model.fuelTypeName)?.id;
        createModel.garbageCollectionBin = model.garbageCollectionBin;
        createModel.hasCabin = model.hasCabin;
        createModel.hasOperator = model.hasOperator;
        createModel.hasPlatform = model.hasPlatform;
        createModel.hasTowerElevationSystem = model.hasTowerElevationSystem;
        createModel.height = model.height;
        createModel.id = model.id;
        createModel.lessorCompanyId = model.lessorCompanyId;
        createModel.lightningCapacity = model.lightningCapacity;
        createModel.loadCapacity = model.loadCapacity;
        createModel.maxDepth = model.maxDepth;
        createModel.maxFeedingSize = model.maxFeedingSize;
        createModel.maxHeight = model.maxHeight;
        createModel.maxLoadCapacity = model.maxLoadCapacity;
        createModel.maxPower = model.maxPower;
        createModel.maxPressure = model.maxPressure;
        createModel.maxWidth = model.maxWidth;
        createModel.minPower = model.minPower;
        createModel.modelName = model.modelName;
        createModel.nominalCapacity = model.nominalCapacity;
        createModel.notes = model.notes;
        createModel.operatingArea = model.operatingArea;
        createModel.operatingCapacity = model.operatingCapacity;
        createModel.operatingHeight = model.operatingHeight;
        createModel.operatingLength = model.operatingLength;
        createModel.operatingWeight = model.operatingWeight;
        createModel.operationHour = model.operationHour;
        createModel.operationMileage = model.operationMileage;
        createModel.operationWidth = model.operationWidth;
        createModel.operatorCostIncluded = model.operatorCostIncluded;
        createModel.operatorExperienceYear = model.operatorExperienceYear;
        createModel.operatorInsuranceIncluded = model.operatorInsuranceIncluded;
        createModel.platformLength = model.platformLength;
        createModel.platformWidth = model.platformWidth;
        createModel.productionYear = model.productionYear;
        createModel.rentalTypes = model.rentalTypes?.map((item) => {
            return {
                currencyId: item.currency?.id ? item.currency.id : 1,
                price: item.price ? item.price : 0,
                rentalTypeId: item.rentalType?.id ? item.rentalType.id : 0,
            };
        });
        let regions: number[] = [];
        model.serviceRegionsList?.forEach((regs) => {
            const sr: BasicModel | undefined = allCities?.find((cty) => cty.name?.toLowerCase() === regs.toLowerCase());
            if (sr && sr.id) regions.push(sr.id);
        });
        createModel.serviceRegions = regions;
        createModel.shipingIncluded = model.shipingIncluded;
        createModel.spraySpeed = model.spraySpeed;
        createModel.subCategoryId = model.subCategoryId;
        createModel.tankCapacity = model.tankCapacity;
        createModel.tankVolume = model.tankVolume;
        createModel.usageFields = model.usageFields;
        createModel.volume = model.volume;
        createModel.waterTankCapacity = model.waterTankCapacity;
        createModel.width = model.width;
        createModel.workingRadius = model.workingRadius;
        createModel.workingSpeed = model.workingSpeed;
        createModel.documents = model.documents?.map((doc) => {
            return {
                documentCategory: doc.documentCategory,
                documentId: doc.document?.id,
            };
        });

        try {
            await machineApi.machineUpdatePost({ machineEditModel: createModel }, postConfig());
            setErrorMessage({
                type: "success",
                message: t("common.success"),
            });
            return true;
        } catch {}
        return false;
    };
    const doSwitch = async (role: string) => {
        try {
            const result =
                role !== "lessor"
                    ? await renterCompanyApi.renterCompanySwitchToLessorDashboardGet(getConfig())
                    : await lessorCompanyApi.lessorCompanySwitchToRenterDashboardGet(getConfig());
            setUser(result);
            return true;
        } catch (err: any) {
            return false;
        }
    };
    const doRejectRequest = async (id: number, note: string | undefined) => {
        try {
            await requestFormApi.requestFormRejectRequestFormIdPost(
                { requestFormId: id, requestFormRejectModel: { note: note } },
                postConfig()
            );
            return true;
        } catch {
            return false;
        }
    };
    const doApproveRequest = async (id: number, note: string | undefined) => {
        try {
            await requestFormApi.requestFormApproveRequestFormIdPost(
                { requestFormId: id, requestFormRejectModel: { note: note } },
                postConfig()
            );
            return true;
        } catch {
            return false;
        }
    };
    const doApproveOffer = async (id: number, comment: string) => {
        try {
            await offerFormApi.offerFormApprovePost({ offerFormCommentModel: { offerFormId: id, comment: comment } }, postConfig());
            return true;
        } catch {
            return false;
        }
    };
    const doRejectOffer = async (id: number, comment: string) => {
        try {
            await offerFormApi.offerFormRejectPost({ offerFormCommentModel: { offerFormId: id, comment: comment } }, postConfig());
            return true;
        } catch {
            return false;
        }
    };

    const doSuggestCompanies = async (reqId: number, compIds: number[]) => {
        try {
            await requestFormApi.requestFormSuggestRequestFormIdPost({ requestFormId: reqId, requestBody: compIds }, postConfig());
            return true;
        } catch {
            return false;
        }
    };
    const doSearchOffers = async (model: OfferFormSearchModel) => {
        try {
            await doGetMachineSubcategory();
        } catch {}
        try {
            let result = await offerFormApi.offerFormSearchPost({ offerFormSearchModel: model }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doSearchRenterCompanies = async (model: RenterCompanySearchModel) => {
        try {
            let result = await renterCompanyApi.renterCompanySearchPost({ renterCompanySearchModel: model }, postConfig());
            return result;
        } catch {}
        return undefined;
    };

    const doGetRenterCompanyList = async () => {
        setLoading(true);
        try {
            const result = await renterCompanyApi.renterCompanyCompanyListGet(getConfig());
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };
    const doSearchLessorCompanies = async (model: LessorCompanySearchModel) => {
        try {
            let result = await lessorCompanyApi.lessorCompanySearchPost({ lessorCompanySearchModel: model }, postConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doGetLessorCompanyDetail = async (lessorId: number) => {
        try {
            let result = await lessorCompanyApi.lessorCompanyIdGet({ id: lessorId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doGetRenterCompanyDetail = async (renterId: number) => {
        try {
            let result = await renterCompanyApi.renterCompanyIdGet({ id: renterId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doGetLessorCompanyUsers = async (id: number) => {
        try {
            let result = await lessorCompanyApi.lessorCompanyListUsersCompanyIdGet({ companyId: id }, getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doGetOfferDetail = async (offerId: number) => {
        try {
            let result = await offerFormApi.offerFormIdGet({ id: offerId }, getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doGetMaturityOptions = async () => {
        if (allMaturityOptions) return allMaturityOptions;
        try {
            const recaptcha = await generateRecaptcha("guest");
            if (!recaptcha) return undefined;

            allMaturityOptions = await maturityOptionApi.maturityOptionGet(getConfig(undefined, recaptcha));
            return allMaturityOptions;
        } catch {}
    };

    const doSetCommission = async (id: number, model: OfferFormBidCommissionCreateModel[]) => {
        try {
            await offerFormApi.offerFormSetCommissionsOfferIdPost({ offerId: id, offerFormBidCommissionCreateModel: model }, postConfig());
            return true;
        } catch {
            return false;
        }
    };

    const doDealOffer = async (id: number) => {
        try {
            await offerFormApi.offerFormDealOfferIdGet({ offerId: id }, getConfig());
            return true;
        } catch {
            return false;
        }
    };
    const doRejectPayment = async (id: number) => {
        try {
            await offerFormApi.offerFormPaymentDeclineOfferIdGet({ offerId: id }, getConfig());
            return true;
        } catch {
            return false;
        }
    };
    const doGetLessorBlocked = async (id: number) => {
        try {
            let result = await lessorCompanyApi.lessorCompanyGetBlockStatusUserIdGet({ userId: id }, getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doGetRenterBlocked = async (id: number) => {
        try {
            let result = await renterCompanyApi.renterCompanyGetBlockStatusUserIdGet({ userId: id }, getConfig());
            return result;
        } catch {}
        return undefined;
    };
    const doBlockLessorCompany = async (id: number) => {
        try {
            await lessorCompanyApi.lessorCompanyChangeBlockStatusUserIdGet({ userId: id }, getConfig());
            return true;
        } catch {}
        return false;
    };
    const doBlockRenterCompany = async (id: number) => {
        try {
            await renterCompanyApi.renterCompanyChangeBlockStatusUserIdGet({ userId: id }, getConfig());
            return true;
        } catch {}
        return false;
    };
    const doGetApplicants = async (model: SearchModel) => {
        setLoading(true);
        try {
            const result = await contactFormApi.contactFormSearchPost({ searchModel: model }, postConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };
    const doGetNotificationCount = async (receiverId: any) => {
        setLoading(true);
        try {
            const result = await notificationApi.notificationCountReceiverIdGet({ receiverId: receiverId }, getConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };
    const doGetNotifications = async (receiverId: any) => {
        setLoading(true);
        try {
            const result = await notificationApi.notificationReceiverIdGet({ receiverId: receiverId }, getConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetAllNotifications = async (receiverId: any) => {
        setLoading(true);
        try {
            const result = await notificationApi.notificationAllReceiverIdGet({ receiverId: receiverId }, getConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doDownloadMachineDocument = async (documentId: any) => {
        setLoading(true);
        try {
            const result = await documentApi.documentDocumentIdDownloadGetRaw({ documentId: documentId }, getConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    const doGetErrorLogs = async (model: SearchModel) => {
        setLoading(true);
        try {
            const result = await errorLogApi.errorLogsSearchPost({ searchModel: model }, postConfig());
            setLoading(false);
            return result;
        } catch (err: any) {
            await handleSecureCatchErrors(err);
            return undefined;
        }
    };

    return {
        doLogin,
        doSendOtp,
        doLogout,
        doLessorSignup,
        doRenterSignup,
        doResendActivationCode,
        doActivate,
        doForgotPassword,
        doPostPasswordReset,
        doGetMachineSubcategory,
        doGetFuelTypes,
        doGetCameraResolutionTypes,
        doGetElevatorTypes,
        doGetRentalTypes,
        doGetCurrencies,
        doGetCities,
        doGetAttachments,
        doGetBrandsByCategory,
        doGetLessorCompanyShortList,
        doSearchMachine,
        doPostCreateMachine,
        doGetRequests,
        doGetRequestDetail,
        doGetMachine,
        doUpdateMachine,
        doSwitch,
        doUpdateRequest,
        doRejectRequest,
        doApproveRequest,
        doGetSuitableCompanies,
        doSuggestCompanies,
        doSearchOffers,
        doGetOfferedCompanies,
        doGetOfferDetail,
        doGetMaturityOptions,
        doApproveOffer,
        doRejectOffer,
        doSetCommission,
        doDealOffer,
        doGetExactCities,
        doSearchRenterCompanies,
        doSearchLessorCompanies,
        doGetLessorCompanyDetail,
        doGetRenterCompanyDetail,
        doGetLessorCompanyUsers,
        doGetLessorBlocked,
        doGetRenterBlocked,
        doBlockLessorCompany,
        doBlockRenterCompany,
        doGetRenterCompanyList,
        doGetApplicants,
        doRejectPayment,
        doGetNotificationCount,
        doGetNotifications,
        doGetAllNotifications,
        doDownloadMachineDocument,
        doGetErrorLogs,
    };
};
