import { Card } from "antd";
import { EventHandler, useEffect } from "react";
import styles from "../styles/modal-layout.module.scss";
import helperStyles from "../styles/helpers.module.scss";

export type ModalLayoutProps = {
    children?: any;
    show: boolean;
    onCloseClick: EventHandler<any>;
};

const ModalLayout = (props: ModalLayoutProps) => {
    useEffect(() => {
        if (props.show) {
            document.body.style.overflow = "hidden";
        } else document.body.style.overflow = "scroll";
    }, [props.show]);
    return (
        <div
            className={props.show ? styles.lightboxContainer : `${styles.lightboxContainer} ${helperStyles.hide}`}
            style={props.show ? { top: document.documentElement.scrollTop } : undefined}
        >
            <Card className={styles.lightbox}>
                <div
                    className={styles.closeButton}
                    onClick={() => {
                        if (props.onCloseClick) props.onCloseClick(!props.show);
                    }}
                >
                    X
                </div>
                {props.children}
            </Card>
        </div>
    );
};

export default ModalLayout;
