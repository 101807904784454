import { Form, Checkbox, Input, ColProps, Tooltip, FormInstance, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ReactElement, useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { t } from "../../../translate";
import BorderedBoxContainer from "../../containers/bordered-box-container";
import { BasicModel } from "../../../service/models/BasicModel";

export type SuffixProps = {
    items: BasicModel[] | undefined;
    selectedItem: BasicModel | undefined;
    selectName: string | undefined;
};

export enum EnterInput {
    Price,
}

export type CheckboxWithInputProps = {
    checkboxLabel: string | null | undefined;
    inputLabel: string;
    checkboxName: string;
    inputName: string;
    initialValue?: number | null | undefined;
    suffix?: string | ReactElement;
    isSuffixDropdown?: boolean;
    suffixProps?: SuffixProps;
    tooltip?: string;
    form: FormInstance<any>;
    alwaysDisabled?: boolean;
    enterType?: EnterInput;
} & ColProps;

export const CheckboxWithInput = (props: CheckboxWithInputProps) => {
    const [checkNick, setCheckNick] = useState(false);
    const [inputVal, setInputVal] = useState("");
    const [inputDisabled, setInputDisabled] = useState(!!!props.initialValue || props.alwaysDisabled);

    useEffect(() => {
        const inputVal = props.form.getFieldValue(props.inputName);
        if (inputVal) setInputDisabled(false);
        //eslint-disable-next-line
    }, [props.form]);

    const checkboxChange = (e: CheckboxChangeEvent) => {
        setInputDisabled(!!!e.target.checked);
        setCheckNick(e.target.checked);
        setInputVal("");
    };

    const containerClick = () => {
        setInputDisabled(!!!inputDisabled);
        setCheckNick(!checkNick);
        setInputVal("");
        interface LooseObject {
            [key: string]: any;
        }
        let obj: LooseObject = {};
        obj[props.checkboxName] = inputDisabled;
        props.form?.setFieldsValue(obj);
    };

    const checkboxComponent =
        props.tooltip === undefined ? (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ fontWeight: "700", cursor: "pointer" }} onClick={containerClick}>
                    {props.checkboxLabel}
                </span>
                <Form.Item name={props.checkboxName} valuePropName="checked" initialValue={!inputDisabled} style={{ marginBottom: "0rem" }}>
                    <Checkbox onChange={checkboxChange} style={{ minHeight: "0" }}></Checkbox>
                </Form.Item>
            </div>
        ) : (
            <Tooltip title={props.tooltip}>
                <Form.Item name={props.checkboxName} valuePropName="checked" initialValue={!inputDisabled} style={{ marginBottom: "0rem" }}>
                    <Checkbox onChange={checkboxChange}>
                        {props.checkboxLabel} <InfoCircleOutlined />
                    </Checkbox>
                </Form.Item>
            </Tooltip>
        );
    const SelectAfter = (suffixProps: SuffixProps) => {
        return (
            <Form.Item
                name={suffixProps.selectName}
                initialValue={suffixProps.selectedItem?.name}
                noStyle
                rules={[
                    {
                        required: checkNick,
                        message: t("common.generic-required-choose-message"),
                    },
                ]}
            >
                <Select style={{ width: "4.75rem" }} defaultValue={suffixProps.selectedItem?.name}>
                    {suffixProps.items?.map((model) => {
                        return <Select.Option key={model.name}>{model.name}</Select.Option>;
                    })}
                </Select>
            </Form.Item>
        );
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.enterType !== EnterInput.Price) return;

        interface LooseObject {
            [key: string]: any;
        }
        let obj: LooseObject = {};
        let parsedValue = e.target.value;
        const regexPattern = /[^0-9]/gm;
        parsedValue = parsedValue.replace(regexPattern, "");
        if (parsedValue[0] === "0") parsedValue = "";
        obj[props.inputName] = parsedValue;
        props.form?.setFieldsValue(obj);
    };

    return (
        <BorderedBoxContainer {...props}>
            {checkboxComponent}
            {!inputDisabled && !props.alwaysDisabled && (
                <Form.Item
                    name={props.inputName}
                    label={props.inputLabel}
                    rules={[
                        {
                            required: checkNick,
                            message: t("common.generic-required-message"),
                        },
                    ]}
                    initialValue={props.initialValue}
                >
                    {props.isSuffixDropdown !== undefined && props.isSuffixDropdown ? (
                        <Input
                            disabled={inputDisabled}
                            value={inputVal}
                            onChange={handleChange}
                            type="text"
                            addonAfter={
                                <SelectAfter
                                    selectName={props.suffixProps?.selectName}
                                    items={props.suffixProps?.items}
                                    selectedItem={props.suffixProps?.selectedItem}
                                />
                            }
                        />
                    ) : (
                        <Input disabled={inputDisabled} value={inputVal} onChange={handleChange} type="text" addonAfter={props.suffix} />
                    )}
                </Form.Item>
            )}
        </BorderedBoxContainer>
    );
};
