import { Checkbox, Col, Row, Space } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../helpers/use-query";
import ViewAdditionalInfoPart from "../../../page-parts/view-additional-info-part";
import ViewAdditionalServicesPart from "../../../page-parts/view-additional-services-part";
import ViewMachineInfoPart from "../../../page-parts/view-machine-info-part";
import ViewServiceRegionsPart from "../../../page-parts/view-service-regions-part";
import { ModifiedRequestDetailModel } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import ListContainer from "../../../components/containers/list-container";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import RequestModal from "../../../modals/request-modal";
import BigboxContainer from "../../../components/containers/bigbox-container";
import { BasicModel, LessorCompanyRequestViewModel, OfferFormViewModel, SubCategoryModel } from "../../../service";
import DataTable from "../../../components/atomics/data-table";
import RequestHistoryPart from "../../../page-parts/request-history-part";
import BoxContainer from "../../../components/containers/box-container";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import IncomingOfferPart from "../../../page-parts/incoming-offers-part";
import AccepteOfferPart from "../../../page-parts/accepted-offer-parts";
import SuggestedOffersPart from "../../../page-parts/suggested-offers-part";

const ViewRequest = () => {
    const query = useQuery();
    const reqId = query.get("reqid");
    const navigate = useNavigate();
    const [rejectrequestVisible, toggleRejectRequestVisible] = useState(false);
    const [approverequestVisible, toggleApproveRequestVisible] = useState(false);
    const { doGetRequestDetail } = useSecureService();
    const { doSuggestCompanies } = useSecureService();
    const { doGetSuitableCompanies } = useSecureService();
    const [request, setRequest] = useState<ModifiedRequestDetailModel>({});
    const [suitableCompanies, setSuitableCompanies] = useState<LessorCompanyRequestViewModel[] | undefined>(undefined);
    const [cityFilter, setCityFilter] = useState<boolean>(false);
    const [checkCompany, setCheckCompany] = useState<number[]>([]);
    const onSelectionChange = (lessorId: number | undefined) => {
        setCheckCompany((oldValues) => {
            let newValues: number[] = [...oldValues];
            if (!lessorId) return oldValues;
            const lessorIdIndexNumber = oldValues.indexOf(lessorId);
            if (lessorIdIndexNumber === -1) {
                newValues.push(lessorId);
            } else {
                newValues.splice(lessorIdIndexNumber);
            }
            return newValues;
        });
    };
    useEffect(() => {
        if (reqId) {
            const reqIdN = parseInt(reqId);
            if (!Number.isNaN(reqIdN)) {
                doGetRequestDetail(reqIdN).then((data) => {
                    setRequest(data as ModifiedRequestDetailModel);
                });
                doGetSuitableCompanies(reqIdN).then((data) => {
                    setSuitableCompanies(data);
                });
            }
        }
        //eslint-disable-next-line
    }, []);
    let requestId: any = reqId;

    const columns = [
        {
            title: t("requests.table.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("requests.table.companyName"),
            dataIndex: "companyName",
            key: "companyName",
        },
        {
            title: t("requests.table.serviceRegions"),
            dataIndex: "serviceRegions",
            key: "serviceRegions",
            render: (val: BasicModel[]) => <>{val.map((cty) => cty.name).join(",")}</>,
        },
        {
            title: t("requests.table.machines"),
            dataIndex: "machines",
            key: "machines",
            render: (val: SubCategoryModel[]) => <>{val.length}</>,
        },
        {
            title: t("requests.table.offerCount"),
            dataIndex: "offerCount",
            key: "offerCount",
        },
        {
            title: t("requests.table.acceptedOfferCount"),
            dataIndex: "acceptedOfferCount",
            key: "acceptedOfferCount",
        },
        {
            title: t("common.select"),
            key: "action",
            render: (record: LessorCompanyRequestViewModel) => {
                return (
                    <Space size="middle">
                        <Checkbox onChange={() => onSelectionChange(record.id)}></Checkbox>
                    </Space>
                );
            },
        },
    ];

    const onSuggestClick = async () => {
        if (reqId) {
            const reqIdN = parseInt(reqId);
            if (!Number.isNaN(reqIdN)) {
                const result = await doSuggestCompanies(reqIdN, checkCompany);
                if (result) navigate(-1);
            }
        }
    };
    const onRowClick = (item: OfferFormViewModel | undefined) => {
        navigate("/offers/view?offerid=" + item?.id + "&reqid=" + reqId);
    };

    let filteredSuitableCompanies = suitableCompanies;
    if (cityFilter)
        filteredSuitableCompanies = suitableCompanies?.filter((sc) => {
            return sc.serviceRegionMatched;
        });
    const onFilterClick = (e: CheckboxChangeEvent) => {
        setCityFilter(e.target.checked);
    };
    return (
        <ListContainer activePage={"requests"} title={t("requests.view.title")} description={t("requests.view.description")}>
            <div>
                <Row gutter={[4, 4]}>
                    <Col xs={24} md={12}>
                        <ViewMachineInfoPart viewType="request" request={request} />
                    </Col>
                    <Col xs={24} md={12}>
                        <ViewAdditionalInfoPart viewType="request" request={request} />
                    </Col>
                    <Col xs={24} md={12}>
                        <ViewAdditionalServicesPart viewType="request" request={request} />
                    </Col>
                    <Col xs={24} md={12}>
                        <ViewServiceRegionsPart viewType="request" request={request} />
                    </Col>
                </Row>

                {request?.status !== "InReview" && request?.status !== "PreRegistration" && (
                    <BigboxContainer title={t("request.suggestCompany")}>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <BoxContainer style={{ height: "inintial" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <span style={{ fontWeight: "700", cursor: "pointer", marginRight: "1rem" }}>
                                                {t("requests.serviceRegionMatch")}
                                            </span>
                                            <Checkbox onChange={onFilterClick}></Checkbox>
                                        </div>
                                    </BoxContainer>
                                </div>
                            </Col>
                        </Row>
                        <div style={{ float: "right" }}>
                            <PrimaryButton onClick={onSuggestClick} style={{ width: "initial" }}>
                                {t("common.submit")}
                            </PrimaryButton>
                        </div>
                        <DataTable columns={columns} dataSource={filteredSuitableCompanies} pagination={false}></DataTable>
                    </BigboxContainer>
                )}
                {request?.status !== "InReview" && request?.status !== "PreRegistration" && (
                    <SuggestedOffersPart offers={request.offerForms?.filter((x) => x.status === "Suggested")}></SuggestedOffersPart>
                )}
                {request?.status !== "InReview" && request?.status !== "PreRegistration" && (
                    <IncomingOfferPart
                        onRow={onRowClick}
                        offers={request.offerForms?.filter((x) => x.status === "Offered" || x.status === "Approved" || x.status === "Expired")}
                    ></IncomingOfferPart>
                )}
                {request?.status !== "InReview" && request?.status !== "PreRegistration" && (
                    <AccepteOfferPart
                        onRow={onRowClick}
                        Acceptedoffers={request.offerForms?.filter(
                            (off) => off.status === "Accepted" || off.status === "Deal" || off.status === "PaymentDeclined"
                        )}
                    ></AccepteOfferPart>
                )}
                <RequestHistoryPart comments={request.requestFormComments} />
                {(request?.status === "InReview" || request?.status === "PreRegistration" ) && (
                    <Row gutter={[4, 4]}>
                        <Col xs={{ span: 24 }} md={{ span: 18, offset: 3 }} style={{ marginTop: "1rem" }}>
                            <Row gutter={16}>
                                <Col xs={24} md={8}>
                                    <SecondaryButton onClick={() => navigate("/requests/edit?reqid=" + reqId)}>
                                        {t("common.edit")}
                                    </SecondaryButton>
                                </Col>
                                <Col xs={24} md={8}>
                                    <SecondaryButton onClick={() => toggleRejectRequestVisible(!rejectrequestVisible)} negative>
                                        {t("common.reject")}
                                    </SecondaryButton>
                                </Col>
                                <Col xs={24} md={8}>
                                    <PrimaryButton onClick={() => toggleApproveRequestVisible(!approverequestVisible)}>
                                        {t("common.approve")}
                                    </PrimaryButton>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
                {PrimaryButton}
            </div>
            <RequestModal
                onCloseClick={() => {
                    if (rejectrequestVisible) {
                        toggleRejectRequestVisible(!rejectrequestVisible);
                    } else if (approverequestVisible) {
                        toggleApproveRequestVisible(!approverequestVisible);
                    }
                }}
                show={approverequestVisible ? approverequestVisible : rejectrequestVisible}
                requestType={approverequestVisible ? "approve" : "reject"}
                requestId={requestId}
            ></RequestModal>
        </ListContainer>
    );
};

export default ViewRequest;
