import { Col, Row } from "antd";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import ListContainer from "../../../components/containers/list-container";
import { useQuery } from "../../../helpers/use-query";
import ViewAdditionalInfoPart from "../../../page-parts/view-additional-info-part";
import ViewAdditionalServicesPart from "../../../page-parts/view-additional-services-part";
import ViewMachineInfoPart from "../../../page-parts/view-machine-info-part";
import ViewServiceRegionsPart from "../../../page-parts/view-service-regions-part";
import { extendedMachineDetailAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import { ReactComponent as ArrowLeft } from "./../../../components/assets/left-arrow.svg";

const ViewMachine = () => {
    const query = useQuery();
    const machineId = query.get("macid");
    const [editedMachine, editMachine] = useAtom(extendedMachineDetailAtom);
    const navigate = useNavigate();
    const { doGetMachine } = useSecureService();
    useEffect(() => {
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN))
                doGetMachine(machineIdN).then((data) => {
                    editMachine(data);
                });
        }
        //eslint-disable-next-line
    }, []);

    return (
        <ListContainer activePage={"machines"}>
            <Row justify="space-between" align="middle">
                <Col>
                    <ArrowLeft></ArrowLeft>
                    {t("machine.details")}
                </Col>
                <Col style={{ float: "right" }}>
                    {editedMachine?.lessorCompanyId !== undefined && editedMachine?.lessorCompanyId !== null && (
                        <SecondaryButton
                            onClick={() => {
                                navigate("/lessor-company/view?compid=" + editedMachine.lessorCompanyId, { replace: true });
                            }}
                            style={{ marginRight: "0.5rem", width: "inherit" }}
                        >
                            {t("companies.detail.view")}
                        </SecondaryButton>
                    )}
                    <PrimaryButton
                        onClick={() => {
                            navigate("/machines/edit?macid=" + machineId, { replace: true });
                        }}
                        style={{ width: "initial" }}
                    >
                        {t("common.edit")}
                    </PrimaryButton>
                </Col>
            </Row>
            <Row gutter={[4, 4]}>
                <Col xs={24} md={12}>
                    <ViewMachineInfoPart viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalInfoPart viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalServicesPart viewType="machine" machine={editedMachine} />
                </Col>
                <Col xs={24} md={12}>
                    <ViewServiceRegionsPart viewType="machine" machine={editedMachine} />
                </Col>
            </Row>
        </ListContainer>
    );
};

export default ViewMachine;
