import DataTable from "../components/atomics/data-table";
import { BasicModel, OfferFormBidViewModel, OfferFormViewModel } from "../service";
import { t } from "../translate";
import { moment } from "../helpers/moment";
import BigboxContainer from "../components/containers/bigbox-container";

type SuggestedOffersPartProps = {
    offers?: OfferFormViewModel[] | null;
    onRow?: (record: OfferFormViewModel | undefined) => void;
};

const SuggestedOffersPart = (props: SuggestedOffersPartProps) => {
    const cols = [
        {
            title: t("offers.table.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("offers.table.company"),
            dataIndex: "company",
            key: "company",
        },
        {
            title: t("offers.table.machines"),
            dataIndex: "machines",
            key: "machines",
            render: (val: BasicModel[]) => {
                const machineTitles = val.map((bm) => bm.name).join("\n");
                return <>{machineTitles}</>;
            },
        },
        {
            title: t("offers.table.notes"),
            dataIndex: "notes",
            key: "notes",
        },
        {
            title: t("offers.table.creationDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("offers.table.expireDate"),
            dataIndex: "expireDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("offers.table.bid"),
            dataIndex: "bids",
            key: "bids",
            render: (val: OfferFormBidViewModel) => <>{val.bid}</>,
        },
    ];

    const onRowHandler = (record: any, rowIndex: number | undefined) => {
        return {
            onClick: (event: any) => {
                if (props.onRow) props.onRow(record);
            },
        };
    };

    return (
        <BigboxContainer title={t("requests.view.suggestedFirms")}>
            {props.offers && <DataTable onRow={onRowHandler} dataSource={props.offers} columns={cols} pagination={false} />}
        </BigboxContainer>
    );
};

export default SuggestedOffersPart;
