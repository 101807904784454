import { Collapse } from "antd";
import styles from "../styles/accordion-payment.module.scss";
import { ReactComponent as Plus } from "../assets/plus.svg";
import { ReactComponent as Minus } from "../assets/minus.svg";


type AccordionProps = {
    activeKey?: string | number;
    children: any;
    panelHeader:string|null|undefined;
    
};

const AccordionPayment = (props: AccordionProps) => {
    const { Panel } = Collapse;
    let actkey =props.activeKey as string | number ;//<<<Error here 
    const Header = (
        <div className={styles.accordionItemHeader}>
                <div>
                    {props.panelHeader}
                </div>
            </div>
    )
    return (
        <Collapse
            
            expandIconPosition="right"
            bordered={false}
            accordion
            defaultActiveKey={props.activeKey}
            className={styles.accordionCollapse}
            expandIcon={(panelProps) => {
                if (panelProps.isActive) return <Minus className={styles.expandIcons} />;
                else return <Plus className={styles.expandIcons} />;
            }}
        >
          <Panel className={styles.accordionItemPanel} header={Header} key={actkey}>{props.children}</Panel> 
        </Collapse>
    );
};

export default AccordionPayment;
