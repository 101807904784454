import { Col, Select } from "antd";
import { useAtom } from "jotai";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import ModalLayout, { ModalLayoutProps } from "../components/containers/modal-layout";
import ModalCardContainer from "../components/containers/modalcard-container";
import { BasicModel } from "../service";
import { loadingAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";

const SelectCompanyModal = (props: ModalLayoutProps) => {
    const [loading] = useAtom(loadingAtom);
    const { Option } = Select;
    const navigate = useNavigate();
    const [companies, setCompanies] = useState<undefined | BasicModel[]>(undefined);
    const [selectedCompanyId, setSelectedCompanyId] = useState<number | undefined>();
    const { doGetLessorCompanyShortList } = useSecureService();
    useEffect(() => {
        const getCompanies = async () => {
            const comps = await doGetLessorCompanyShortList();
            setCompanies(comps);
        };
        getCompanies();
        // eslint-disable-next-line
    }, []);
    const onContinueClick = () => {
        if (selectedCompanyId) navigate("/machines/add?compid=" + selectedCompanyId);
    };
    const onSelectChange = (value: number | undefined) => {
        setSelectedCompanyId(value);
    };
    return (
        <ModalLayout {...props}>
            <ModalCardContainer title={t("common.makinesahibi")}>
                <Col md={24}>
                    <span style={{ marginLeft: "0.5rem" }}>{t("machines.table.firmName")}</span>
                    <Col md={24}>
                        <Select placeholder={t("common.select")} onChange={onSelectChange} loading={loading} optionFilterProp="children">
                            {companies?.map((cmp) => (
                                <Option key={cmp.id} value={cmp.id}>
                                    {cmp.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={18} offset={3}>
                        <PrimaryButton onClick={onContinueClick}>{t("common.continue")}</PrimaryButton>
                    </Col>
                </Col>
            </ModalCardContainer>
        </ModalLayout>
    );
};

export default SelectCompanyModal;
