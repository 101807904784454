import { Col, Divider, Input, Row } from "antd";
import { OfferFormBidViewModel } from "../../service/models";
import styles from "../styles/payment.module.scss";
import { t } from "../../translate";

type PaymentContainerProps = {
    bid?: OfferFormBidViewModel;
    onBidChange?: (param: OfferFormBidViewModel) => void;
};

export const PaymentContainer = (props: PaymentContainerProps) => {
    const totalBid = (props.bid?.predictedTotal || 0) + (props.bid?.machineGoCommission || 0);
    return (
        <div {...props}>
            <Row style={{ display: "flex", alignItems: "center" }} gutter={[0, 16]} justify="space-between">
                <Col md={12} xs={12}>
                    <Row justify="space-between">
                        <Col md={12} className={styles.labelStyle} >
                            {t("offers.view.period")}
                        </Col>
                        <Col md={12} className={styles.priceStyle}>
                            {props.bid?.paymentPeriod?.name}
                        </Col>
                    </Row>
                </Col>
                <Col md={12} xs={12}>
                    <Row justify="space-between">
                        <Col md={12} className={styles.labelStyle} >
                            {t("offers.view.price")}
                        </Col>
                        <Col  md={12} className={styles.priceStyle}>
                            {props.bid?.bid} {props.bid?.currency?.name}
                        </Col>
                    </Row>    
                </Col>
            </Row>
            <Divider></Divider>
            <Row style={{ display: "flex", alignItems: "center" }} gutter={[0, 16]} className={styles.priceTable} justify="space-between">
                <Col xs={16}>{t("offers.view.predictedTotal")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const calp = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0 ;
                            const bidModel = {
                                ...props.bid,
                                predictedTotal: calp,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.predictedTotal}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
                <Col xs={16}>{t("offers.view.machineGoCommission")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const comm = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel = {
                                ...props.bid,
                                machineGoCommission: comm,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.machineGoCommission}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>

                <Col xs={16}>{t("offers.view.advancePayment")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const ladv = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            if (props.onBidChange)
                                props.onBidChange({
                                    ...props.bid,
                                    advancePayment:ladv,
                                });
                        }}
                        value={props?.bid?.advancePayment}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>

                <Col xs={16}>{t("offers.view.renterPeriodPayment")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const renterp = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel = {
                                ...props.bid,
                                renterPeriodPayment: renterp,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.renterPeriodPayment}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
                <Col xs={16}>{t("offers.view.lessorMachineGoCommission")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const lessorc = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel = {
                                ...props.bid,
                                lessorMachineGoCommission: lessorc,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.lessorMachineGoCommission}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
                <Col xs={16}>{t("offers.view.lessorPeriodPayment")}</Col>
                <Col xs={8} className={styles.suffixStyle}>
                    <Input
                        type={"number"}
                        onWheel={(e: any) => e.target.blur()}
                        onChange={(val) => {
                            const lessorp = val?.currentTarget?.value ? Number.parseInt(val?.currentTarget?.value) : 0;
                            const bidModel = {
                                ...props.bid,
                                lessorPeriodPayment: lessorp,
                            };
                            if (props.onBidChange) props.onBidChange(bidModel);
                        }}
                        value={props.bid?.lessorPeriodPayment}
                    ></Input>{" "}
                    {props.bid?.currency?.name}
                </Col>
            </Row>
            <Row style={{ display: "flex", alignItems: "center" }} gutter={[16, 16]} justify="space-between">
                <Col className={styles.labelStyle} xs={16}>
                    {t("offers.view.totalBid")}
                </Col>
                <Col className={styles.labelStyle} xs={8}>
                    {totalBid} {props.bid?.currency?.name}
                </Col>
            </Row>
            <Divider></Divider>
            <Row style={{ display: "flex", alignItems: "center" }} gutter={[16, 16]} justify="space-between">
                <Col>{t("offers.view.paymentLink")}</Col>
                <Col>
                    <Input
                        placeholder="https://www.example.com/abc"
                        onChange={(e) => {
                            if (props.onBidChange)
                                props.onBidChange({
                                    ...props.bid,
                                    paymentLink: e.currentTarget.value,
                                });
                        }}
                        value={props.bid?.paymentLink || undefined}
                    ></Input>
                </Col>
            </Row>
        </div>
    );
};
