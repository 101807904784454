import { Col, message, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListContainer from "../../components/containers/list-container";
import { BasicModel, MachineSearchFilterModel, MachineSearchSortField } from "../../service/models";
import { PaginationType, useSecureService } from "../../store/service-atoms";
import { t } from "../../translate";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/components/CommonPropTypes";
import { EditOutlined } from "@ant-design/icons";
import { extendedMachineDetailAtom, loadingAtom, machineEditAtom } from "../../store/global-atoms";
import { useAtom } from "jotai";
import DataTable from "../../components/atomics/data-table";
import PrimaryButton from "../../components/atomics/buttons/primary-button";
import { ReactComponent as PlusB } from "./../../components/assets/plus-black.svg";
import { ReactComponent as CsvDownload } from "./../../components/assets/download-csv.svg";
import SecondaryButton from "../../components/atomics/buttons/secondary-button";
import SelectCompany from "../../modals/select-company";
import { moment } from "../../helpers/moment";
import { ExtendedMachineDetailModel } from "../../service/models/ExtendedMachineDetailModel";
import MachineListFilter from "../../components/containers/machine-list-filter";
import { useQuery } from "../../helpers/use-query";

type TableModel = {
    companyName?: string | null;
    districtAndCity: string | undefined;
    subCategoryName?: string | null;
    allAttachments?: string | null;
} & ExtendedMachineDetailModel;

const castToTableModelArray = (data: ExtendedMachineDetailModel[] | null | undefined) => {
    const newList = data?.map((item) => {
        let attachmentsValue = item?.attachments?.map((atc) => {
            return atc.name;
        });

        let cities = item?.serviceRegions?.map((cty) => {
            return cty.name;
        });

        const tableModel: TableModel = {
            id: item.id,
            brandName: item.brand?.name ? item.brand.name : "",
            companyName: item.lessorCompany?.name,
            districtAndCity: cities?.join(", "),
            creationDate: item.creationDate,
            subCategoryName: item.subCategory?.name,
            allAttachments: attachmentsValue?.join(", "),
            ...item,
        };
        return tableModel;
    });
    return newList;
};

const Machines = () => {
    const { doSearchMachine } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [selectcompanyVisible, toggleSelectCompanyVisible] = useState(false);
    const [machines, setMachines] = useState<TableModel[] | undefined>(undefined);
    const query = useQuery();
    const machineId = query.get("macid");
    const compName = query.get("compname");
    const [, editMachine] = useAtom(extendedMachineDetailAtom);
    const { doGetMachine } = useSecureService();
    const navigate = useNavigate();
    const [, ViewMachine] = useAtom(machineEditAtom);
    const [filterObj, setFilterObj] = useState<{
        filterModel: MachineSearchFilterModel;
        sortBy: {
            field: MachineSearchSortField;
            sortAscending: boolean;
        };
    }>({
        filterModel: {
            companyName: compName,
        },
        sortBy: {
            field: MachineSearchSortField.CreationDate,
            sortAscending: false,
        },
    });
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });
    useEffect(() => {
        if (machineId) {
            const machineIdN = parseInt(machineId);
            if (!Number.isNaN(machineIdN))
                doGetMachine(machineIdN).then((data) => {
                    editMachine(data);
                });
        }
        //eslint-disable-next-line
    }, []);
    const [itemCount, setItemCount] = useState<number | undefined>(1);
    const columns = [
        {
            title: t("machines.table.machineId"),
            dataIndex: "id",
            key: "id",
            render: (text: string, detail: any) => (
                <Space size="middle">
                    <Tooltip title={t("common.view")}>
                        <div onClick={() => rowClickHandler(detail)}>{text}</div>{" "}
                    </Tooltip>
                </Space>
            ),
        },
        {
            title: t("machines.table.machineCategory"),
            dataIndex: "category",
            key: "category",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.machineType"),
            dataIndex: "subCategoryName",
            key: "subCategoryName",
        },
        {
            title: t("machines.table.brand"),
            dataIndex: "brand",
            key: "brand",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.model"),
            dataIndex: "modelName",
            key: "modelName",
        },
        {
            title: t("machines.table.modelYear"),
            dataIndex: "productionYear",
            key: "productionYear",
        },
        {
            title: t("machines.table.firmName"),
            dataIndex: "lessorCompany",
            key: "lessorCompany",
            render: (val: BasicModel) => <>{val.name}</>,
        },
        {
            title: t("machines.table.CreationDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("common.action"),
            key: "action",
            render: (text: string, record: any) => (
                <Space size="middle">
                    <Tooltip title={t("common.edit")}>
                        <EditOutlined onClick={() => viewClickHandler(record)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current };
        });
    };

    useEffect(() => {
        const fillMachineList = async () => {
            try {
                const result = await doSearchMachine({
                    pageNo: pagination.current,
                    pageSize: pagination.pageSize,
                    filter: filterObj.filterModel,
                    sortAscending: filterObj.sortBy.sortAscending,
                    sortBy: filterObj.sortBy.field,
                });
                if (result?.machines !== null) {
                    setItemCount(result?.itemCount);
                    setMachines(castToTableModelArray(result?.machines));
                }
            } catch (err: any) {
                message.error(err.toString());
            }
        };
        fillMachineList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        if (!itemCount || itemCount <= 0) {
            return;
        }
        const result = await doSearchMachine({ pageNo: 1, pageSize: itemCount });
        if (result?.machines?.length !== undefined && result?.machines?.length > 0) {
            const exportData = castToTableModelArray(result?.data);
            if (exportData?.length !== undefined && exportData?.length > 0) {
                setAllRows(exportData);
                csvRef.current?.link.click();
            }
            //result.data[0].rentalTypes[0].rentalFee
        }
    };

    const rowClickHandler = async (record: ExtendedMachineDetailModel) => {
        ViewMachine(record);
        navigate("/machines/view?macid=" + record.id);
    };
    const viewClickHandler = (machineId: ExtendedMachineDetailModel) => {
        editMachine(machineId);
        navigate("/machines/edit?macid=" + machineId.id);
    };

    return (
        <ListContainer>
            <MachineListFilter
                isAdmin={true}
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params.searchFilterModel || {},
                        sortBy: params.sortBy,
                    });
                }}
            ></MachineListFilter>
            <Row justify="space-between" align="middle">
                <Col>{t("menu.listMachines")}</Col>
                <Col style={{ float: "right" }}>
                    <PrimaryButton
                        onClick={() => toggleSelectCompanyVisible(!selectcompanyVisible)}
                        style={{ marginRight: "0.5rem", width: "inherit", marginBottom: "0px" }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <PlusB style={{ marginRight: "0.5rem" }} />
                            {t("success.machine.middleButtonText")}
                        </div>
                    </PrimaryButton>
                    <SecondaryButton onClick={exportClick} style={{ width: "initial", marginBottom: "0px" }}>
                        <CsvDownload style={{ display: "flex" }} />
                    </SecondaryButton>
                </Col>
            </Row>
            <CSVLink filename="machines.csv" ref={csvRef} data={allRows} separator={";"} />
            <br />
            {
                <DataTable
                    onChange={handleChange}
                    columns={columns}
                    dataSource={machines}
                    loading={loading}
                    pagination={{ ...pagination, total: itemCount, showSizeChanger: false }}
                />
            }
            <SelectCompany
                onCloseClick={() => toggleSelectCompanyVisible(!selectcompanyVisible)}
                show={selectcompanyVisible}
            ></SelectCompany>
        </ListContainer>
    );
};

export default Machines;
