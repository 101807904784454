import { Col, Form, Input, Row, Select } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { DropdownWithInput } from "../components/atomics/inputs/dropdown-with-input";
import {
    BladeWidth,
    BomLength,
    BreakingInfo,
    CameraResolution,
    CarryingCapacity,
    CleaningCapacity,
    CompressionWidth,
    ConcretePumpingCapacity,
    DrivingEquipment,
    ElevatorTypeId,
    EngineBrand,
    EnginePower,
    FlightDistance,
    FlightTime,
    FloorCount,
    FreeAirOutput,
    Frequency,
    FuelTypeId,
    GarbageCollectionBin,
    HasCabin,
    HasPlatform,
    HasTowerElevationSystem,
    Height,
    LiftingHeight,
    LightningCapacity,
    LoadCapacity,
    MaxDepth,
    MaxFeedingSize,
    MaxHeight,
    MaxLoadCapacity,
    MaxPower,
    MaxPressure,
    MaxWidth,
    MinPower,
    NominalCapacity,
    OperatingCapacity,
    OperatingHeight,
    OperatingLength,
    OperatingWeight,
    OperationHour,
    OperationMileage,
    OperationWidth,
    PlatformLength,
    PlatformWidth,
    SpraySpeed,
    TankCapacity,
    TankVolume,
    UsageFields,
    Volume,
    WaterTankCapacity,
    Width,
    WorkingRadius,
    WorkingSpeed,
} from "../components/atomics/form-items";
import { Uploader } from "../components/atomics/inputs/uploader";
import BigboxContainer from "../components/containers/bigbox-container";
import { BasicModel, CategoryModel, DocumentCategory, DocumentModel } from "../service";
import {activeApiBaseUrl} from "../store/app-constants";
import { loadingAtom, postloginMessageAtom } from "../store/global-atoms";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import { EditMachinePartProps } from "../viewmodels/edit-page-part-props";
import SecondaryButton from "../components/atomics/buttons/secondary-button";

const { Option } = Select;

const MachineMachineInfoPart = (props: EditMachinePartProps) => {
    const [, setErrorMessage] = useAtom(postloginMessageAtom);
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const [availableAttachments, setAvailableAttachments] = useState<BasicModel[] | undefined>(undefined);
    const [availableBrands, setAvailableBrands] = useState<BasicModel[] | undefined>(undefined);
    const [selectedCategory, setSelectedCategory] = useState<CategoryModel | undefined>(undefined);
    const [selectedSubcategory, setSelectedSubcategory] = useState<BasicModel | undefined>(undefined);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [loading] = useAtom(loadingAtom);
    const { doGetMachineSubcategory, doGetBrandsByCategory, doGetAttachments, doDownloadMachineDocument } = useSecureService();

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const currentCategory = props.form.getFieldValue("category") as BasicModel | undefined;
        onMachineCategorySelected(currentCategory?.name?.toString(), true);
        //eslint-disable-next-line
    }, [allCategories]);

    useEffect(() => {
        const currentSub = props.form.getFieldValue("subCategoryName") as string | undefined;
        onSubcategorySelected(currentSub, true);
        //eslint-disable-next-line
    }, [availableSubcategories]);


    const handleUploadChange = (data: UploadChangeParam<UploadFile<unknown>>) => {
        if (data?.file.status === "uploading") return;
        if (data.fileList !== undefined) {
            const files: UploadFile[] | undefined = data.fileList;
            let machineDetailImgeModel: DocumentModel[] = [];
            files?.forEach((file) => {
                if (file.status !== "done" && file.status !== "success") return;
                machineDetailImgeModel.push({ id: file.response.id, fileName: file.response.fileName });
            });
            props.uploadChange && props.uploadChange(machineDetailImgeModel, DocumentCategory.Photo);
        }
    };

    const onMachineCategorySelected = async (selectedCategoryName: string | undefined, initial?: boolean) => {
        const selectedCat = allCategories?.find((cat) => cat.name === selectedCategoryName);
        setSelectedCategory(selectedCat);

        if (selectedCat && selectedCat.id) {
            const brands = await doGetBrandsByCategory(selectedCat.id);
            setAvailableBrands(brands);
        }
        setAvailableSubcategories(selectedCat?.subCategories);
        setAvailableAttachments(undefined);
        if (!initial) {
            props.form.setFieldsValue({
                subcategoryName: undefined,
                brandName: undefined,
                attachmentsList: [],
            });
        }
    };

    const onSubcategorySelected = async (selectedSubcategoryName: string | undefined, initial?: boolean) => {
        const currentCategory = props.form.getFieldValue("categoryName") as BasicModel | undefined;

        const selectedCat = allCategories?.find((cat) => cat.name === currentCategory);
        const selectedSubcat = selectedCat?.subCategories?.find((sub) => sub.name === selectedSubcategoryName);

        setSelectedSubcategory(selectedSubcat);
        if (!initial) {
            props.form.setFieldsValue({
                attachmentsList: [],
                otherAttachmentsName: undefined,
            });
        }
        if (selectedSubcat && selectedSubcat.id) {
            const availableAtchs = await doGetAttachments(selectedSubcat?.id);
            setAvailableAttachments(availableAtchs);
        }
    };
    const sleep = async (ms:number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    const downloadAllDocs = async () => {
        for (let index = 0; index < fileList.length; index++) {
            const file = fileList[index];
            await downloadDoc(file, index + 1);
            
        }
    }
    const downloadDoc = async (file: any, index: any) => {
        const fileId = parseInt(file?.uid);
        var a = document.createElement("a");
        doDownloadMachineDocument(fileId)
            .then((res) => {
                if (res?.raw?.ok) {
                    res?.raw.blob().then((blob) => {
                        var file = window.URL.createObjectURL(blob);
                        a.href = file;
                        a.download = "dosya" + index?.toString();
                        a.click();
                        window.URL.revokeObjectURL(file);
                    });
                }
            })
            .catch(() => {});
        await sleep(500);
    }
    const fileListChangeHandler = (data:UploadFile[])=> {
        setFileList(data);
    }
    const editedMachine: any = undefined;
    
    return (
        <BigboxContainer title={t("machines.add.machineInfoTitle")}>
            <Uploader
                onFileListChange={fileListChangeHandler}
                onChange={handleUploadChange}
                defaultImageFiles={props.docs}
                maxSize={10}
                requestUrl={activeApiBaseUrl + "/Document"}
                unsupportedFileError={() => setErrorMessage({ message: t("common.unsupportedFile"), type: "error" })}
            />
            <SecondaryButton onClick={downloadAllDocs}>{t('machines.add.downloadAll')}</SecondaryButton>
            <Row gutter={16}>
                <Col xs={24} md={12}>
                    <Form.Item label={t("machines.add.machineIdLabel")} name="id">
                        <Input disabled placeholder={t("machines.add.machineIdPlaceholder")} />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="categoryName"
                        label={t("machines.add.category")}
                        rules={[{ required: true, message: t("common.generic-required-message") }]}
                    >
                        <Select showSearch loading={loading} onSelect={(item: string) => onMachineCategorySelected(item)}>
                            {allCategories?.map((mc) => (
                                <Option value={mc.name}>{mc.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="subcategoryName"
                        label={t("machines.add.subcategory")}
                        rules={[{ required: true, message: t("common.generic-required-message") }]}
                    >
                        <Select showSearch loading={loading} onSelect={(item: string) => onSubcategorySelected(item)}>
                            {availableSubcategories?.map((msc) => (
                                <Option value={msc.name}>{msc.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <DropdownWithInput
                        data={availableBrands}
                        required={true}
                        dropdownValidationMessage={t("common.generic-required-message")}
                        formItemLabel={t("machines.add.brand")}
                        formItemName="brandName"
                        loading={loading}
                        inputName="otherBrandName"
                        inputLabel={t("machines.add.otherBrandInputlabel")}
                        otherChoiceValue="Diğer"
                    />
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="modelName"
                        label={t("machines.add.modelName")}
                        rules={[{ required: true, message: t("common.generic-required-message") }]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                    <Form.Item
                        name="productionYear"
                        label={t("machines.add.productionYear")}
                        rules={[{ required: true, message: t("common.generic-required-message") }]}
                    >
                        <Input type={"number"} />
                    </Form.Item>
                </Col>

                {selectedSubcategory && (
                    <>
                        {OperationMileage(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operationMileage)}
                        {OperationHour(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operationHour)}
                        {DrivingEquipment(selectedCategory?.id, selectedSubcategory.id, editedMachine?.drivingEquipment)}
                        {EnginePower(selectedCategory?.id, selectedSubcategory.id, editedMachine?.enginePower)}
                        {OperatingWeight(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingWeight)}
                        {TankCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.tankCapacity)}
                        {OperatingHeight(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingHeight)}
                        {OperatingLength(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingLength)}
                        {OperationWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operationWidth)}
                        {OperatingCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.operatingCapacity)}
                        {UsageFields(selectedCategory?.id, selectedSubcategory.id, editedMachine?.usageFields)}
                        {HasCabin(selectedCategory?.id, selectedSubcategory.id, editedMachine?.hasCabin)}
                        {EngineBrand(selectedCategory?.id, selectedSubcategory.id, editedMachine?.engineBrand)}
                        {HasPlatform(selectedCategory?.id, selectedSubcategory.id, editedMachine?.hasPlatform)}
                        {HasTowerElevationSystem(selectedCategory?.id, selectedSubcategory.id, editedMachine?.hasTowerElevationSystem)}
                        {LightningCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.lightningCapacity)}
                        {CarryingCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.carryingCapacity)}
                        {Width(selectedCategory?.id, selectedSubcategory.id, editedMachine?.width)}
                        {Volume(selectedCategory?.id, selectedSubcategory.id, editedMachine?.volume)}
                        {Height(selectedCategory?.id, selectedSubcategory.id, editedMachine?.height)}
                        {FloorCount(selectedCategory?.id, selectedSubcategory.id, editedMachine?.floorCount)}
                        {BladeWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.bladeWidth)}
                        {Frequency(selectedCategory?.id, selectedSubcategory.id, editedMachine?.frequency)}
                        {MinPower(selectedCategory?.id, selectedSubcategory.id, editedMachine?.minPower)}
                        {MaxPower(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxPower)}
                        {MaxLoadCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxLoadCapacity)}
                        {NominalCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.nominalCapacity)}
                        {WaterTankCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.waterTankCapacity)}
                        {MaxFeedingSize(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxFeedingSize)}
                        {WorkingSpeed(selectedCategory?.id, selectedSubcategory.id, editedMachine?.workingSpeed)}
                        {CleaningCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.cleaningCapacity)}
                        {GarbageCollectionBin(selectedCategory?.id, selectedSubcategory.id, editedMachine?.garbageCollectionBin)}
                        {BreakingInfo(selectedCategory?.id, selectedSubcategory.id, editedMachine?.breakingInfo)}
                        {BomLength(selectedCategory?.id, selectedSubcategory.id, editedMachine?.bomLength)}
                        {MaxHeight(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxHeight)}
                        {MaxDepth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxDepth)}
                        {MaxWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxWidth)}
                        {CompressionWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.compressionWidth)}
                        {PlatformLength(selectedCategory?.id, selectedSubcategory.id, editedMachine?.platformLength)}
                        {PlatformWidth(selectedCategory?.id, selectedSubcategory.id, editedMachine?.platformWidth)}
                        {ConcretePumpingCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.concretePumpingCapacity)}
                        {MaxPressure(selectedCategory?.id, selectedSubcategory.id, editedMachine?.maxPressure)}
                        {FreeAirOutput(selectedCategory?.id, selectedSubcategory.id, editedMachine?.freeAirOutput)}
                        {SpraySpeed(selectedCategory?.id, selectedSubcategory.id, editedMachine?.spraySpeed)}
                        {TankVolume(selectedCategory?.id, selectedSubcategory.id, editedMachine?.tankVolume)}
                        {LoadCapacity(selectedCategory?.id, selectedSubcategory.id, editedMachine?.loadCapacity)}
                        {FlightTime(selectedCategory?.id, selectedSubcategory.id, editedMachine?.flightTime)}
                        {FlightDistance(selectedCategory?.id, selectedSubcategory.id, editedMachine?.flightDistance)}
                        {WorkingRadius(selectedCategory?.id, selectedSubcategory.id, editedMachine?.workingRadius)}
                        {LiftingHeight(selectedCategory?.id, selectedSubcategory.id, undefined)}
                        <FuelTypeId cid={selectedCategory?.id} sid={selectedSubcategory?.id} defaultValue={editedMachine?.fuelType?.name} />
                        <CameraResolution
                            cid={selectedCategory?.id}
                            sid={selectedSubcategory?.id}
                            defaultValue={editedMachine?.cameraResolution?.name}
                        />
                        <ElevatorTypeId
                            cid={selectedCategory?.id}
                            sid={selectedSubcategory?.id}
                            defaultValue={editedMachine?.elevatorType?.name}
                        />
                        <Col xs={24} md={12}>
                            <DropdownWithInput
                                data={availableAttachments}
                                required={false}
                                dropdownValidationMessage={t("common.generic-required-message")}
                                formItemLabel={t("machines.add-new.attachments")}
                                formItemName="attachmentsList"
                                loading={loading}
                                inputName="otherAttachmentsName"
                                inputLabel={t("machines.add-new.otherAttachmentInputlabel")}
                                inputValidationMessage={t("common.generic-required-message")}
                                otherChoiceValue="Diğer"
                                multiple
                            />
                        </Col>
                    </>
                )}
            </Row>
        </BigboxContainer>
    );
};

export default MachineMachineInfoPart;
