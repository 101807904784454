import { Row } from "antd";
import styles from "../styles/modal-card.module.scss";

export type ModalCardContainerProps = {
    children: any;
    title?: string;
};

const ModalCardContainer = (props: ModalCardContainerProps) => {
    return (
        <Row gutter={16} className={styles.ModalCard}>
            <div className={styles.cardHeader}>{props.title} </div>
            {props.children}
        </Row>
    );
};

export default ModalCardContainer;
