import { Col, Input, InputNumber, Row } from "antd";
import { useEffect, useState } from "react";
import {  RenterCompanySearchFilterModel, RenterCompanySearchSortField } from "../../service/models";
import { t } from "../../translate";
import BoxContainer from "./box-container";
//import styles from '../styles/filter-components.module.scss';
type RenterListFilterProps = {
    onChange?: (params: RenterListFilterViewModel) => void;
    children?: any;
    isAdmin?: boolean;
};

type RenterListFilterViewModel = {
    searchFilterModel?: RenterCompanySearchFilterModel;
    companyName?: string | null;
    sortBy: {
        field: RenterCompanySearchSortField;
        sortAscending: boolean;
    };
};

const RenterListFilter = (props: RenterListFilterProps) => {
    const [filterModel, setFilterModel] = useState<RenterListFilterViewModel>({
        sortBy: {
            
            field: RenterCompanySearchSortField.DealCount,
            sortAscending: true,
        },
    });
    
    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);


    const companyIdChange = (val: string | number) => {
        let compId: number | undefined = undefined;
        if (typeof val === "number") compId = val;
        setFilterModel((filt) => {
            return {
                ...filt,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    id:compId,
                },
            };
        });
    };

    const companyNameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    companyName: val,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    {t("common.filterBy")}
                    <Row gutter={[12, 12]}>
                        <Col xs={12} md={3}>
                            <InputNumber
                                style={{ width: "100%" }}
                                placeholder={t("renter.filter.id")}
                                value={filterModel.searchFilterModel?.id || undefined}
                                onChange={(val) => {
                                    companyIdChange(val);
                                }}
                            />
                        </Col>
                        {props.isAdmin && (
                            <Col xs={12} md={3}>
                                <Input
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder={t("renter.filter.companyName")}
                                    value={filterModel.searchFilterModel?.companyName || undefined}
                                    onChange={(val) => {
                                        companyNameChange(val.currentTarget.value);
                                    }}
                                />
                            </Col>
                        )}
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default RenterListFilter;
