/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestFormAdditionalServices,
    RequestFormAdditionalServicesFromJSON,
    RequestFormAdditionalServicesFromJSONTyped,
    RequestFormAdditionalServicesToJSON,
} from './RequestFormAdditionalServices';
import {
    RequestFormMachineFeatures,
    RequestFormMachineFeaturesFromJSON,
    RequestFormMachineFeaturesFromJSONTyped,
    RequestFormMachineFeaturesToJSON,
} from './RequestFormMachineFeatures';

/**
 * 
 * @export
 * @interface RequestFormCreateModel
 */
export interface RequestFormCreateModel {
    /**
     * 
     * @type {number}
     * @memberof RequestFormCreateModel
     */
    subCategoryId: number;
    /**
     * 
     * @type {string}
     * @memberof RequestFormCreateModel
     */
    subCategoryOther?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RequestFormCreateModel
     */
    attachments?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof RequestFormCreateModel
     */
    documents?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof RequestFormCreateModel
     */
    attachmentsOther?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RequestFormCreateModel
     */
    districtId: number;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormCreateModel
     */
    requestDateFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormCreateModel
     */
    requestDateTo: Date;
    /**
     * 
     * @type {number}
     * @memberof RequestFormCreateModel
     */
    machineCount: number;
    /**
     * 
     * @type {number}
     * @memberof RequestFormCreateModel
     */
    dailyWorkingTime: number;
    /**
     * 
     * @type {RequestFormMachineFeatures}
     * @memberof RequestFormCreateModel
     */
    machineFeatures?: RequestFormMachineFeatures;
    /**
     * 
     * @type {RequestFormAdditionalServices}
     * @memberof RequestFormCreateModel
     */
    additionalServices?: RequestFormAdditionalServices;
    /**
     * 
     * @type {string}
     * @memberof RequestFormCreateModel
     */
    description?: string | null;
}

export function RequestFormCreateModelFromJSON(json: any): RequestFormCreateModel {
    return RequestFormCreateModelFromJSONTyped(json, false);
}

export function RequestFormCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFormCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subCategoryId': json['subCategoryId'],
        'subCategoryOther': !exists(json, 'subCategoryOther') ? undefined : json['subCategoryOther'],
        'attachments': !exists(json, 'attachments') ? undefined : json['attachments'],
        'documents': !exists(json, 'documents') ? undefined : json['documents'],
        'attachmentsOther': !exists(json, 'attachmentsOther') ? undefined : json['attachmentsOther'],
        'districtId': json['districtId'],
        'requestDateFrom': (new Date(json['requestDateFrom'])),
        'requestDateTo': (new Date(json['requestDateTo'])),
        'machineCount': json['machineCount'],
        'dailyWorkingTime': json['dailyWorkingTime'],
        'machineFeatures': !exists(json, 'machineFeatures') ? undefined : RequestFormMachineFeaturesFromJSON(json['machineFeatures']),
        'additionalServices': !exists(json, 'additionalServices') ? undefined : RequestFormAdditionalServicesFromJSON(json['additionalServices']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function RequestFormCreateModelToJSON(value?: RequestFormCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subCategoryId': value.subCategoryId,
        'subCategoryOther': value.subCategoryOther,
        'attachments': value.attachments,
        'documents': value.documents,
        'attachmentsOther': value.attachmentsOther,
        'districtId': value.districtId,
        'requestDateFrom': (value.requestDateFrom.toISOString()),
        'requestDateTo': (value.requestDateTo.toISOString()),
        'machineCount': value.machineCount,
        'dailyWorkingTime': value.dailyWorkingTime,
        'machineFeatures': RequestFormMachineFeaturesToJSON(value.machineFeatures),
        'additionalServices': RequestFormAdditionalServicesToJSON(value.additionalServices),
        'description': value.description,
    };
}

