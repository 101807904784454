/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MachineSearchFilterModel,
    MachineSearchFilterModelFromJSON,
    MachineSearchFilterModelFromJSONTyped,
    MachineSearchFilterModelToJSON,
} from './MachineSearchFilterModel';
import {
    MachineSearchSortField,
    MachineSearchSortFieldFromJSON,
    MachineSearchSortFieldFromJSONTyped,
    MachineSearchSortFieldToJSON,
} from './MachineSearchSortField';

/**
 * 
 * @export
 * @interface MachineSearchModel
 */
export interface MachineSearchModel {
    /**
     * 
     * @type {number}
     * @memberof MachineSearchModel
     */
    pageNo?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineSearchModel
     */
    pageSize?: number;
    /**
     * 
     * @type {number}
     * @memberof MachineSearchModel
     */
    readonly rowIndex?: number;
    /**
     * 
     * @type {MachineSearchFilterModel}
     * @memberof MachineSearchModel
     */
    filter?: MachineSearchFilterModel;
    /**
     * 
     * @type {MachineSearchSortField}
     * @memberof MachineSearchModel
     */
    sortBy?: MachineSearchSortField;
    /**
     * 
     * @type {boolean}
     * @memberof MachineSearchModel
     */
    sortAscending?: boolean;
}

export function MachineSearchModelFromJSON(json: any): MachineSearchModel {
    return MachineSearchModelFromJSONTyped(json, false);
}

export function MachineSearchModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineSearchModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pageNo': !exists(json, 'pageNo') ? undefined : json['pageNo'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'rowIndex': !exists(json, 'rowIndex') ? undefined : json['rowIndex'],
        'filter': !exists(json, 'filter') ? undefined : MachineSearchFilterModelFromJSON(json['filter']),
        'sortBy': !exists(json, 'sortBy') ? undefined : MachineSearchSortFieldFromJSON(json['sortBy']),
        'sortAscending': !exists(json, 'sortAscending') ? undefined : json['sortAscending'],
    };
}

export function MachineSearchModelToJSON(value?: MachineSearchModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pageNo': value.pageNo,
        'pageSize': value.pageSize,
        'filter': MachineSearchFilterModelToJSON(value.filter),
        'sortBy': MachineSearchSortFieldToJSON(value.sortBy),
        'sortAscending': value.sortAscending,
    };
}

