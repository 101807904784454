import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/atomics/buttons/primary-button";
import SecureLayout from "../../components/containers/secure-layout";
import { userAtom } from "../../store/global-atoms";

const Dashboard = () => {
    const navigate = useNavigate();
    const [user] = useAtom(userAtom);
    return (
        <SecureLayout activePage={"dashboard"} role={"lessor"} unreadCount={0}>
            <PrimaryButton onClick={() => navigate("/machines/add?compid=" + user?.id)} style={{ width: "240px" }}>
                Makine Ekle
            </PrimaryButton>
        </SecureLayout>
    );
};

export default Dashboard;
