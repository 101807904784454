/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LessorUserViewModel
 */
export interface LessorUserViewModel {
    /**
     * 
     * @type {number}
     * @memberof LessorUserViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LessorUserViewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserViewModel
     */
    lastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorUserViewModel
     */
    title?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LessorUserViewModel
     */
    isBlocked?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof LessorUserViewModel
     */
    creationDate?: Date;
}

export function LessorUserViewModelFromJSON(json: any): LessorUserViewModel {
    return LessorUserViewModelFromJSONTyped(json, false);
}

export function LessorUserViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorUserViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'isBlocked': !exists(json, 'isBlocked') ? undefined : json['isBlocked'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
    };
}

export function LessorUserViewModelToJSON(value?: LessorUserViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'lastname': value.lastname,
        'phone': value.phone,
        'email': value.email,
        'title': value.title,
        'isBlocked': value.isBlocked,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
    };
}

