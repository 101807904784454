import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../../../components/atomics/buttons/secondary-button";
import ListContainer from "../../../../components/containers/list-container";
import { useQuery } from "../../../../helpers/use-query";
import LessorCompanyInfoPart from "../../../../page-parts/view-lessor-company-info-part";
import { LessorCompanyDetailViewModel, LessorUserViewModel } from "../../../../service";
import { useSecureService } from "../../../../store/service-atoms";
import { t } from "../../../../translate";
import { ReactComponent as ArrowLeft } from "./../../../../components/assets/left-arrow.svg";

type TableModel = {
    fullName?: string | null | undefined;
} & LessorUserViewModel;

const castToTableModelArray = (data: TableModel[] | null | undefined) => {
    const newList = data?.map((item) => {
        const tableModel: TableModel = {
            fullName: item.name + " " + item.lastname,
            ...item,
        };
        return tableModel;
    });
    return newList;
};

const ViewLessorCompany = () => {
    const query = useQuery();
    const [companyUsers, setCompanyUsers] = useState<TableModel[] | undefined>(undefined);
    const companyId = query.get("compid");
    const [lessorCompany, setLessorCompany] = useState<LessorCompanyDetailViewModel | undefined>(undefined);
    const [isBlocked, setIsBlocked] = useState<boolean | undefined>();
    const companyIdN = parseInt(companyId || "NaN");
    const userId = lessorCompany?.companyOwner?.id;
    const { doGetLessorCompanyUsers, doGetLessorBlocked, doBlockLessorCompany } = useSecureService();
    const { doGetLessorCompanyDetail } = useSecureService();
    useEffect(() => {
        if (!Number.isNaN(companyIdN))
            doGetLessorCompanyDetail(companyIdN).then((data) => {
                setLessorCompany(data);
            });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const fillFormsList = async () => {
            try {
                const result = await doGetLessorCompanyUsers(companyIdN);
                if (result !== null) {
                    setCompanyUsers(castToTableModelArray(result));
                }
            } catch {}
        };
        fillFormsList();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (userId) {
            doGetLessorBlocked(userId).then((data) => {
                setIsBlocked(data?.isBlocked);
            });
        }
        //eslint-disable-next-line
    }, [lessorCompany]);
    const columns = [
        {
            title: t("companies.table.nameLastName"),
            dataIndex: "fullName",
            key: "fullName",
        },
        {
            title: t("companies.table.phone"),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t("companies.table.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: t("companies.table.title"),
            dataIndex: "title",
            key: "title",
        },
    ];
    const onBlockClick = async () => {
        if (userId === undefined) return;
        doBlockLessorCompany(userId).then(() => {
            doGetLessorBlocked(userId).then((data) => {
                setIsBlocked(data?.isBlocked);
            });
        });
    };
    const navigate = useNavigate();
    const onMachineClick = () => {
        navigate("/machines?compname=" + lessorCompany?.companyName);
    };
    const onOffersClick = () => {
        navigate("/offers?compname=" + lessorCompany?.companyName);
    };
    return (
        <ListContainer activePage={"lessor-companies"}>
            <Row justify="space-between" align="middle">
                <Col>
                    <ArrowLeft />
                    {t("company.details")}
                </Col>
                <Col style={{ float: "right" }}>
                    <SecondaryButton onClick={onMachineClick} style={{ marginRight: "0.5rem", width: "inherit" }}>
                        {t("companies.detail.machines")}
                    </SecondaryButton>
                </Col>
                <Col>
                    <LessorCompanyInfoPart
                        viewType="lessorCompany"
                        isBlocked={isBlocked}
                        lessorCompany={lessorCompany}
                        compId={companyIdN}
                        userId={userId || 0}
                        dataSource={companyUsers}
                        dataColumns={columns}
                        onClickOfferFormList={onOffersClick}
                        onBlockClick={onBlockClick}
                    ></LessorCompanyInfoPart>
                </Col>
            </Row>
        </ListContainer>
    );
};

export default ViewLessorCompany;
