import { Col, message, Row, TablePaginationConfig } from "antd";
import { useEffect, useRef, useState } from "react";
import ListContainer from "../../../components/containers/list-container";
import { ContactFormDetailModel } from "../../../service/models";
import { PaginationType, useSecureService } from "../../../store/service-atoms";
import { CSVLink } from "react-csv";
import { t } from "../../../translate";
import { Data } from "react-csv/components/CommonPropTypes";
import { loadingAtom } from "../../../store/global-atoms";
import { useAtom } from "jotai";
import DataTable from "../../../components/atomics/data-table";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import { ReactComponent as CsvDownload } from "../../../components/assets/download-csv.svg";

const Applicants = () => {
    const { doGetApplicants, doGetErrorLogs } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [applicants, setApplicants] = useState<ContactFormDetailModel[] | undefined>(undefined);
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });
    const [itemCount, setItemCount] = useState<number | undefined>(1);
    const [logItemCount, setLogItemCount] = useState<number | undefined>(1);
    const [exportDataName, setExportDataName] = useState<string>();

    const columns = [
        {
            title: t("applicants.table.nameLastname"),
            dataIndex: "nameLastname",
            key: "nameLastname",
        },
        {
            title: t("applicants.table.phone"),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t("applicants.table.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: t("applicants.table.firmName"),
            dataIndex: "firmName",
            key: "firmName",
        },
        {
            title: t("applicants.table.message"),
            dataIndex: "message",
            key: "message",
        },
        {
            title: t("applicants.table.creationDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date) => <span>{val.toLocaleDateString()}</span>,
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current };
        });
    };

    useEffect(() => {
        const fillApplicantsList = async () => {
            try {
                const result = await doGetApplicants({ pageNo: pagination.current, pageSize: pagination.pageSize });
                if (result?.data !== null) {
                    setItemCount(result?.itemCount);
                    setApplicants(result?.data);
                }
            } catch (err: any) {
                message.error(err.toString());
            }
        };
        const getLogsForCount =async () => {
            try {
                const result = await doGetErrorLogs({ pageNo: pagination.current, pageSize: pagination.pageSize });
                if (result?.data !== null) {
                    setLogItemCount(result?.itemCount);
                }
            } catch (err: any) {
                message.error(err.toString());
            }
        }
        fillApplicantsList();
        getLogsForCount();
        // eslint-disable-next-line
    }, [pagination]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        if (!itemCount || itemCount <= 0) {
            return;
        }
        const result = await doGetApplicants({ pageNo: 1, pageSize: itemCount });
        if (result?.data?.length !== undefined && result?.data?.length > 0) {
            setAllRows(result.data);
            setExportDataName("requests");
            csvRef.current?.link.click();
        }
    };

    const getErrorLogs = async () => {
        if (!logItemCount || logItemCount <= 0) {
            return;
        }
        const result = await doGetErrorLogs({ pageNo: 1, pageSize: logItemCount });
        if (result?.data?.length !== undefined && result?.data?.length > 0) {
            setAllRows(result.data);
            setExportDataName("logs");
            csvRef.current?.link.click();
        }
    };

    return (
        <ListContainer>
            <Row justify="space-between" align="middle">
                <Col>{t("menu.requests")}</Col>
                <Row>
                    <Col style={{ float: "right" }}>
                        <SecondaryButton onClick={exportClick} style={{ width: "initial" }}>
                            <Row gutter={[8, 8]} justify="space-between" align="middle">
                                <Col>{t("menu.contactUs")}</Col>{" "}
                                <Col>
                                    <CsvDownload style={{ display: "flex" }} />
                                </Col>
                            </Row>
                        </SecondaryButton>
                    </Col>
                    <Col style={{ float: "right" }}>
                        <SecondaryButton onClick={getErrorLogs} style={{ width: "initial" }}>
                            <Row gutter={[8, 8]} justify="space-between" align="middle">
                                <Col>{t("error.logs")}</Col>{" "}
                                <Col>
                                    <CsvDownload style={{ display: "flex" }} />
                                </Col>
                            </Row>
                        </SecondaryButton>
                    </Col>
                </Row>
            </Row>
            <CSVLink filename={exportDataName === "logs"? "errorlogs.csv" :"requestforms.csv"} ref={csvRef} data={allRows} separator={";"}></CSVLink>
            <br />
            <br />
            <DataTable
                onChange={handleChange}
                columns={columns}
                dataSource={applicants}
                loading={loading}
                pagination={{ ...pagination, total: itemCount }}
            />
        </ListContainer>
    );
};

export default Applicants;
