import { Col, Divider, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import SecondaryButton from "../components/atomics/buttons/secondary-button";
import BigboxContainer from "../components/containers/bigbox-container";
import { BasicModel } from "../service";
import { ExtendedMachineDetailModel } from "../service/models/ExtendedMachineDetailModel";
import {activeApiBaseUrl} from "../store/app-constants";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";

const OfferViewMachineInfoPart = (props: { machines: BasicModel[] | null | undefined }) => {
    const [imageUrls, setImageUrls] = useState<string[]>([]);
    const [mainImageUrl, setMainImageUrl] = useState<string>("");
    const [allMachines, setAllMachines] = useState<ExtendedMachineDetailModel[]>([]);
    const { doGetMachine } = useSecureService();
    const navigate = useNavigate();

    useEffect(() => {
        props.machines?.forEach((bMach) => {
            const available = allMachines?.find((mach) => mach.id === bMach.id);
            if (!available && bMach.id) {
                doGetMachine(bMach.id).then((data) => {
                    setAllMachines([data as ExtendedMachineDetailModel, ...allMachines]);
                });
            }
        });
        //eslint-disable-next-line
    }, [props.machines]);

    //for profile images
    useEffect(() => {
        if (imageUrls.length > 0 || !allMachines) return;
        let imageIds: string[] = [];
        allMachines.forEach((mach) => {
            const currentImagesIds = mach?.documents
                ?.filter((doc) => doc.documentCategory === "Photo")
                .map((doc) => activeApiBaseUrl + "/document/" + (doc.document?.id || 0));
            if (currentImagesIds) imageIds.push(...currentImagesIds);
        });
        setImageUrls(imageIds);
        setMainImageUrl(imageIds[0]);
        //eslint-disable-next-line
    }, [allMachines]);

    return (
        <BigboxContainer title={t("machines.add.machineInfoTitle")}>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Row gutter={[16, 16]}>
                        {imageUrls?.length > 0 && (
                            <>
                                <Col xs={24} style={{ height: "12rem", background: "#222222", display: "flex", justifyContent: "center" }}>
                                    <img
                                        style={{ width: "auto", height: "100%", overflow: "hidden" }}
                                        src={mainImageUrl}
                                        alt="galleryImages"
                                    />
                                </Col>
                                {imageUrls.map((url) => {
                                    return (
                                        <Col
                                            xs={4}
                                            style={{
                                                height: "5rem",
                                                background: "#222222",
                                                margin: ".25rem",
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <img
                                                style={{ width: "auto", height: "100%", overflow: "hidden" }}
                                                src={url}
                                                alt="galleryImages"
                                                onClick={() => {
                                                    setMainImageUrl(url);
                                                }}
                                            />
                                        </Col>
                                    );
                                })}
                            </>
                        )}
                    </Row>
                </Col>
                {props.machines?.map((bMach) => {
                    const names = bMach?.name?.split(" / ");

                    return (
                        <>
                            <BasicModelViewer objKey={t("machine.review.machineId")} value={bMach?.id} />
                            <BasicModelViewer objKey={t("machine.review.machineCategory")} value={names?.[0]} />
                            <BasicModelViewer objKey={t("machine.review.machineSubcategory")} value={names?.[1]} />
                            <Col xs={24}>
                                <SecondaryButton onClick={() => navigate("/machines/view?macid=" + bMach?.id)}>
                                    {t("offer.view.machineDetailsButtonLabel")}
                                </SecondaryButton>
                            </Col>
                            <Divider />
                        </>
                    );
                })}
            </Row>
        </BigboxContainer>
    );
};

export default OfferViewMachineInfoPart;
