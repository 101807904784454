import { Layout, List } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ListContainer from "../../components/containers/list-container";
import { NotificationDetailModel } from "../../service";
import { userAtom } from "../../store/global-atoms";
import { useSecureService } from "../../store/service-atoms";
import { t } from "../../translate";

const Notifications = () => {
    const { doGetAllNotifications } = useSecureService();
    const [notifications, setNotifications] = useState<NotificationDetailModel[]>();
    const [user] = useAtom(userAtom);
    const navigate = useNavigate();

    useEffect(() => {
        const doGetNotifications = async () => {
            await doGetAllNotifications(user?.id).then((result) => {
                setNotifications(result);
            });
        };
        doGetNotifications();
        // eslint-disable-next-line
    }, []);

    return (
        <ListContainer activePage={"offers"} title={t("offers.view.title")} description={t("offers.view.description")}>
            <Layout style={{ padding: 15, backgroundColor: "white", borderRadius: 20 }}>
                <List
                    itemLayout="horizontal"
                    dataSource={notifications}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                description={
                                    <div
                                        onClick={() => {
                                            if (item.offerId === 0) {
                                                navigate("/requests/view?reqid=" + item.requestId);
                                            } else {
                                                navigate("/offers/view?offerid=" + item.offerId + "&reqid=" + item.requestId);
                                            }
                                        }}
                                    >
                                        {item.text}
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                />
            </Layout>
        </ListContainer>
    );
};

export default Notifications;
