import { EditOutlined } from "@ant-design/icons";
import { Col, message, Row, Space, TablePaginationConfig, Tooltip } from "antd";
import { useAtom } from "jotai";
import { moment } from "../../../../helpers/moment";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Data } from "react-csv/components/CommonPropTypes";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CsvDownload } from "../../../../components/assets/download-csv.svg";
import SecondaryButton from "../../../../components/atomics/buttons/secondary-button";
import DataTable from "../../../../components/atomics/data-table";
import LessorListFilter from "../../../../components/containers/lessor-list-filter";
import ListContainer from "../../../../components/containers/list-container";
import { LessorCompanySearchFilterModel, LessorCompanySearchSortField, LessorCompanyViewModel } from "../../../../service/models";
import { companiesAtom, loadingAtom, requestFormAtom } from "../../../../store/global-atoms";
import { PaginationType, useSecureService } from "../../../../store/service-atoms";
import { t } from "../../../../translate";

type TableModel = {
    districtAndCity?: string;
    nameLastName?: string;
    categoryList?: string;
    city?: string | null;
    district?: string | null;
} & LessorCompanyViewModel;

const castToTableModelArray = (data: LessorCompanyViewModel[] | null | undefined) => {
    const newList = data?.map((item) => {
        let cities = item?.serviceRegions?.map((cty) => {
            return cty.name;
        });
        let categories = item.categories?.map((cat) => {
            return cat.name;
        })
        /* let machines = item?.machines?.map((mchn)=>{
            return mchn.name;
        }); */

        const tableModel: TableModel = {
            districtAndCity: cities?.join(", "),
            nameLastName: item.name + " " + item.lastname,
            categoryList: categories?.join(", "),
            city: item.location?.city?.name,
            district: item.location?.name,
            /*  companyName:item.companyName,
            email:item.email,
            phone:item.phone,
            
            
            creationDate:item.creationDate,
            subCategoryName:item.subCategory?.name,
            allAttachments:attachmentsValue?.join(', '),
            description:item.description, */

            /*  dailyWorkingTime:item.dailyWorkingTime,
            machineCount:item.machineCount,
            ...item.machineFeatures,
            ...item.additionalServices, */
            ...item,
        };
        return tableModel;
    });
    return newList;
};
const Requests = () => {
    const navigate = useNavigate();
    const { doSearchLessorCompanies } = useSecureService();
    const [loading] = useAtom(loadingAtom);
    const [, ViewRequest] = useAtom(requestFormAtom);
    const [lessors, setLessors] = useState<TableModel[] | undefined>(undefined);
    const [, editCompany] = useAtom(companiesAtom);
    const [filterObj, setFilterObj] = useState<{
        filterModel: LessorCompanySearchFilterModel;
        sortBy: LessorCompanySearchSortField;
    }>({
        filterModel: {},
        sortBy: LessorCompanySearchSortField.MachineCount,
    });
    const [pagination, setPagination] = useState<PaginationType>({
        current: 1,
        pageSize: 10,
    });

    const [itemCount, setItemCount] = useState<number | undefined>(1);

    const columns = [
        {
            title: t("companies.table.id"),
            dataIndex: "id",
            key: "id",
        },
        {
            title: t("companies.table.nameLastName"),
            dataIndex: "nameLastName",
            key: "nameLastName",
        },
        {
            title: t("companies.table.phone"),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t("companies.table.email"),
            dataIndex: "email",
            key: "email",
        },
        {
            title: t("companies.table.companyServiceRegions"),
            dataIndex: "districtAndCity",
            key: "districtAndCity",
        },
        {
            title: t("companies.table.sector"),
            dataIndex: "categoryList",
            key: "categoryList",
        },
        {
            title: t("companies.table.companyName"),
            dataIndex: "companyName",
            key: "companyName",
        },
        {
            title: t("companies.table.creationDate"),
            dataIndex: "creationDate",
            key: "creationDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("companies.table.totalMachineCount"),
            dataIndex: "totalMachines",
            key: "totalMachines",
        },
        {
            title: t("companies.table.registeredMachineCount"),
            dataIndex: "machineCount",
            key: "machineCount",
        },
        {
            title: t("companies.table.lessorOfferCount"),
            dataIndex: "offerCount",
            key: "offerCount",
        },
        {
            title: t("companies.table.lessorAcceptedOfferCount"),
            dataIndex: "acceptedOfferCount",
            key: "acceptedOfferCount",
        },
        {
            title: t("companies.table.lessorLastOfferDate"),
            dataIndex: "lastOfferDate",
            key: "lastOfferDate",
            render: (val: Date | undefined | null) => {
                return moment(val);
            },
        },
        {
            title: t("common.action"),
            key: "action",
            render: (text: string, record: any) => (
                <Space size="middle">
                    <Tooltip title={t("common.edit")}>
                        <EditOutlined onClick={() => viewClickHandler(record)} />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleChange = (tablePagination: TablePaginationConfig) => {
        setPagination((curr) => {
            return { ...curr, current: tablePagination.current };
        });
    };

    useEffect(() => {
        const fillFormsList = async () => {
            try {
                const result = await doSearchLessorCompanies({
                    pageNo: pagination.current,
                    pageSize: pagination.pageSize,
                    filter: filterObj.filterModel,
                    sortBy: filterObj.sortBy,
                });
                if (result?.data !== null) {
                    setItemCount(result?.itemCount);
                    setLessors(castToTableModelArray(result?.data));
                }
            } catch (err: any) {
                message.error(err.toString());
            }
        };
        fillFormsList();
        // eslint-disable-next-line
    }, [pagination, filterObj]);

    const [allRows, setAllRows] = useState<Data>([]);
    const csvRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
    const exportClick = async () => {
        if (!itemCount || itemCount <= 0) {
            return;
        }
        const result = await doSearchLessorCompanies({ pageNo: 1, pageSize: itemCount });
        const exportData = castToTableModelArray(result?.data);
        if (exportData?.length !== undefined && exportData?.length > 0) {
            setAllRows(exportData);
            csvRef.current?.link.click();
        }
    };
    const rowClickHandler = async (record: any) => {
        ViewRequest(record);
        navigate("/offers/view?offerid=" + record.id + "&reqid=" + record.requestForm.id);
    };
    const viewClickHandler = (record: any) => {
        editCompany(record);
        navigate("/lessor-company/view?compid=" + record.id);
    };
    return (
        <ListContainer>
            <LessorListFilter
                isAdmin={true}
                onChange={(params) => {
                    setFilterObj({
                        filterModel: params?.searchFilterModel || {},
                        sortBy: params.sortBy.field,
                    });
                }}
            ></LessorListFilter>
            <Row justify="space-between" align="middle">
                <Col>{t("menu.lessor.list")}</Col>
                <Col style={{ float: "right" }}>
                    <SecondaryButton onClick={exportClick} style={{ width: "initial" }}>
                        <CsvDownload style={{ display: "flex" }} />
                    </SecondaryButton>
                </Col>
            </Row>
            <CSVLink filename="requestforms.csv" ref={csvRef} data={allRows} separator={";"}></CSVLink>
            <br />
            <DataTable
                onRowClick={rowClickHandler}
                onChange={handleChange}
                columns={columns}
                dataSource={lessors}
                loading={loading}
                pagination={{ ...pagination, total: itemCount }}
            />
        </ListContainer>
    );
};

export default Requests;
