/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LessorCompanyProfileViewModel
 */
export interface LessorCompanyProfileViewModel {
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyProfileViewModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyProfileViewModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyProfileViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyProfileViewModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyProfileViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LessorCompanyProfileViewModel
     */
    creationDate?: Date;
}

export function LessorCompanyProfileViewModelFromJSON(json: any): LessorCompanyProfileViewModel {
    return LessorCompanyProfileViewModelFromJSONTyped(json, false);
}

export function LessorCompanyProfileViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyProfileViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'lastName': json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
    };
}

export function LessorCompanyProfileViewModelToJSON(value?: LessorCompanyProfileViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'lastName': value.lastName,
        'email': value.email,
        'title': value.title,
        'phone': value.phone,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
    };
}

