import { ReactElement, useEffect, useRef } from "react";
import BaseLayout from "./base-layout";
import { Layout, Menu } from "antd";
import { UserOutlined, VideoCameraOutlined, LogoutOutlined } from "@ant-design/icons";
import styles from "../../styles/PostloginLayout.module.css";
import { useAtom } from "jotai";
import { t } from "../../translate";
import {  useNavigate } from "react-router-dom";
import { useSecureService } from "../../store/service-atoms";
import { navAtom, userAtom } from "../../store/global-atoms";
import NotificationBell from "../atomics/notification-bell";


const { Header, Content, Footer, Sider } = Layout;

export type PostloginLayoutProps = {
    children: any;
    header?: string | ReactElement;
    footer?: string | ReactElement;
};

const PostloginLayout = (props: PostloginLayoutProps) => {
    const navigate = useNavigate();
    const { doLogout } = useSecureService();
    const [user] = useAtom(userAtom);
    const [selected, setSelected] = useAtom(navAtom);

    const navigation = useRef(useNavigate());

    useEffect(() => {
        if (user === undefined) {
            navigation.current("/");
        }
    }, [user]);

    const selectionChange = async (item: any) => {
        if (item.key === "0") {
            await doLogout();
        } else {
            setSelected(item.key);
            navigation.current(item.key);
        }
    };
    
    return (
        <BaseLayout>
            <Sider breakpoint="lg" collapsedWidth="0" className={styles.sider}>
                <div className={styles.logoContainer} onClick={() => navigate("../machines")}>
                    <img src="/assets/logo-inverse.png" alt="Machinego Logo" width={"100%"}></img>
                </div>
                <Menu
                    theme="dark"
                    style={{ backgroundColor: "#2E2E2E" }}
                    defaultSelectedKeys={[selected]}
                    mode="inline"
                    onSelect={selectionChange}
                >
                    <Menu.Item key="/machines" icon={<UserOutlined />}>
                        {t("menu.listMachines")}
                    </Menu.Item>
                    {user?.role === "Admin" && (
                        <>
                            <Menu.Item key="/renter-company" icon={<VideoCameraOutlined />}>
                                {t("menu.listRenters")}
                            </Menu.Item>
                            <Menu.Item key="/lessor-company" icon={<UserOutlined />}>
                                {t("menu.listLessors")}
                            </Menu.Item>
                            <Menu.Item key="/offers" icon={<VideoCameraOutlined />}>
                                {t("menu.offers")}
                            </Menu.Item>
                            <Menu.Item key="/requests" icon={<UserOutlined />}>
                                {t("menu.requests")}
                            </Menu.Item>
                            <Menu.Item key="/applicants" icon={<UserOutlined />}>
                                {t("menu.contactUs")}
                            </Menu.Item>
                        </>
                    )}
                    <Menu.Item key="0" icon={<LogoutOutlined />}>
                        {t("menu.logout")}
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout style={{ backgroundColor: "transparent", height: "100vh", display: "flex", justifyContent: "space-between" }}>
                <Header className={styles.header}>
                    <div className={styles.headerTopRight} style={{ padding: 15 }}>
                        {user?.name}
                        <div>
                            <NotificationBell userId={user?.id.toString()} />
                        </div>
                    </div>
                </Header>
                <Content className={styles.content}>{props.children}</Content>
                <Footer className={styles.footer}>{props.footer || t("copyright")}</Footer>
            </Layout>
        </BaseLayout>
    );
};

export default PostloginLayout;
