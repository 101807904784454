/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginResponseModel
 */
export interface LoginResponseModel {
    /**
     * 
     * @type {number}
     * @memberof LoginResponseModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseModel
     */
    role: string;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponseModel
     */
    canSwitch?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseModel
     */
    agreementVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseModel
     */
    kvkkVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseModel
     */
    kvkkInfoVersion?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginResponseModel
     */
    ipazVersion?: string | null;
}

export function LoginResponseModelFromJSON(json: any): LoginResponseModel {
    return LoginResponseModelFromJSONTyped(json, false);
}

export function LoginResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'lastname': json['lastname'],
        'role': json['role'],
        'canSwitch': !exists(json, 'canSwitch') ? undefined : json['canSwitch'],
        'agreementVersion': !exists(json, 'agreementVersion') ? undefined : json['agreementVersion'],
        'kvkkVersion': !exists(json, 'kvkkVersion') ? undefined : json['kvkkVersion'],
        'kvkkInfoVersion': !exists(json, 'kvkkInfoVersion') ? undefined : json['kvkkInfoVersion'],
        'ipazVersion': !exists(json, 'ipazVersion') ? undefined : json['ipazVersion'],
    };
}

export function LoginResponseModelToJSON(value?: LoginResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'lastname': value.lastname,
        'role': value.role,
        'canSwitch': value.canSwitch,
        'agreementVersion': value.agreementVersion,
        'kvkkVersion': value.kvkkVersion,
        'kvkkInfoVersion': value.kvkkInfoVersion,
        'ipazVersion': value.ipazVersion,
    };
}

