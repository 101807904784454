import { Col, Row } from "antd";
import { moment } from "../../../helpers/moment";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicModelViewer from "../../../components/atomics/basic-model-viewer";
import PrimaryButton from "../../../components/atomics/buttons/primary-button";
import SecondaryButton from "../../../components/atomics/buttons/secondary-button";
import BigboxContainer from "../../../components/containers/bigbox-container";
import ListContainer from "../../../components/containers/list-container";
import { useQuery } from "../../../helpers/use-query";
import OfferModal from "../../../modals/offer-modal";
//import RequestModal from "../../../modals/request-modal";
import OfferViewAdditionalInfoPart from "../../../page-parts/offer-view-additional-info-part";
import OfferViewMachineInfoPart from "../../../page-parts/offer-view-machine-info-part";
import RequestViewPart from "../../../page-parts/request-view-part";
import ViewAdditionalServicesPart from "../../../page-parts/view-additional-services-part";
import { OfferFormBidCommissionCreateModel, OfferFormViewModel } from "../../../service/models";
import { postloginMessageAtom } from "../../../store/global-atoms";
import { useSecureService } from "../../../store/service-atoms";
import { t } from "../../../translate";
import EditPaymentModal from "../../../modals/edit-payment";
import BoxContainer from "../../../components/containers/box-container";
import { ReactComponent as Danger } from "../../../components/assets/danger.svg";
import { ReactComponent as Block } from "../../../components/assets/block.svg";
import OfferHistoryPart from "../../../page-parts/offer-history-part";

const ViewOffer = () => {
    const query = useQuery();
    const offerId = query.get("offerid");
    const reqId = query.get("reqid");
    const navigate = useNavigate();
    const [rejectrequestVisible, toggleRejectRequestVisible] = useState(false);
    const [selectPayment, toggleSelectPayment] = useState(false);
    const [offerForm, setOfferForm] = useState<OfferFormViewModel | undefined>(undefined);
    const reqIdN = parseInt(reqId || "NaN");
    const offerIdN = parseInt(offerId || "NaN");
    const [rerender, setRerender] = useState(0);
    const [, setMessage] = useAtom(postloginMessageAtom);
    const [commissionsSuccess, setCommissionsSuccess] = useState(false);
    const { doSetCommission, doGetOfferDetail, doDealOffer, doRejectPayment } = useSecureService();
    const [offerType, setOfferType] = useState<"approve" | "reject">();
    useEffect(() => {
        if (!Number.isNaN(offerIdN))
            doGetOfferDetail(offerIdN).then((data) => {
                setOfferForm(data);
                const anyCommissionIsGreaterThanZero = offerForm?.bids?.some((bid) => (bid.predictedTotal || 0) > 0);
                setCommissionsSuccess(anyCommissionIsGreaterThanZero || true);
            });
        console.log(commissionsSuccess);
        //eslint-disable-next-line
    }, [rerender]);

    const onDealClick = async () => {
        if (offerForm?.id) {
            const result = await doDealOffer(offerForm?.id);
            if (!result)
                setMessage({
                    type: "error",
                    message: t("offers.view.cannotDealError"),
                });
            else {
                setMessage({
                    type: "success",
                    message: t("offers.view.dealt"),
                });
                navigate(-1);
            }
        } else {
            setMessage({
                type: "error",
                message: t("offers.view.noOfferIdError"),
            });
        }
    };
    const onRejectPayment = async () => {
        if (offerForm?.id) {
            const result = await doRejectPayment(offerForm?.id);
            if (!result)
                setMessage({
                    type: "error",
                    message: t("offers.view.cannotRejectError"),
                });
            else {
                setMessage({
                    type: "success",
                    message: t("offers.view.rejected"),
                });
                navigate(-1);
            }
        } else {
            setMessage({
                type: "error",
                message: t("offers.view.noOfferIdError"),
            });
        }
    };

    const approveCommissions = async (data: OfferFormBidCommissionCreateModel[] | undefined) => {
        //TODO:data yoksa hata göster.
        if (data) {
            const commissionResult = await doSetCommission(offerIdN, data);

            if (commissionResult) {
                toggleSelectPayment(false);
                setRerender(rerender + 1);
            }
        } else {
            setMessage({
                type: "error",
                message: t("offers.view.cannotSetCommission"),
            });
        }
    };

    const openCommentModal = (offerTypeVal: "approve" | "reject") => {
        setOfferType(offerTypeVal);
        toggleRejectRequestVisible(!rejectrequestVisible);
    };

    return (
        <ListContainer activePage={"offers"} title={t("offers.view.title")} description={t("offers.view.description")}>
            <Row gutter={[4, 4]}>
                <Col xs={24}>
                    {offerForm?.status === "PaymentDeclined" && (
                        <BoxContainer
                            style={{
                                display: "flex",
                                height: "inherit",
                                alignItems: "center",
                                flexDirection: "row",
                                borderLeft: "0.75rem solid red",
                            }}
                        >
                            <Row gutter={[16, 16]}>
                                <Col>
                                    <Danger />
                                </Col>
                                <Col style={{ color: "red", display: "flex", alignItems: "center" }}>
                                    <span>{t("offer.view.declinedPayment")}</span>
                                </Col>
                            </Row>
                        </BoxContainer>
                    )}
                </Col>
                <Col xs={24}>
                    <RequestViewPart
                        requestId={reqIdN}
                        onClick={() => navigate("/requests/view?reqid=" + reqId)}
                        dateFromTo={moment(offerForm?.requestForm?.requestDateFrom) + " - " + moment(offerForm?.requestForm?.requestDateTo)}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <OfferViewMachineInfoPart machines={offerForm?.machines} />
                </Col>
                <Col xs={24} md={12}>
                    <OfferViewAdditionalInfoPart
                        docs={offerForm?.documents}
                        commission={commissionsSuccess}
                        notes={offerForm?.notes}
                        bids={offerForm?.bids}
                        statue={offerForm?.status}
                        paymentTypes={offerForm?.paymentTypes}
                        billing={offerForm?.billingTimeOptions}
                        onClick={() => toggleSelectPayment(!selectPayment)}
                    />
                </Col>
                <Col xs={24} md={12}>
                    <ViewAdditionalServicesPart viewType="offer" additionalServices={offerForm?.additionalServices} />
                </Col>
                <Col xs={24} md={12}>
                    <BigboxContainer title={t("machines.add.additionalServicesTitle")}>
                        <Row gutter={[16, 16]}>
                            <BasicModelViewer objKey={t("offers.view.lastAvailableDateTitle")} value={moment(offerForm?.expireDate)} />
                        </Row>
                    </BigboxContainer>
                </Col>
            </Row>
            <div>
                <OfferHistoryPart comments={offerForm?.comments} />
            </div>
            {offerForm?.status === "Accepted" && (
                <Col xs={8} offset={8} style={{ marginTop: "1.75rem" }}>
                    <Row gutter={[16, 16]}>
                        <Col xs={12}>
                            <SecondaryButton
                                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                onClick={onRejectPayment}
                                negative
                            >
                                <Block /> {t("common.rejectPayment")}
                            </SecondaryButton>
                        </Col>
                        <Col xs={12}>
                            <PrimaryButton onClick={onDealClick}>{t("common.accept")}</PrimaryButton>
                        </Col>
                    </Row>
                </Col>
            )}
            {offerForm?.status === "Offered" && (
                <Row gutter={[4, 4]}>
                    <Col xs={{ span: 24 }} md={{ span: 18, offset: 6 }} style={{ marginTop: "1rem" }}>
                        <Row gutter={16}>
                            <Col xs={24} md={8}>
                                <SecondaryButton
                                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                                    onClick={() => openCommentModal("reject")}
                                    negative
                                >
                                    <Block /> {t("common.reject")}
                                </SecondaryButton>
                            </Col>
                            <Col xs={24} md={8}>
                                <PrimaryButton onClick={() => openCommentModal("approve")}>{t("common.approve")}</PrimaryButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
            <Row gutter={[4, 4]}>
                <Col xs={{ span: 24 }} style={{ marginTop: "1rem", textAlign: "center" }}>
                    <span style={{ fontSize: "1.25rem", fontWeight: "800", display: "block" }}>
                        {t("offer.view.requestDateandAgreement")}
                    </span>
                </Col>
                <Col xs={{ span: 24 }} style={{ marginTop: "1rem", textAlign: "center", marginBottom: "1rem" }}>
                    <BoxContainer>
                        <span style={{ background: "#FFF6E8", borderRadius: "0.5rem", padding: "0.5rem" }}>
                            {moment(offerForm?.requestForm?.requestDateFrom) + " - " + moment(offerForm?.requestForm?.requestDateTo)}
                        </span>
                    </BoxContainer>
                </Col>
            </Row>
            <OfferModal
                onCloseClick={() => {
                    toggleRejectRequestVisible(!rejectrequestVisible);
                }}
                show={rejectrequestVisible}
                offerType={offerType}
                offerId={offerIdN}
                commissionsSuccess={commissionsSuccess}
            ></OfferModal>
            <EditPaymentModal
                onApproveClick={approveCommissions}
                bids={offerForm?.bids}
                req={offerForm}
                onCloseClick={() => toggleSelectPayment(!selectPayment)}
                show={selectPayment}
            ></EditPaymentModal>
        </ListContainer>
    );
};

export default ViewOffer;
