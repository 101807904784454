/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RequestFormAdditionalServices,
    RequestFormAdditionalServicesFromJSON,
    RequestFormAdditionalServicesFromJSONTyped,
    RequestFormAdditionalServicesToJSON,
} from './RequestFormAdditionalServices';

/**
 * 
 * @export
 * @interface RequestFormCreateWithRegisterModel
 */
export interface RequestFormCreateWithRegisterModel {
    /**
     * 
     * @type {number}
     * @memberof RequestFormCreateWithRegisterModel
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof RequestFormCreateWithRegisterModel
     */
    subCategoryId: number;
    /**
     * 
     * @type {number}
     * @memberof RequestFormCreateWithRegisterModel
     */
    districtId: number;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormCreateWithRegisterModel
     */
    requestDateFrom: Date;
    /**
     * 
     * @type {Date}
     * @memberof RequestFormCreateWithRegisterModel
     */
    requestDateTo: Date;
    /**
     * 
     * @type {number}
     * @memberof RequestFormCreateWithRegisterModel
     */
    machineCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof RequestFormCreateWithRegisterModel
     */
    withRegister: boolean;
    /**
     * 
     * @type {string}
     * @memberof RequestFormCreateWithRegisterModel
     */
    subCategoryOther?: string | null;
    /**
     * 
     * @type {RequestFormAdditionalServices}
     * @memberof RequestFormCreateWithRegisterModel
     */
    additionalServices?: RequestFormAdditionalServices;
    /**
     * 
     * @type {string}
     * @memberof RequestFormCreateWithRegisterModel
     */
    description?: string | null;
}

export function RequestFormCreateWithRegisterModelFromJSON(json: any): RequestFormCreateWithRegisterModel {
    return RequestFormCreateWithRegisterModelFromJSONTyped(json, false);
}

export function RequestFormCreateWithRegisterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestFormCreateWithRegisterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'subCategoryId': json['subCategoryId'],
        'districtId': json['districtId'],
        'requestDateFrom': (new Date(json['requestDateFrom'])),
        'requestDateTo': (new Date(json['requestDateTo'])),
        'machineCount': json['machineCount'],
        'withRegister': json['withRegister'],
        'subCategoryOther': !exists(json, 'subCategoryOther') ? undefined : json['subCategoryOther'],
        'additionalServices': !exists(json, 'additionalServices') ? undefined : RequestFormAdditionalServicesFromJSON(json['additionalServices']),
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function RequestFormCreateWithRegisterModelToJSON(value?: RequestFormCreateWithRegisterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'subCategoryId': value.subCategoryId,
        'districtId': value.districtId,
        'requestDateFrom': (value.requestDateFrom.toISOString()),
        'requestDateTo': (value.requestDateTo.toISOString()),
        'machineCount': value.machineCount,
        'withRegister': value.withRegister,
        'subCategoryOther': value.subCategoryOther,
        'additionalServices': RequestFormAdditionalServicesToJSON(value.additionalServices),
        'description': value.description,
    };
}

