import { Col, Input, InputNumber, Row, Select } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { BasicModel, CategoryModel, MachineSearchFilterModel, MachineSearchSortField } from "../../service/models";
import { loadingAtom } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "./box-container";
//import styles from '../styles/filter-components.module.scss';
import { useSecureService } from "../../store/service-atoms";
type MachineListFilterProps = {
    onChange?: (params: MachineListFilterViewModel) => void;
    children?: any;
    isAdmin?: boolean;
};

type MachineListFilterViewModel = {
    searchFilterModel?: MachineSearchFilterModel;
    categoryName?: string | null;
    subCategoryName?: string | null;
    brandName?: string | null;
    companyName?: string | null;
    sortBy: {
        field: MachineSearchSortField;
        sortAscending: boolean;
    };
};

const MachineListFilter = (props: MachineListFilterProps) => {
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const [availableBrands, setAvailableBrands] = useState<BasicModel[] | undefined>(undefined);
    const [filterModel, setFilterModel] = useState<MachineListFilterViewModel>({
        sortBy: {
            field: MachineSearchSortField.CreationDate,
            sortAscending: false,
        },
    });
    const [loading] = useAtom(loadingAtom);
    const { doGetMachineSubcategory, doGetBrandsByCategory } = useSecureService();
    const { Option } = Select;

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    const onMachineCategorySelected = async (selectedCategoryName?: string) => {
        const selectedCategory = allCategories?.find((cat) => cat.name === selectedCategoryName);
        if (selectedCategory && selectedCategory.id) {
            const brands = await doGetBrandsByCategory(selectedCategory.id);
            setAvailableBrands(brands);
        } else {
            setAvailableBrands(undefined);
        }
        setAvailableSubcategories(selectedCategory?.subCategories);
        setFilterModel((filt) => {
            return {
                ...filt,
                categoryName: selectedCategory?.name,
                subCategoryName: undefined,
                brandName: undefined,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    subCategoryId: undefined,
                    brandId: undefined,
                    categoryId: selectedCategory?.id,
                },
            };
        });
    };

    const onSubcategorySelected = async (selectedSubcategoryName?: string) => {
        let selectedSub: BasicModel | undefined;
        allCategories?.find((category) => {
            selectedSub = category.subCategories?.find((subCategory) => {
                return subCategory.name === selectedSubcategoryName;
            });
            return selectedSub !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                subCategoryName: selectedSub?.name,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    subCategoryId: selectedSub?.id,
                },
            };
        });
    };

    const onBrandSelected = async (selectedBrandName?: string) => {
        let selectedBrand: BasicModel | undefined;
        availableBrands?.find((category) => {
            selectedBrand = availableBrands?.find((br) => {
                return br.name === selectedBrandName;
            });
            return selectedBrand !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                brandName: selectedBrand?.name,
                searchFilterModel: {
                    ...filt.searchFilterModel,
                    brandId: selectedBrand?.id,
                },
            };
        });
    };

    const productionYearChange = (val: string | number) => {
        let prodYear: number | undefined = undefined;
        if (typeof val === "number") prodYear = val;
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    productionYearFrom: prodYear,
                    productionYearTo: prodYear,
                },
            };
        });
    };

    const machineIdChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    searchKeyword: val,
                },
            };
        });
    };

    const companyNameChange = (val: string) => {
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter.searchFilterModel,
                    companyName: val,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={4}>
                <BoxContainer shadowless marginless>
                    {t("common.sortBy")}
                    <Select
                        style={{ width: "100%" }}
                        placeholder={t("common.creationDate")}
                        allowClear
                        onChange={(val) => {
                            setFilterModel((filter) => {
                                return {
                                    ...filter,
                                    sortBy: {
                                        sortAscending: true,
                                        field: val,
                                    },
                                };
                            });
                        }}
                    >
                        <Option value="creationDate">{t("common.creationDate")}</Option>
                        <Option value="productionYear">{t("common.productionYear")}</Option>
                    </Select>
                </BoxContainer>
            </Col>
            <Col xs={24} md={20}>
                <BoxContainer shadowless marginless>
                    {t("common.filterBy")}
                    <Row gutter={[12, 12]}>
                        <Col xs={12} md={4}>
                            <Select
                                allowClear
                                placeholder={t("machines.add.category")}
                                style={{ width: "100%" }}
                                value={filterModel.categoryName}
                                showSearch
                                loading={loading}
                                onSelect={onMachineCategorySelected}
                                onClear={onMachineCategorySelected}
                            >
                                {allCategories?.map((mc) => (
                                    <Option value={mc.name}>{mc.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Select
                                allowClear
                                placeholder={t("machines.add.subcategory")}
                                style={{ width: "100%" }}
                                value={filterModel?.subCategoryName}
                                showSearch
                                loading={loading}
                                onSelect={onSubcategorySelected}
                                onClear={onSubcategorySelected}
                            >
                                {availableSubcategories?.map((msc) => (
                                    <Option value={msc.name}>{msc.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Select
                                allowClear
                                onClear={onBrandSelected}
                                placeholder={t("machines.add.brand")}
                                style={{ width: "100%" }}
                                value={filterModel?.brandName}
                                showSearch
                                loading={loading}
                                onSelect={onBrandSelected}
                            >
                                {availableBrands?.map((msc) => (
                                    <Option value={msc.name}>{msc.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={12} md={3}>
                            <Input
                                allowClear
                                placeholder={t("machines.add.modelName")}
                                value={filterModel.searchFilterModel?.modelName || undefined}
                                onChange={(val) => {
                                    setFilterModel((filter) => {
                                        return {
                                            ...filter,
                                            searchFilterModel: {
                                                ...filter.searchFilterModel,
                                                modelName: val.currentTarget.value,
                                            },
                                        };
                                    });
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <InputNumber
                                style={{ width: "100%" }}
                                placeholder={t("machines.add.productionYear")}
                                value={filterModel.searchFilterModel?.productionYearFrom || undefined}
                                onChange={(val) => {
                                    productionYearChange(val);
                                }}
                            />
                        </Col>
                        <Col xs={12} md={props.isAdmin ? 3 : 6}>
                            <Input
                                allowClear
                                style={{ width: "100%" }}
                                placeholder={t("machines.add.machineIdLabel")}
                                value={filterModel.searchFilterModel?.searchKeyword || undefined}
                                onChange={(val) => {
                                    machineIdChange(val.currentTarget.value);
                                }}
                            />
                        </Col>
                        {props.isAdmin && (
                            <Col xs={12} md={3}>
                                <Input
                                    allowClear
                                    style={{ width: "100%" }}
                                    placeholder={t("machines.add.companyName")}
                                    value={filterModel.searchFilterModel?.companyName || undefined}
                                    onChange={(val) => {
                                        companyNameChange(val.currentTarget.value);
                                    }}
                                />
                            </Col>
                        )}
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default MachineListFilter;
