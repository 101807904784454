/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OfferFormBidCreateModel
 */
export interface OfferFormBidCreateModel {
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCreateModel
     */
    maturityOptionId?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCreateModel
     */
    bid?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCreateModel
     */
    currencyId?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferFormBidCreateModel
     */
    paymentPeriodId?: number;
}

export function OfferFormBidCreateModelFromJSON(json: any): OfferFormBidCreateModel {
    return OfferFormBidCreateModelFromJSONTyped(json, false);
}

export function OfferFormBidCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormBidCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maturityOptionId': !exists(json, 'maturityOptionId') ? undefined : json['maturityOptionId'],
        'bid': !exists(json, 'bid') ? undefined : json['bid'],
        'currencyId': !exists(json, 'currencyId') ? undefined : json['currencyId'],
        'paymentPeriodId': !exists(json, 'paymentPeriodId') ? undefined : json['paymentPeriodId'],
    };
}

export function OfferFormBidCreateModelToJSON(value?: OfferFormBidCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'maturityOptionId': value.maturityOptionId,
        'bid': value.bid,
        'currencyId': value.currencyId,
        'paymentPeriodId': value.paymentPeriodId,
    };
}

