/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicModel,
    BasicModelFromJSON,
    BasicModelFromJSONTyped,
    BasicModelToJSON,
} from './BasicModel';
import {
    DistrictModel,
    DistrictModelFromJSON,
    DistrictModelFromJSONTyped,
    DistrictModelToJSON,
} from './DistrictModel';
import {
    SubCategoryModel,
    SubCategoryModelFromJSON,
    SubCategoryModelFromJSONTyped,
    SubCategoryModelToJSON,
} from './SubCategoryModel';

/**
 * 
 * @export
 * @interface LessorCompanyViewModel
 */
export interface LessorCompanyViewModel {
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyViewModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyViewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyViewModel
     */
    lastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyViewModel
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LessorCompanyViewModel
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModel
     */
    totalMachines?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModel
     */
    locationId?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof LessorCompanyViewModel
     */
    creationDate?: Date;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof LessorCompanyViewModel
     */
    serviceRegions?: Array<BasicModel> | null;
    /**
     * 
     * @type {Array<BasicModel>}
     * @memberof LessorCompanyViewModel
     */
    categories?: Array<BasicModel> | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModel
     */
    machineCount?: number;
    /**
     * 
     * @type {Array<SubCategoryModel>}
     * @memberof LessorCompanyViewModel
     */
    machines?: Array<SubCategoryModel> | null;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModel
     */
    offerCount?: number;
    /**
     * 
     * @type {number}
     * @memberof LessorCompanyViewModel
     */
    acceptedOfferCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof LessorCompanyViewModel
     */
    lastOfferDate?: Date | null;
    /**
     * 
     * @type {DistrictModel}
     * @memberof LessorCompanyViewModel
     */
    location?: DistrictModel;
}

export function LessorCompanyViewModelFromJSON(json: any): LessorCompanyViewModel {
    return LessorCompanyViewModelFromJSONTyped(json, false);
}

export function LessorCompanyViewModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LessorCompanyViewModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'lastname': !exists(json, 'lastname') ? undefined : json['lastname'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'totalMachines': !exists(json, 'totalMachines') ? undefined : json['totalMachines'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'creationDate': !exists(json, 'creationDate') ? undefined : (new Date(json['creationDate'])),
        'serviceRegions': !exists(json, 'serviceRegions') ? undefined : (json['serviceRegions'] === null ? null : (json['serviceRegions'] as Array<any>).map(BasicModelFromJSON)),
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] === null ? null : (json['categories'] as Array<any>).map(BasicModelFromJSON)),
        'machineCount': !exists(json, 'machineCount') ? undefined : json['machineCount'],
        'machines': !exists(json, 'machines') ? undefined : (json['machines'] === null ? null : (json['machines'] as Array<any>).map(SubCategoryModelFromJSON)),
        'offerCount': !exists(json, 'offerCount') ? undefined : json['offerCount'],
        'acceptedOfferCount': !exists(json, 'acceptedOfferCount') ? undefined : json['acceptedOfferCount'],
        'lastOfferDate': !exists(json, 'lastOfferDate') ? undefined : (json['lastOfferDate'] === null ? null : new Date(json['lastOfferDate'])),
        'location': !exists(json, 'location') ? undefined : DistrictModelFromJSON(json['location']),
    };
}

export function LessorCompanyViewModelToJSON(value?: LessorCompanyViewModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'companyName': value.companyName,
        'name': value.name,
        'lastname': value.lastname,
        'phone': value.phone,
        'email': value.email,
        'totalMachines': value.totalMachines,
        'locationId': value.locationId,
        'creationDate': value.creationDate === undefined ? undefined : (value.creationDate.toISOString()),
        'serviceRegions': value.serviceRegions === undefined ? undefined : (value.serviceRegions === null ? null : (value.serviceRegions as Array<any>).map(BasicModelToJSON)),
        'categories': value.categories === undefined ? undefined : (value.categories === null ? null : (value.categories as Array<any>).map(BasicModelToJSON)),
        'machineCount': value.machineCount,
        'machines': value.machines === undefined ? undefined : (value.machines === null ? null : (value.machines as Array<any>).map(SubCategoryModelToJSON)),
        'offerCount': value.offerCount,
        'acceptedOfferCount': value.acceptedOfferCount,
        'lastOfferDate': value.lastOfferDate === undefined ? undefined : (value.lastOfferDate === null ? null : value.lastOfferDate.toISOString()),
        'location': DistrictModelToJSON(value.location),
    };
}

