import { Col, DatePicker, Input, Row, Select } from "antd";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { BasicModel, CategoryModel, OfferStatus, RequestFormSearchModel } from "../../service/models";
import { loadingAtom } from "../../store/global-atoms";
import { t } from "../../translate";
import BoxContainer from "./box-container";
//import styles from '../styles/filter-components.module.scss';
import { useSecureService } from "../../store/service-atoms";
import moment from "moment";

type RequestsFilterProps = {
    onChange?: (params: RequestsFilterViewModel | undefined) => void;
    children?: any;
    isAdmin?: boolean;
};

type RequestsFilterViewModel = {
    searchFilterModel?: RequestFormSearchModel;
    categoryName?: string | null;
    subCategoryName?: string | null;
    brandName?: string | null;
    companyName?: string | null;
    cityName?: string | null;
    reqId?: number;
    reqDate?: moment.Moment;
    offerStatus?: OfferStatus[] | null | undefined;
};

const RequestListFilter = (props: RequestsFilterProps) => {
    const [allCategories, setAllCategories] = useState<undefined | CategoryModel[]>(undefined);
    const [availableSubcategories, setAvailableSubcategories] = useState<undefined | null | BasicModel[]>(undefined);
    const [city, setCity] = useState<BasicModel[] | undefined>(undefined);
    const [filterModel, setFilterModel] = useState<RequestsFilterViewModel>();
    const [loading] = useAtom(loadingAtom);
    const { doGetMachineSubcategory, doGetCities } = useSecureService();
    const { Option } = Select;

    useEffect(() => {
        const getCategoriesAndSubcategories = async () => {
            const result = await doGetMachineSubcategory();
            setAllCategories(result);
        };
        getCategoriesAndSubcategories();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        const getCityNames = async () => {
            const result = await doGetCities();
            setCity(result);
        };
        getCityNames();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        props.onChange && props.onChange(filterModel);
        // eslint-disable-next-line
    }, [filterModel]);

    const onMachineCategorySelected = async (selectedCategoryName?: string) => {
        const selectedCategory = allCategories?.find((cat) => cat.name === selectedCategoryName);
        setAvailableSubcategories(selectedCategory?.subCategories);
        setFilterModel((filt) => {
            return {
                ...filt,
                categoryName: selectedCategory?.name,
                subCategoryName: undefined,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    subCategoryId: undefined,
                    categoryId: selectedCategory?.id,
                    requestDateFrom: undefined,
                },
            };
        });
    };

    const onSubcategorySelected = async (selectedSubcategoryName?: string) => {
        let selectedSub: BasicModel | undefined;
        allCategories?.find((category) => {
            selectedSub = category.subCategories?.find((subCategory) => {
                return subCategory.name === selectedSubcategoryName;
            });
            return selectedSub !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                subCategoryName: selectedSub?.name,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    subCategoryId: selectedSub?.id,
                },
            };
        });
    };

    const onCitySelected = async (selectedCityName?: string) => {
        let SelectedCity: BasicModel | undefined;
        city?.find((ct) => {
            SelectedCity = city?.find((br) => {
                return br.name === selectedCityName;
            });
            return SelectedCity !== undefined;
        });
        setFilterModel((filt) => {
            return {
                ...filt,
                cityName: SelectedCity?.name,
                searchFilterModel: {
                    ...filt?.searchFilterModel,
                    cityId: SelectedCity?.id,
                },
            };
        });
    };

    const requestCreationDateChange = (val: moment.Moment) => {
        let prodYear = moment().toDate();
        if (val) prodYear = moment(val).toDate();
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    creationDateFrom: prodYear,
                },
            };
        });
    };
    const requestDateChange = (val: moment.Moment) => {
        let prodYear = moment().toDate();
        if (val) prodYear = moment(val).toDate();
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    requestDateFrom: prodYear,
                },
            };
        });
    };
    const requestIdChange = (val: number) => {
        let requestId: number | undefined = undefined;
        requestId = val;
        setFilterModel((filter) => {
            return {
                ...filter,
                searchFilterModel: {
                    ...filter?.searchFilterModel,
                    id: requestId,
                },
            };
        });
    };

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: "1rem" }}>
            <Col xs={24} md={24}>
                <BoxContainer shadowless marginless>
                    {t("common.filterBy")}
                    <Row gutter={[12, 12]}>
                        <Col xs={12} md={4}>
                            <Select
                                allowClear
                                placeholder={t("machines.add.category")}
                                style={{ width: "100%" }}
                                value={filterModel?.categoryName}
                                showSearch
                                loading={loading}
                                onSelect={onMachineCategorySelected}
                                onClear={onMachineCategorySelected}
                            >
                                {allCategories?.map((mc) => (
                                    <Option value={mc.name}>{mc.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Select
                                allowClear
                                placeholder={t("machines.add.subcategory")}
                                style={{ width: "100%" }}
                                value={filterModel?.subCategoryName}
                                showSearch
                                loading={loading}
                                onSelect={onSubcategorySelected}
                                onClear={onSubcategorySelected}
                            >
                                {availableSubcategories?.map((msc) => (
                                    <Option value={msc.name}>{msc.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Select
                                allowClear
                                onClear={onCitySelected}
                                placeholder={t("requests.filter.city")}
                                style={{ width: "100%" }}
                                value={filterModel?.cityName}
                                showSearch
                                loading={loading}
                                onSelect={onCitySelected}
                            >
                                {city?.map((msc) => (
                                    <Option value={msc.name}>{msc.name}</Option>
                                ))}
                            </Select>
                        </Col>
                        <Col xs={12} md={3}>
                            <DatePicker
                                style={{ width: "100%" }}
                                placeholder={t("request.filter.requestCreationDate")}
                                format="DD-MM-YYYY"
                                onChange={(val) => {
                                    requestCreationDateChange(moment(val));
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <DatePicker
                                style={{ width: "100%" }}
                                placeholder={t("request.filter.requestDateFrom")}
                                onChange={(val) => {
                                    requestDateChange(moment(val));
                                }}
                            />
                        </Col>
                        <Col xs={12} md={3}>
                            <Input
                                allowClear
                                style={{ width: "100%" }}
                                placeholder={t("request.filter.requestId")}
                                value={filterModel?.searchFilterModel?.id || undefined}
                                onChange={(val) => {
                                    requestIdChange(val.currentTarget.valueAsNumber);
                                }}
                            />
                        </Col>
                    </Row>
                </BoxContainer>
            </Col>
        </Row>
    );
};

export default RequestListFilter;
