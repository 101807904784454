import { ReactElement } from "react";
import PostloginLayout from "./postlogin-layout";

type PageType = "dashboard" | "machines" | "requests" | "offers" | "requests-and-offers" | "lessor-companies" | "renter-companies";
export type ListContainerProps = {
    activePage?: PageType;
    role?: string;
    unreadCount?: number;
    children?: any;
    title?: string;
    description?: string;
    helperButtons?: ReactElement[] | undefined;
    onlyHeader?: boolean;
};

const ListContainer = (props: ListContainerProps) => {
    return <PostloginLayout>{props.children}</PostloginLayout>;
};

export default ListContainer;
