/* tslint:disable */
/* eslint-disable */
/**
 * MachineGo P1 API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OfferFormBidCreateModel,
    OfferFormBidCreateModelFromJSON,
    OfferFormBidCreateModelFromJSONTyped,
    OfferFormBidCreateModelToJSON,
} from './OfferFormBidCreateModel';
import {
    RequestFormAdditionalServices,
    RequestFormAdditionalServicesFromJSON,
    RequestFormAdditionalServicesFromJSONTyped,
    RequestFormAdditionalServicesToJSON,
} from './RequestFormAdditionalServices';

/**
 * 
 * @export
 * @interface OfferFormEditModel
 */
export interface OfferFormEditModel {
    /**
     * 
     * @type {number}
     * @memberof OfferFormEditModel
     */
    id: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof OfferFormEditModel
     */
    machines: Array<number>;
    /**
     * 
     * @type {Array<OfferFormBidCreateModel>}
     * @memberof OfferFormEditModel
     */
    bids: Array<OfferFormBidCreateModel>;
    /**
     * 
     * @type {Date}
     * @memberof OfferFormEditModel
     */
    expireDate: Date;
    /**
     * 
     * @type {Array<number>}
     * @memberof OfferFormEditModel
     */
    paymentTypes: Array<number>;
    /**
     * 
     * @type {RequestFormAdditionalServices}
     * @memberof OfferFormEditModel
     */
    additionalServices: RequestFormAdditionalServices;
    /**
     * 
     * @type {string}
     * @memberof OfferFormEditModel
     */
    notes?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OfferFormEditModel
     */
    documents?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof OfferFormEditModel
     */
    billingTimeOptions: Array<number>;
}

export function OfferFormEditModelFromJSON(json: any): OfferFormEditModel {
    return OfferFormEditModelFromJSONTyped(json, false);
}

export function OfferFormEditModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OfferFormEditModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'machines': json['machines'],
        'bids': ((json['bids'] as Array<any>).map(OfferFormBidCreateModelFromJSON)),
        'expireDate': (new Date(json['expireDate'])),
        'paymentTypes': json['paymentTypes'],
        'additionalServices': RequestFormAdditionalServicesFromJSON(json['additionalServices']),
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'documents': !exists(json, 'documents') ? undefined : json['documents'],
        'billingTimeOptions': json['billingTimeOptions'],
    };
}

export function OfferFormEditModelToJSON(value?: OfferFormEditModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'machines': value.machines,
        'bids': ((value.bids as Array<any>).map(OfferFormBidCreateModelToJSON)),
        'expireDate': (value.expireDate.toISOString()),
        'paymentTypes': value.paymentTypes,
        'additionalServices': RequestFormAdditionalServicesToJSON(value.additionalServices),
        'notes': value.notes,
        'documents': value.documents,
        'billingTimeOptions': value.billingTimeOptions,
    };
}

