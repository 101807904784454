import { Col, Row } from "antd";
import { EventHandler, useEffect, useState } from "react";
import BasicModelViewer from "../components/atomics/basic-model-viewer";
import PrimaryButton from "../components/atomics/buttons/primary-button";
import LightTitle from "../components/atomics/light-title";
import BigboxContainer from "../components/containers/bigbox-container";
import { BasicModel, DocumentModel, OfferFormBidViewModel } from "../service";
import { useSecureService } from "../store/service-atoms";
import { t } from "../translate";
import { ReactComponent as Check } from "../components/assets/check.svg";
import GrayboxContainer from "../components/containers/graybox-container";
import SecondaryButton from "../components/atomics/buttons/secondary-button";
import {activeApiBaseUrl} from "../store/app-constants";
import style from "../components/styles/payment.module.scss";

type OfferViewAdditionalInfoPartProps = {
    notes?: string | null;
    bids?: OfferFormBidViewModel[] | null;
    paymentTypes?: BasicModel[] | null | undefined;
    billing?: BasicModel[] | null | undefined;
    onClick?: EventHandler<any>;
    statue?: string | null | undefined;
    commission?: boolean;
    docs?: DocumentModel[] | null;
};
const OfferViewAdditionalInfoPart = (props: OfferViewAdditionalInfoPartProps) => {
    const { doGetMaturityOptions } = useSecureService();
    const [availableMaturityOptions, setAvailableMaturityOptions] = useState<BasicModel[] | undefined>(undefined);
    const [selectedBid, setSelectedBid] = useState<OfferFormBidViewModel>();
    useEffect(() => {
        const getServiceDatas = async () => {
            const curries = await doGetMaturityOptions();
            setAvailableMaturityOptions(curries);
        };
        getServiceDatas();
        //eslint-disable-next-line
    }, []);
    useEffect(() => {
        const initialSelectedBid = props.bids?.find((bid) => bid?.selected);
        if (initialSelectedBid) setSelectedBid(initialSelectedBid);
        //eslint-disable-next-line
    }, [props.bids]);
    return (
        <BigboxContainer title={t("offers.view.additionalInfoTitle")}>
            <Row gutter={[16, 16]}>
                <BasicModelViewer xs={24} md={24} objKey={t("common.description")} value={props.notes} />
                {props.docs && (
                    <Col xs={24}>
                        <LightTitle>{t("machines.add.documents")}</LightTitle>
                        <Row gutter={[16, 16]}>
                            {
                                //TODO: dokuman tipine gore filter yapmaliyiz.
                                props?.docs?.map((doc) => {
                                    return (
                                        <Col xs={24} md={12}>
                                            <GrayboxContainer title={doc.fileName}>
                                                <div style={{ paddingBottom: "1rem" }}>
                                                    <SecondaryButton
                                                        onClick={() => {
                                                            window.open(activeApiBaseUrl + "/Document/" + doc.id);
                                                        }}
                                                    >
                                                        {t("common.review")}
                                                    </SecondaryButton>
                                                </div>
                                            </GrayboxContainer>
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Col>
                )}
                <Col xs={24}>
                    <LightTitle>{t("offers.add.paymentOptionsTitle")}</LightTitle>
                    {props.statue && (props.statue === "Approved" || props.statue === "PaymentDeclined" || props.statue === "Expired") && (
                        <Row gutter={[12, 12]}>
                            {props.bids?.map((bid) => {
                                var selected = availableMaturityOptions?.find((item) => item.id === bid.maturityOption?.id);
                                if (!selected) return <></>;
                                return (
                                    <div
                                        style={{
                                            border: "1px solid #28282833",
                                            padding: "1rem",
                                            borderRadius: "1rem",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        <Col xs={24} className={style.labelStyle}>
                                            {bid.maturityOption?.name}
                                        </Col>
                                        <Row style={{ display: "flex", alignItems: "center" }} gutter={[16, 16]} justify="space-between">
                                            <Col md={12} xs={12}>
                                                <Row justify="space-between">
                                                    <Col md={12} className={style.labelStyle}>
                                                        {t("offers.view.period")}
                                                    </Col>
                                                    <Col md={12} className={style.priceStyle}>
                                                        {bid?.paymentPeriod?.name}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={12} xs={12}>
                                                <Row justify="space-between">
                                                    <Col md={12} className={style.labelStyle}>
                                                        {t("offers.view.price")}
                                                    </Col>
                                                    <Col md={12} className={style.priceStyle}>
                                                        {bid?.bid} {bid.currency?.name}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{ display: "flex", alignItems: "center" }}
                                            gutter={[0, 16]}
                                            className={style.priceTable}
                                            justify="space-between"
                                        >
                                            <Col xs={16}>{t("offers.view.predictedTotal")}</Col>
                                            <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                                {bid.predictedTotal} {bid.currency?.name}
                                            </Col>
                                            <Col xs={16}>{t("offers.view.machineGoCommission")}</Col>
                                            <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                                {bid.machineGoCommission} {bid.currency?.name}
                                            </Col>
                                            <Col xs={16}>{t("offers.view.advancePayment")}</Col>
                                            <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                                {bid.advancePayment} {bid.currency?.name}
                                            </Col>
                                            <Col xs={16}>{t("offers.view.renterPeriodPayment")}</Col>
                                            <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                                {bid.renterPeriodPayment} {bid.currency?.name}
                                            </Col>
                                            <Col xs={16}>{t("offers.view.lessorMachineGoCommission")}</Col>
                                            <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                                {bid.lessorMachineGoCommission} {bid.currency?.name}
                                            </Col>
                                            <Col xs={16}>{t("offers.view.lessorPeriodPayment")}</Col>
                                            <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                                {bid.lessorPeriodPayment} {bid.currency?.name}
                                            </Col>
                                        </Row>
                                        <Row
                                            style={{ display: "flex", alignItems: "center", paddingRight: "0.75rem" }}
                                            gutter={[0, 16]}
                                            justify="space-between"
                                        >
                                            <Col className={style.labelStyle} xs={16}>
                                                {t("offers.view.totalBid")}
                                            </Col>
                                            <Col className={style.labelStyle} xs={8} style={{ textAlign: "end" }}>
                                                {bid.total} {bid.currency?.name}
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            })}
                        </Row>
                    )}
                    {selectedBid && (
                        <div style={{ border: "1px solid #28282833", padding: "1rem", borderRadius: "1rem", marginBottom: "1rem" }}>
                            <Col xs={24} className={style.labelStyle}>
                                {selectedBid.maturityOption?.name}
                            </Col>
                            <Row style={{ display: "flex", alignItems: "center" }} gutter={[16, 16]} justify="space-between">
                                <Col md={12} xs={12}>
                                    <Row justify="space-between">
                                        <Col md={12} className={style.labelStyle}>
                                            {t("offers.view.period")}
                                        </Col>
                                        <Col md={12} className={style.priceStyle}>
                                            {selectedBid?.paymentPeriod?.name}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} xs={12}>
                                    <Row justify="space-between">
                                        <Col md={12} className={style.labelStyle}>
                                            {t("offers.view.price")}
                                        </Col>
                                        <Col md={12} className={style.priceStyle}>
                                            {selectedBid?.bid} {selectedBid.currency?.name}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                style={{ display: "flex", alignItems: "center" }}
                                gutter={[0, 16]}
                                className={style.priceTable}
                                justify="space-between"
                            >
                                <Col xs={16}>{t("offers.view.predictedTotal")}</Col>
                                <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                    {selectedBid.predictedTotal} {selectedBid.currency?.name}
                                </Col>
                                <Col xs={16}>{t("offers.view.machineGoCommission")}</Col>
                                <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                    {selectedBid.machineGoCommission} {selectedBid.currency?.name}
                                </Col>
                                <Col xs={16}>{t("offers.view.advancePayment")}</Col>
                                <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                    {selectedBid.advancePayment} {selectedBid.currency?.name}
                                </Col>
                                <Col xs={16}>{t("offers.view.renterPeriodPayment")}</Col>
                                <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                    {selectedBid.renterPeriodPayment} {selectedBid.currency?.name}
                                </Col>
                                <Col xs={16}>{t("offers.view.lessorMachineGoCommission")}</Col>
                                <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                    {selectedBid.lessorMachineGoCommission} {selectedBid.currency?.name}
                                </Col>
                                <Col xs={16}>{t("offers.view.lessorPeriodPayment")}</Col>
                                <Col xs={8} className={style.labelStyle} style={{ textAlign: "end" }}>
                                    {selectedBid.lessorPeriodPayment} {selectedBid.currency?.name}
                                </Col>
                            </Row>
                            <Row
                                style={{ display: "flex", alignItems: "center", paddingRight: "0.75rem" }}
                                gutter={[0, 16]}
                                justify="space-between"
                            >
                                <Col className={style.labelStyle} xs={16}>
                                    {t("offers.view.totalBid")}
                                </Col>
                                <Col className={style.labelStyle} xs={8} style={{ textAlign: "end" }}>
                                    {selectedBid.total} {selectedBid.currency?.name}
                                </Col>
                            </Row>
                        </div>
                    )}
                    {props.statue === "Offered" &&
                        props.bids?.map((item, index) => {
                            if (props?.commission === false) return <></>;
                            return (
                                <div style={{ border: "1px solid #28282833", padding: "1rem", borderRadius: "1rem", marginBottom: "1rem" }}>
                                    <Col xs={24} className={style.labelStyle}>
                                        {item.maturityOption?.name}
                                    </Col>
                                    <Row style={{ display: "flex", alignItems: "center" }} gutter={16} justify="space-between">
                                        <Col md={12} xs={12}>
                                            <Row justify="space-between">
                                                <Col md={12} className={style.labelStyle}>
                                                    {t("offers.view.period")}
                                                </Col>
                                                <Col md={12} className={style.priceStyle}>
                                                    {item?.paymentPeriod?.name}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={12} xs={12}>
                                            <Row justify="space-between">
                                                <Col md={12} className={style.labelStyle}>
                                                    {t("offers.view.price")}
                                                </Col>
                                                <Col md={12} className={style.priceStyle}>
                                                    {item?.bid} {item?.currency?.name}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        })}
                    <Row
                        style={{ display: "flex", alignItems: "center", paddingRight: "0.75rem" }}
                        gutter={[0, 16]}
                        justify="space-between"
                    >
                        <Col className={style.labelStyle} xs={16}>
                            {t("offers.view.invoiceTime")}
                        </Col>

                        {props.billing?.map((bill) => {
                            return (
                                <Col className={style.priceStyle} xs={8} style={{ textAlign: "end" }}>
                                    {bill.name || " - "}
                                </Col>
                            );
                        })}
                    </Row>
                </Col>

                {/* {props.paymentTypes && 
                    <Col xs={24}>
                        <LightTitle>{t("machines.add.documents")}</LightTitle>
                        <Row gutter={[16,16]}>
                            {
                                //TODO: dokuman tipine gore filter yapmaliyiz.
                                props.paymentTypes.map((pmt)=>{
                                    return <Col xs={12} sm={8}><SelectedItemText>{pmt.name}</SelectedItemText></Col>
                                })
                            }
                        </Row>
                    </Col>
                } */}
                {props?.statue === "Offered" && (
                    <Col style={{ float: "right" }}>
                        <PrimaryButton
                            onClick={props.onClick}
                            style={{
                                margin: "0rem",
                                padding: ".5rem",
                                width: "inherit",
                                marginBottom: "0px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {t("offers.view.editPayment")}
                            {props?.commission === true && <Check style={{ height: "50%" }}></Check>}
                        </PrimaryButton>
                    </Col>
                )}
            </Row>
        </BigboxContainer>
    );
};

export default OfferViewAdditionalInfoPart;
